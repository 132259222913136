import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <>
            {/* <footer className="footer">
                <div className="footer-bottom">
                    <div className="container">
                        <div className="copyright">
                            <div className="row align-items-center">
                                <div className="col-md-4">
                                    <div className="copyright-text">
                                        <p className="mb-0">Copyright © 2023. All Rights Reserved.</p>
                                    </div>
                                </div>
                                <div className="col-md-4 text-center">
                                    <img src="/assets/img/logo.png" alt="logo" className="img-fluid w-50" />
                                </div>
                                <div className="col-md-4">
                                    <div className="copyright-menu">
                                        <ul className="policy-menu">
                                            <li>
                                                <a href="privacy-policy.html">Privacy Policy</a>
                                            </li>
                                            <li>
                                                <a href="terms-condition.html">Terms &amp; Conditions</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer> */}
            <footer className="footer">
                <div className="footer-bottom">
                    <div className="container">
                        <div className="copyright">
                            <div className="row align-items-center">
                                <div className="col-md-4">
                                    <img src="/assets/img/logo.png" alt="logo" className="img-fluid w-25" loading='lazy'/>

                                </div>
                                <div className="col-md-4 offset-md-4">
                                    <div className="copyright-text">
                                        <p className="mb-0">Copyright © 2023. All Rights Reserved.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div></footer>
        </>
    )
}

export default Footer