import React from 'react'
import { Link } from 'react-router-dom';

import "./style.css";

const AttractiveOffers = () => {
    return (
        <section className="work-section pt-0">
            <div className="container">
                <div className="row pt-5 mb-0 mb-md-5">
                    <div className="col-md-4 mb-2 mb-md-0">
                        <div className="work-box aos aos-init aos-animate h-100 mb-0 d-flex flex-column justify-content-between" data-aos="fade-up">
                            {/* <div className="work-icon">
                                <span>
                                    <img src="/assets/img/icons/work-icon.svg" alt="img" />
                                </span>
                            </div> */}
                            <span className="heading-style styled aqua align-self-center">Rank Higher on Google</span>
                            <p className='my-3'>Now is the moment for you to be on the first page. Improve your online visibility by ranking higher in searches.</p>
                            {/* <h4>01</h4> */}
                            <Link to={`/rank_high`} className="read-more align-self-center">Read More <i className="feather-arrow-right-circle ms-2"></i></Link>
                        </div>
                    </div>
                    <div className="col-md-4 mb-2 mb-md-0">
                        <div className="work-box aos aos-init aos-animate h-100 mb-0 d-flex flex-column justify-content-between" data-aos="fade-up">
                            {/* <div className="work-icon">
                                <span>
                                    <img src="/assets/img/icons/find-icon.svg" alt="img" />
                                </span>
                            </div> */}
                            <span className="heading-style styled aqua align-self-center">Promote Your Brand</span>
                            <p className='my-3'>Enhance your presence on the internet. Increased visibility, more quality prospects, and lower costs are all results of effective SEO.</p>
                            {/* <h4>02</h4> */}
                            <Link to={`/promote_brand`} className="read-more align-self-center">Read More <i className="feather-arrow-right-circle ms-2"></i></Link>
                        </div>
                    </div>
                    <div className="col-md-4 mb-2 mb-md-0">
                        <div className="work-box aos aos-init aos-animate h-100 mb-0 d-flex flex-column justify-content-between" data-aos="fade-up">
                            {/* <div className="work-icon">
                                <span>
                                    <img src="/assets/img/icons/place-icon.svg" alt="img" />
                                </span>
                            </div> */}
                            <span className="heading-style styled aqua align-self-center">Obtain Quality Leads</span>
                            <p className='my-3'>Acquire High-Quality Backlinks to direct targeted traffic to your website. Wherever your chances are, make sure you make a great impression.</p>
                            {/* <h4>03</h4> */}
                            <Link to={`/obtain_quality_leads`} className="read-more align-self-center">Read More <i className="feather-arrow-right-circle ms-2"></i></Link>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 offset-md-2 mb-2 mb-md-0">
                        <div className="work-box aos aos-init aos-animate h-100 mb-0 d-flex flex-column justify-content-between" data-aos="fade-up">
                            {/* <div className="work-icon">
                                <span>
                                    <img src="/assets/img/icons/work-icon.svg" alt="img" />
                                </span>
                            </div> */}
                            <span className="heading-style styled aqua align-self-center">Boost Revenue</span>
                            <p className='my-3'>Increase sales with our effective solutions; a variety of backlinks services are available for you to select from.</p>
                            {/* <h4>01</h4> */}
                            <Link to={`/boost_revenue`} className="read-more align-self-center">Read More <i className="feather-arrow-right-circle ms-2"></i></Link>
                        </div>
                    </div>
                    <div className="col-md-4 mb-2 mb-md-0">
                        <div className="work-box aos aos-init aos-animate h-100 mb-0 d-flex flex-column justify-content-between" data-aos="fade-up">
                            {/* <div className="work-icon">
                                <span>
                                    <img src="/assets/img/icons/work-icon.svg" alt="img" />
                                </span>
                            </div> */}
                            <span className="heading-style styled aqua align-self-center">Social Media Visibility</span>
                            <p className='my-3'>By using backlinks, you may increase your exposure on Instagram, Facebook, Twitter, and other social media platforms. This is simpler than on regular websites.</p>
                            {/* <h4>01</h4> */}
                            <Link to={`/social_media_visibility`} className="read-more align-self-center">Read More <i className="feather-arrow-right-circle ms-2"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AttractiveOffers