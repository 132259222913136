import React from 'react'
import { Link } from 'react-router-dom'

const MoneyBackPromise = () => {
    return (
        <section className="app-seven-section">
            <div className="container">
                <div className="app-sec app-sec-seven">
                    <div className="row align-items-center w-100 py-5">
                        <div className="col-lg-6 col-12">
                            <div className="new-app-seven-middle">
                                <div className="heading aos aos-init aos-animate" data-aos="fade-up">
                                    <h2>Money-Back Promise</h2>
                                    <p>You are entitled to a complete refund in the event that we are unable to deliver orders for any reason.</p>

                                    <Link className='btn btn-primary'>Read the full refund policy…</Link>
                                </div>
                                {/* <div className="new-app-six-bottom aos aos-init aos-animate" data-aos="fade-up">
                                    <a href="javascript:void(0);">
                                        <img src="assets/img/icons/scan-six.svg" className="img-fluid" alt="img" />
                                    </a>
                                    <div className="new-app-six-content">
                                        <a href="javascript:void(0);">
                                            <img src="assets/img/icons/googleplay-seven.svg" className="img-fluid new-six-img" alt="img" />
                                        </a>
                                        <a href="javascript:void(0);">
                                            <img src="assets/img/icons/appstore-seven.svg" className="img-fluid" alt="img" />
                                        </a>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="appimg-seven aos aos-init aos-animate d-flex justify-content-end" data-aos="fade-up">
                                <img src="/assets/img/money-back-guarantee.png" className="img-fluid w-50 my-auto me-md-5" alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MoneyBackPromise