import React from 'react'

const Badge = ({ status }) => {
    if (status === "pending")
        return <span className="badge-info px-3 py-2 rounded-1" style={{ textTransform: "capitalize" }}>{status.replace("pending", "Pending")}</span>
    else if (status === "completed")
        return <span className="badge-success" style={{ textTransform: "capitalize" }}>{status.replace("completed", "Completed")}</span>
    else if (status === "refunded")
        return <span className="badge-danger" style={{ textTransform: "capitalize" }}>{status.replace("refunded", "Refunded")}</span>
}

export default Badge