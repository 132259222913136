import React, { useEffect, useState } from 'react'

const ServiceForm = (props) => {
    const [formFields, setFormFields] = useState([...JSON.parse(props.formFields)]);
    const [formData, setFormData] = useState([]);

    useEffect(() => {
        // console.log("formFields", formFields);
        if (formFields.length > 0) {
            var temp = [];
            formFields.forEach((f) => {
                // console.log("name", f.name, "label", f.label, "userinput", "userinput");
                if (f.type !== "checkbox-group" && f.type !== "radio-group" && f.type !== "select") {
                    temp.push({ "name": f.name, "label": f.label, "userinput": "", "required": f.required });
                }
                else if (f.type === "radio-group" || f.type === "select") {
                    var defaultvalue = f.values.filter(v => v.selected === true).length > 0 ? f.values.filter(v => v.selected === true)[0]["value"] : "";
                    temp.push({ "name": f.name, "label": f.label, "userinput": defaultvalue, "required": f.required });
                }
                else if (f.type === "checkbox-group") {
                    //var defaultvalue = f.values.filter(v => v.selected === true).length > 0 ? f.values.filter(v => v.selected === true)[0]["value"].join(",") : "";
                    var defaultvalue = '';
                    if (f.values.filter(v => v.selected === true).length > 0) {
                        // console.log("selected:", f.values.filter(v => v.selected === true));
                        // console.log(f.values.filter(v => v.selected === true).map(u => u.value).join('^'));
                        defaultvalue = f.values.filter(v => v.selected === true).map(u => u.value).join('^');
                    }
                    temp.push({ "name": f.name, "label": f.label, "userinput": defaultvalue, "required": f.required });
                }
            })
            // console.log("temp:", temp);
            setFormData([...temp]);
        }
    }, [formFields]);

    useEffect(() => {
        if (formData.length > 0) {
            // console.log("formData:", formData);

            var contine = true;
            var requireFields = formData.filter(field => field.required);
            requireFields.forEach((rf) => {
                // console.log("rf: ", rf);
                if (rf.userinput.trim() === '' || rf.userinput.trim() === '-1') {
                    // console.log("data: ", rf.userinput);
                    contine = false;
                }
            })
            props.setEnableProceedButton(contine);
            if (contine)
                props.setServiceFormData([...formData]);
        }
    }, [formData])

    const handleOnChange = (field, value) => {
        // console.log("id", field.name, "value", value);
        //console.log("formFields: ", formFields);
        // formData.filter(fd => fd.name === field.name && fd.userinput === value.value);
        var temp = [...formFields];
        // console.log("temp:", temp);
        if (field.type === "checkbox-group") {
            // // console.log("formData: ", formData);
            // var h = temp.filter(fd => fd.name === field.name)[0];

            // if (h.values.filter(v => v.value === value)[0].selected)
            //     h.values.filter(v => v.value === value)[0].selected = false;
            // else
            //     h.values.filter(v => v.value === value)[0].selected = true;

            // //console.log(h);
            // // console.log("Checkbox Group:", h.values.filter(v => v.value === value)[0]);
            // setFormFields([...temp]);

            var h = temp.filter(fd => fd.name === field.name)[0];

            if (h.values.filter(v => v.value === value)[0].selected)
                h.values.filter(v => v.value === value)[0].selected = false;
            else
                h.values.filter(v => v.value === value)[0].selected = true;

            var tempformData = [...formData];
            tempformData.filter(fd => fd.name === field.name)[0]["userinput"] = h.values.filter(v => v.selected === true).map(u => u.value).join('^');
            setFormData([...tempformData]);
        }
        else if (field.type === "radio-group") {
            // //console.log(temp.filter(fd => fd.name === field.name && fd.values.some(v => v.value === value)));
            // var h = temp.filter(fd => fd.name === field.name)[0];
            // //console.log(h);
            // // h.values.filter(v => v.value === value)[0].selected = true;
            // h.values.forEach((v) => {
            //     //console.log("v", v);
            //     v.value === value ? (v.selected = true) : (v.selected = false)
            // })
            // //console.log(h.values.filter(v => v.value === value)[0]);
            // // console.log("temp:", temp);
            // setFormFields([...temp]);

            var tempformData = [...formData];
            tempformData.filter(fd => fd.name === field.name)[0]["userinput"] = value;
            //console.log(tempformData.filter(fd => fd.name === field.name)[0]);
            // console.log("id", field.name, "value", tempformData.filter(fd => fd.name === field.name)[0]["userinput"]);
            setFormData([...tempformData]);
        }
        else if (field.type === "date" || field.type === "number" || field.type === "select" || field.type === "text" || field.type === "textarea") {
            var tempformData = [...formData];
            tempformData.filter(fd => fd.name === field.name)[0]["userinput"] = value;
            //console.log(tempformData.filter(fd => fd.name === field.name)[0]);
            // console.log("id", field.name, "value", tempformData.filter(fd => fd.name === field.name)[0]["userinput"]);
            setFormData([...tempformData]);
        }
    }

    const CheckBoxGroupField = (field, index) => {
        return (
            <div className="form-group mb-0" key={index}>
                <label className="col-form-label">{field.label}{field.required && (<span className="ms-1 text-danger">*</span>)}</label>
                {
                    field.values.map((value, index) => (
                        <div className="payment-head" key={index}>
                            <div className="payment-title">
                                <label className="custom_check credit-card-option mb-3">
                                    <input type="checkbox" id={field.name + "-" + index} name={field.name} className="card-payment" value={value.value} checked={formData.filter(fd => fd.name === field.name && fd.userinput.split('^').includes(value.value)).length > 0 ? true : false} onChange={(e) => handleOnChange(field, e.target.value)} />
                                    <span className="checkmark" style={{ marginTop: "0px" }}></span>
                                    <h6 className='ms-2'>{value.label}</h6>
                                </label>
                            </div>
                        </div>
                    ))
                }
            </div>
        )
    }

    const DateField = (field, index) => {
        return (
            <div className="form-group mb-1" key={index}>
                <label className="col-form-label">{field.label}{field.required && (<span className="ms-1 text-danger">*</span>)}</label>
                <div className="form-icon">
                    <input type={field.type} className="form-control datetimepicker" placeholder="DD/MM/YYYY" name={field.name} style={{ paddingRight: "10px" }} value={formData.filter(fd => fd.name === field.name)[0]["userinput"]} onChange={(e) => handleOnChange(field, e.target.value)} />
                    {/* <span className="cus-icon"><i className="feather-calendar"></i></span> */}
                </div>
            </div>
        )
    }

    const NumberField = (field, index) => {
        return (
            <div className="form-group mb-1" key={index}>
                <label className="col-form-label">{field.label}{field.required && (<span className="ms-1 text-danger">*</span>)}</label>
                <input type={field.type} name={field.name} className="form-control" placeholder="" value={formData.filter(fd => fd.name === field.name)[0]["userinput"]} onChange={(e) => handleOnChange(field, e.target.value)} />
            </div>
        )
    }

    const RadioGroupField = (field, index) => {
        return (
            <div className="form-group mb-0" key={index}>
                <label className="col-form-label">{field.label}{field.required && (<span className="ms-1 text-danger">*</span>)}</label>
                {
                    field.values.map((value, index) => (
                        <div className="payment-head" key={index}>
                            <div className="payment-title">
                                <label className="custom_radio credit-card-option mb-3">
                                    <input type="radio" id={field.name + "-" + index} name={field.name} className="card-payment" value={value.value} checked={formData.filter(fd => fd.name === field.name && fd.userinput === value.value).length > 0 ? true : false} onChange={(e) => handleOnChange(field, e.target.value)} />
                                    <span className="checkmark" style={{ marginTop: "0px" }}></span>
                                    <h6>{value.label}</h6>
                                </label>
                            </div>
                        </div>
                    ))
                }
            </div>
        )
    }

    const SelectField = (field, index) => {
        return (
            <div className="form-group mb-1" key={index}>
                <label className="col-form-label">{field.label}{field.required && (<span className="ms-1 text-danger">*</span>)}</label>
                <select className="select form-control" name={field.name} required={field.required} value={formData.filter(fd => fd.name === field.name)[0]["userinput"]} onChange={(e) => handleOnChange(field, e.target.value)}>
                    <option value={"-1"} >Select</option>
                    {
                        field.values.map((value, index) => (
                            <option value={value.value} key={index}>{value.label}</option>
                        ))
                    }
                </select>
            </div>
        )
    }

    const TextField = (field, index) => {
        return (
            <div className="form-group mb-1" key={index}>
                <label className="col-form-label">{field.label}{field.required && (<span className="ms-1 text-danger">*</span>)}</label>
                <input type={field.type} name={field.name} className="form-control" placeholder="" value={formData.filter(fd => fd.name === field.name)[0]["userinput"]} required={field.required} onChange={(e) => handleOnChange(field, e.target.value)} />
            </div>
        )
    }

    const TextAreaField = (field, index) => {
        return (
            <div className="form-group mb-1" key={index}>
                {/* <label className="col-form-label d-block">Your Bio <span className="brief-bio float-end">Brief description for your profile. URLs are hyperlinked.</span></label> */}
                <label className="col-form-label d-block">{field.label}{field.required && (<span className="ms-1 text-danger">*</span>)}</label>
                <textarea className="form-control" name={field.name} rows="5" placeholder="" value={formData.filter(fd => fd.name === field.name)[0]["userinput"]} required={field.required} onChange={(e) => handleOnChange(field, e.target.value)}></textarea>
            </div>
        )
    }

    return (
        formData.length > 0 && formFields.map((field, index) => {
            switch (field.type) {
                case "checkbox-group":
                    return CheckBoxGroupField(field, index);////<CheckBoxGroupField field={field} key={index} />;
                case "date":
                    return DateField(field, index);//<DateField field={field} key={index} />;
                case "number":
                    return NumberField(field, index);//<NumberField field={field} key={index} />;
                case "radio-group":
                    return RadioGroupField(field, index);//<RadioGroupField field={field} key={index} />;
                case "select":
                    return SelectField(field, index);//<SelectField field={field} key={index} />;
                case "text":
                    return TextField(field, index);//<TextField field={field} key={index} />;
                case "textarea":
                    return TextAreaField(field, index);//<TextAreaField field={field} key={index} />;
                default:
                    break;
            }
        })
    )
}

export default ServiceForm