import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import Header from '../../components/Header';
import Alert from '../../components/Alert';

import { getCookie, setCookie, checkRole } from '../../func';

const Login = () => {
    let location = useLocation();
    // console.log("location: ", location);
    // console.log("location.search: ", location.search);
    // console.log(`<div>Search query is ${new URLSearchParams(location.search).get('url')}</div>`)
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const [alert, setAlert] = useState({
        type: '',
        message: '',
        show: false
    });

    // useEffect(()=>{
    //     console.log("rememberMe: ", rememberMe);
    // }, [rememberMe])
    useEffect(() => {
        //console.log(checkRole(getCookie("token")));
        if (getCookie("token") !== undefined && getCookie("token") !== null) {
            //user is already loggedin
            if (checkRole(getCookie("token")) === "admin")
                navigate(`/dashboard`, { replace: true });
            else
                navigate(`/dashboard`, { replace: true });
        }
    }, [])

    const handleMessage = (params) => {
        setAlert({ type: params.type, message: params.message, show: true });

        setTimeout(() => {
            setAlert({ type: '', message: '', show: false });
        }, 2000);
    }

    const Login = () => {
        const json = JSON.stringify({ "email": email, "password": password });
        const url = `${process.env.REACT_APP_BASE_API}/api.php?action=login`;

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        }).then((response) => {
            if (response.data.success === "true") {
                handleMessage({ type: 'success', message: "Login successfully", show: true });

                setTimeout(() => {
                    setCookie("token", response.data.token, !rememberMe ? 7 : 365); //if "remember me" is checked save login token cookie for 1 year else save login for 7 days
                    // if (checkRole(response.data.token) === "admin")
                    //     navigate(`/dashboard`, { replace: true });
                    // else
                    //   navigate(`/admin/add_listing`, { replace: true });
                    if (new URLSearchParams(location.search).get('url') !== null)
                        navigate(`${new URLSearchParams(location.search).get('url')}`);
                    else
                        navigate(`/dashboard`, { replace: true });
                }, 2000);
            }
            else {
                handleMessage({ type: 'danger', message: response.data.message, show: true });
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
            handleMessage({ type: 'danger', message: "Something went wrong. Please try later", show: true });
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (email.trim() !== "" && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) && password.trim() !== "") {
            Login();
        }
        else {
            if (email.trim() === "" || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email))
                handleMessage({ type: 'info', message: "Please provide valid email address", show: true }); //console.log("email is required");          
            else if (password.trim() !== "")
                handleMessage({ type: 'info', message: "Please provide password", show: true });//console.log("password is required");
            else
                handleMessage({ type: 'info', message: "Please provide valid email and password", show: true });//console.log("Please provide valid email and password");
        }
    }

    return (
        <>
            <Header />

            <div className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-6 mx-auto">
                            <div className="login-wrap">
                                <Alert type={alert.type} message={alert.message} show={alert.show} />
                                <div className="login-header mb-3">
                                    <h3 className='mb-1'>Login</h3>
                                    <p className='mb-0'>Please enter your details</p>
                                    {/* <h6>Sign in with <a href="login-phone.html">Phone Number</a></h6> */}
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="log-form pb-0">
                                        <div className="form-group">
                                            <label className="col-form-label">Email</label>
                                            <input type="text" className="form-control" placeholder="johndoe@example.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                                        </div>
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col">
                                                    <label className="col-form-label">Password</label>
                                                </div>
                                                <div className="col-auto">
                                                    <Link className="forgot-link" to={"/password_recovery"}>
                                                        Forgot password?
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="pass-group">
                                                <input type={showPassword ? "text" : "password"} className="form-control pass-input" placeholder="*************" value={password} onChange={(e) => setPassword(e.target.value)} />
                                                <span className={`toggle-password ${showPassword ? 'feather-eye-off' : 'feather-eye'}`} onClick={() => { setShowPassword(!showPassword) }} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <label className="custom_check">
                                                    <input type="checkbox" name="rememberme" className="rememberme" value="rememberme" checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} />
                                                    <span className="checkmark" />Remember Me
                                                </label>
                                            </div>
                                            {/* <div className="col-6 text-end">
                                            <label className="custom_check">
                                                <input type="checkbox" name="loginotp" className="loginotp" />
                                                <span className="checkmark" />Login with OTP
                                            </label>
                                        </div> */}
                                        </div>
                                    </div>
                                    <button className="btn btn-primary w-100 login-btn" type="submit">Login</button>
                                    {/* <div className="login-or">
                                    <span className="or-line" />
                                    <span className="span-or">OR</span>
                                </div>
                                <div className="social-login">
                                    <a href="#" className="btn btn-google w-100">
                                        <img src="assets/img/icons/google.svg" className="me-2" alt="img" />Login with Google
                                    </a>
                                    <a href="#" className="btn btn-google w-100">
                                        <img src="assets/img/icons/fb.svg" className="me-2" alt="img" />Login with Facebook
                                    </a>
                                </div> */}
                                    <p className="no-acc mt-1">Don't have an account ? <Link to={"/register"}>Register</Link></p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mouse-cursor cursor-outer" style={{ visibility: 'visible' }} />
            <div className="mouse-cursor cursor-inner" style={{ visibility: 'visible' }} />
        </>
    )
}

export default Login