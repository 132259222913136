import React, { useState, useEffect } from 'react'
import axios from "axios";
import { Link, useNavigate, useParams } from 'react-router-dom';

import Header from '../../../components/Header';
import SideBar from '../../../components/SideBar';

import Alert from '../../../components/Alert';

import Cookies from 'universal-cookie';

// import { getCookie, checkRole } from '../../../func';
import { getCookie, eraseCookie, checkRole } from '../../../func';
import AddedCampaigns from '../../../components/AddedCampaigns';

const cookies = new Cookies();

const pageItemLimit = 10;

const UpdateCampaign = () => {
    const navigate = useNavigate();
    let params = useParams();

    const [previousData, setPreviousData] = useState({});
    const [name, setName] = useState("");
    const [details, setDetails] = useState("");
    // const [images, setImages] = useState([]);
    const [features, setFeatures] = useState([]);
    const [campaign, setCampaign] = useState([]); //Live
    const [totalCampaigns, setTotalCampaigns] = useState([]);

    const [enableSaveButton, setEnableSaveButton] = useState(false);

    const [campaignPackgeServicePrice, setCampaignPackgeServicePrice] = useState(5);

    const [alert, setAlert] = useState({
        type: '',
        message: '',
        show: false
    });

    const [cookieName, setCookieName] = useState('');
    const [formjson, setFormJson] = useState([]);

    const [savedCampaigns, setSavedCampaigns] = useState([]);

    useEffect(() => {
        if (getCookie("token") !== undefined && getCookie("token") !== null) {
            if (checkRole(getCookie("token")) !== "admin")
                navigate(`/dashboard`, { replace: true });
            else {
                if (params.slug !== undefined && params.slug !== null && params.slug.toString().trim() !== "") {
                    fetchCampaignDetails(params.slug);
                }
                else {
                    navigate(`/dashboard`, { replace: true });
                }
            }
        }
        else {
            navigate(`/login`, { replace: true });
        }
    }, [])

    useEffect(() => {
        if (previousData.id !== undefined && previousData.id !== '') {
            setName(previousData.name);
            setDetails(previousData.details);
            setCampaign(previousData.campaign);
            setFormJson(previousData.formjson);

            var temp = "update-campaign-" + Math.floor(Date.now() / 1000);
            // console.log("temp: ", temp);
            setCookieName(temp);

            window["bindFormBuilder"](temp, JSON.stringify(previousData.formjson));

            const cookieChangeListener = (cookie, value) => {
                if (cookie.name === temp) {
                    setFormJson((typeof cookie.value !== 'undefined' && cookie.value.length > 0) ? [...cookie.value] : []);
                }
            }

            cookies.addChangeListener(cookieChangeListener);

            return () => {
                cookies.removeChangeListener(cookieChangeListener);
            }
        }
    }, [previousData]);

    useEffect(() => {
        try {
            if (name.trim() !== "" && details.trim() !== "" && campaign.length > 0) {
                setEnableSaveButton(true);
                console.log(JSON.stringify(campaign));
            }
            else {
                setEnableSaveButton(false);
            }
        } catch (err) {
            console.log("error: ", err);
        }
    }, [name, details, campaign])

    const handleMessage = (params) => {
        setAlert({ type: params.type, message: params.message, show: true });

        setTimeout(() => {
            setAlert({ type: '', message: '', show: false });
        }, 3000);
    }

    // const changeHandler = (e) => {
    //     let files = e.target.files;
    //     //console.log("files: ", files);

    //     var allFiles = [];
    //     for (var i = 0; i < files.length; i++) {

    //         let file = files[i];

    //         let ext = '';
    //         switch (file.type) {
    //             case "image/x-png":
    //                 ext = "png"
    //                 break;
    //             case "image/png":
    //                 ext = "png"
    //                 break;
    //             case "image/jpeg":
    //                 ext = "jpg"
    //                 break;
    //             case "image/webp":
    //                 ext = "webp"
    //                 break;
    //             default:
    //                 break;
    //         }

    //         let reader = new FileReader();
    //         reader.readAsDataURL(file);
    //         reader.onload = () => {
    //             let fileInfo = {
    //                 name: file.name.replace(`.${ext}`, ''),
    //                 type: file.type,
    //                 extention: ext,
    //                 size: Math.round(file.size / 1000) + ' kB',
    //                 base64: reader.result,
    //                 file: file,
    //             };

    //             allFiles.push(fileInfo);

    //             if (allFiles.length == files.length) {
    //                 console.log(allFiles);
    //                 //if (this.props.multiple) this.props.onDone(allFiles);
    //                 //else this.props.onDone(allFiles[0]);
    //                 setImages(allFiles);
    //             }
    //         }
    //     }
    // }

    // const removeImage = (i) => {
    //     let array = [...images];
    //     array.splice(i, 1);
    //     setImages(array);
    // }

    const handleAddCampaignServices = () => {
        setFeatures([...features, { service: "", details: "", quantity: "", tier: "" }]);
        // setCampaign([...campaign, { service: "", details: "", quantity: "", tier: "" }]);
    };

    const handleCampaignServiceChange = (event, index) => {
        let { name, value } = event.target;
        let onChangeValue = [...features];
        onChangeValue[index][name] = value;
        setFeatures(onChangeValue);

        // let { name, value } = event.target;
        // let onChangeValue = [...campaign];
        // onChangeValue[index][name] = value;
        // setCampaign(onChangeValue);
    };

    const handleDeleteCampaignService = (index) => {
        const newArray = [...features];
        newArray.splice(index, 1);
        setFeatures(newArray);

        // const newArray = [...campaign];
        // newArray.splice(index, 1);
        // setCampaign(newArray);
    };

    const clear = () => {
        setName('');
        setDetails('');
        // setImages([]);
        // setFeatures([]);
        setCampaign([]);
    }

    const fetchCampaignDetails = (slug) => {
        const json = JSON.stringify({ "slug": slug });
        const url = `${process.env.REACT_APP_BASE_API}/api.php?action=get_campaign_details`;

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                var campaign_details = response.data.campaign_details[0];
                // console.log("campaign_details: ", campaign_details);
                setPreviousData({
                    id: campaign_details.campaign_id,
                    name: campaign_details.campaign_title,
                    details: campaign_details.campaign_description,
                    campaign: campaign_details.campaign_packages !== "" ? JSON.parse(campaign_details.campaign_packages) : [],
                    formjson: campaign_details.campaign_form !== "" ? JSON.parse(campaign_details.campaign_form) : []
                })
            }
            else {
                handleMessage({ type: 'danger', message: response.data.message, show: true });

                setTimeout(() => {
                    navigate(`/dashboard`, { replace: true });
                }, 1500)
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });

        fetchCampaigns();
    }

    const fetchCampaigns = () => {
        const json = JSON.stringify({ "token": getCookie("token"), "limit": pageItemLimit, "offset": 0 });
        const url = `${process.env.REACT_APP_BASE_API}/api.php?action=get_campaigns`;

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                setTotalCampaigns(response.data.total_campaigns);
                setSavedCampaigns(response.data.campaigns);
            }
            else {
                handleMessage({ type: 'danger', message: response.data.message, show: true });
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    }

    const updateCampaign = () => {
        // debugger;
        var x = window["returnFormJSON"]();
        // console.log("x: ", x);
        setFormJson([...JSON.parse(x)]);

        setTimeout(() => {
            // var nonEmptyIncludes = features.filter(value => value.feature !== '');
            var campaigns_string = campaign.length > 0 ? JSON.stringify(campaign) : "";

            if (name.trim() !== "" && details.trim() !== "" && (campaigns_string.trim() !== "" && campaign.length > 0)) {
                const json = JSON.stringify({ "token": getCookie("token"), "oldtitle": previousData.name, "title": name, "description": details, "packages": campaigns_string, "formjson": JSON.stringify(formjson) });

                const url = `${process.env.REACT_APP_BASE_API}/api.php?action=update_campaign`;

                axios.post(url, JSON.stringify({ params: json }), {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).then((response) => {
                    if (response.data.success === "true") {
                        //console.log("package added successfully");
                        // clear();
                        handleMessage({ type: 'success', message: response.data.message, show: true });
                    }
                    else {
                        handleMessage({ type: 'danger', message: response.data.message, show: true });
                    }
                }).catch((error) => {
                    console.error(`Error: ${error}`);
                });
            }
            else {
                if (name.trim() === "" && details.trim() === "" && campaigns_string.trim() === "")
                    handleMessage({ type: 'danger', message: "Please fill all the required fields", show: true });
                else if (name.trim() === "")
                    handleMessage({ type: 'danger', message: "Please provide campaign title", show: true });
                else if (details.trim() === "")
                    handleMessage({ type: 'danger', message: "Please provide campaign description", show: true });
                else if (campaigns_string.trim() === "" || campaign.length === 0)
                    handleMessage({ type: 'danger', message: "Please provide services included in this campaign", show: true });
            }
        }, 1500)
    }

    const removeCampaign = (i) => {
        // console.log("index: ", i);
        if (i > -1) {
            var tempCampaign = [...campaign];
            tempCampaign.splice(i, 1);
            setCampaign([...tempCampaign]);
        }
    }

    const campaignPackageExists = (campaign, newCampaignPackgeServicePrice) => {
        if (campaign.length > 0) {
            const found = campaign.find((c) => c.price === newCampaignPackgeServicePrice);
            console.log("campaign package already exists");
            return found;
        }

        return false;
    }

    const addPackageToCampaign = () => {
        var tempFeatures = [];
        tempFeatures = [...features.filter(feature => feature.service.trim() !== "" && feature.quantity.trim() !== "")];
        //console.log("tempFeatures", tempFeatures);

        if (campaignPackgeServicePrice >= 5 && tempFeatures.length > 0) {
            var tempCampaign = [...campaign];
            if (!campaignPackageExists(tempCampaign, campaignPackgeServicePrice)) {
                tempCampaign.push({ services: [...tempFeatures], price: campaignPackgeServicePrice });
                // console.log("tempCampaign: ", tempCampaign);
                // console.log("tempCampaign: ", JSON.stringify(tempCampaign));
                setCampaignPackgeServicePrice(5);
                setFeatures([]);
                setCampaign([...tempCampaign]);
            }
            else {
                handleMessage({ type: 'danger', message: `Campaign service package with this price "$${campaignPackgeServicePrice}" already exists`, show: true });
            }
        }
        else {
            if (campaignPackgeServicePrice < 5)
                handleMessage({ type: 'danger', message: "Please provide service package price", show: true });
            else if (tempFeatures.length === 0)
                handleMessage({ type: 'danger', message: "Please provide campaign services package", show: true });
        }
    }

    // const AddedCampaigns = () => {
    //     return (
    //         <>
    //             <h6 className="user-title mt-5">All Campaigns</h6>
    //             <div className="table-responsive">
    //                 <table className="table mb-0 custom-table">
    //                     <thead className="thead-light">
    //                         <tr>
    //                             <th>#</th>
    //                             <th>Title</th>
    //                             <th>Description</th>
    //                             <th>Status</th>
    //                             <th>Acion</th>
    //                         </tr>
    //                     </thead>
    //                     <tbody>
    //                         {savedCampaigns.map((camp, index) => (
    //                             <tr key={index}>
    //                                 <td>{atob(atob(camp.campaign_id))}</td>
    //                                 <td>
    //                                     <h2 className="table-avatar">
    //                                         {/* <div className="avatar avatar-m me-2">
    //                                             <img className="avatar-img rounded" src={`${process.env.REACT_APP_BASE_API}${camp.service_images}`} alt="service img" />
    //                                         </div> */}
    //                                         <div>
    //                                             {camp.campaign_title}
    //                                             {/* <span>{camp.service_sub_title}</span> */}
    //                                         </div>
    //                                     </h2>
    //                                 </td>
    //                                 <td className="text-body">{getWords(camp.campaign_description, 5) + "..."}</td>
    //                                 <td>{camp.campaign_status === 1 ? <span className="badge-success">Active</span> : <span className="badge-danger">Inactive</span>}</td>
    //                                 <td>
    //                                     {
    //                                         camp.campaign_status === 1 ? <button className='btn btn-light-danger' onClick={() => activatedeativeCampaign("0", atob(atob(camp.campaign_id)))}>Deactivate</button> : <button className='btn btn-light-success' onClick={() => activatedeativeCampaign("1", atob(atob(camp.campaign_id)))}>Activate</button>
    //                                     }
    //                                     <Link to={`/update_campaign/${camp.campaign_slug}`} className='btn btn-sm btn-success ms-2' target='_blank'>Edit</Link>
    //                                 </td>
    //                             </tr>
    //                         ))}
    //                     </tbody>
    //                 </table>
    //             </div>
    //         </>
    //     )
    // }

    // const activatedeativeCampaign = (action, id) => {
    //     const json = JSON.stringify({ "token": getCookie("token"), "campaign_id": id, "action": action });
    //     const url = `${process.env.REACT_APP_BASE_API}/api.php?action=activate_deactivate_campaign`;

    //     axios.post(url, JSON.stringify({ params: json }), {
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         }
    //     }).then((response) => {
    //         if (response.data.success === "true") {
    //             var temp = [...savedCampaigns];
    //             temp.filter(s => s.campaign_id === btoa(btoa(id)))[0]["campaign_status"] = parseInt(action);
    //             setSavedCampaigns([...temp]);
    //             handleMessage({ type: 'success', message: response.data.message, show: true });
    //         }
    //         else {
    //             handleMessage({ type: 'danger', message: response.data.message, show: true });
    //         }
    //     }).catch((error) => {
    //         console.error(`Error: ${error}`);
    //     });
    // }

    // const getWords = (str, limit) => {
    //     return str.split(/\s+/).slice(0, limit).join(" ");
    // }

    return (
        <>
            <Header />

            <div className="content">
                <div className="container">
                    <div className="row">

                        <SideBar />

                        <div className="col-lg-9">
                            <Alert type={alert.type} message={alert.message} show={alert.show} />
                            <div className="widget-title">
                                <h4>Update Campaign</h4>
                            </div>
                            <h6 className="user-title">General Information</h6>
                            <div className="general-info mb-4">
                                <div className="row">
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">Name <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" placeholder="Enter campaign name" value={name} onChange={(e) => setName(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12">
                                        <div className="form-group">
                                            <label className="col-form-label d-block">Description <span className="text-danger">*</span> <span className="brief-bio float-end">Brief description for campaign.</span></label>
                                            <textarea className="form-control" rows={5} placeholder="Add a brief description of campaign....." value={details} onChange={(e) => setDetails(e.target.value)} />
                                        </div>
                                    </div>

                                    {/* <div className="col-sm-12 col-md-12">
                                        <h6 className="user-title">Campaign Image</h6>
                                        <div className="service-image-box mb-4">
                                            {images.length > 0 && <div className="service-image mb-2">
                                                <img src={images[0]["base64"]} alt="user" style={{ width: "300px", height: "250px", borderRadius: "8px" }} />
                                            </div>}
                                            <div className="pro-info">
                                                <div className="d-flex">
                                                    <div className="img-upload">
                                                        <i className="feather-upload-cloud me-1" />Upload
                                                        <input type="file" onChange={changeHandler} accept="image/x-png,image/gif,image/jpeg" />
                                                    </div>
                                                    <button className="btn btn-remove" onClick={() => removeImage(0)}>Remove</button>
                                                </div>
                                                <p>*image size should be at least 640px in width and 260px in height,and less then 500kb. Allowed files .png and .jpg.</p>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>

                                <div className="rounded-3 mx-0 mb-4" style={{ backgroundColor: "#f7f7ff", border: "1px solid #e7e7ee" }}>
                                    <div className="row p-4">
                                        <div className="col-12 d-flex align-items-center mb-3">
                                            <h6>Add Campaign Services Packages</h6>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="col-form-label">Campaign Service Package Price <span className="text-danger">*</span></label>
                                                <input type="number" className="form-control" placeholder="Enter campaign service package price" value={campaignPackgeServicePrice} onChange={(e) => setCampaignPackgeServicePrice(e.target.value)} min={5} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row px-4 pb-4">
                                        <div className="col-12 d-flex align-items-center mb-3">
                                            <button className='btnaddfeature me-2' onClick={() => handleAddCampaignServices()}>+</button>
                                            <h6>Add Campaign Services Package</h6>
                                        </div>
                                        {features.map((feature, index) => (
                                            <div className="row" key={index}>

                                                <div className='col-md-10'>
                                                    <div className='row border-bottom'>
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-2">
                                                                <label className="col-form-label py-0">Service Name <span className="text-danger">*</span></label>
                                                                <input type="text" name="service" className="form-control" placeholder="Enter service name" value={feature.feature} onChange={(event) => handleCampaignServiceChange(event, index)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-2">
                                                                <label className="col-form-label py-0">Service Details <span className="text-danger">*</span></label>
                                                                <input type="text" name="details" className="form-control" placeholder="Enter service details" value={feature.detail} onChange={(event) => handleCampaignServiceChange(event, index)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-2">
                                                                <label className="col-form-label py-0">Quantity <span className="text-danger">*</span></label>
                                                                <input type="number" name="quantity" className="form-control" placeholder="Enter service quantity" value={feature.quantity} min={1} onChange={(event) => handleCampaignServiceChange(event, index)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-2"><label className="col-form-label py-0">Tier Details</label>
                                                                <input type="text" name="tier" className="form-control" placeholder="Enter service tier details: Tier project for 1,2" value={feature.tier} onChange={(event) => handleCampaignServiceChange(event, index)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='col-md-2'>
                                                    <div className="d-flex align-items-center justify-content-end mb-3">
                                                        {features.length >= 1 && (
                                                            <button className="btnremovefeature me-2 mt-3" onClick={() => handleDeleteCampaignService(index)}>Delete</button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                        {features.length > 0 && (
                                            <div className='col-12'>
                                                <button className='btn btn-sm btn-success' onClick={() => addPackageToCampaign()}>Add Package</button>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* <div className="row"> */}
                                {
                                    campaign.length > 0 && campaign.map((c, i) => (
                                        <div className="row p-2 mx-0 mb-1 rounded-3 align-items-center" style={{ border: "1px solid #e7e7ee" }} key={i}>
                                            <div className="col-md-1"><span className='fw-bold text-black'>${c.price}</span></div>
                                            <div className="col-md-10">
                                                {
                                                    c?.services?.map((s, index) => (
                                                        <div className={`row${index < c.services.length - 1 ? ' border-bottom' : ''}`} key={index}>
                                                            <div className='col-md-3'><span className='fw-bold text-black'>Name</span><p className='mb-1'>{s.service}</p></div>
                                                            {/* <div className='col-md-3'><span className='fw-bold text-black'>Details</span><p className='mb-1'>{s.details}</p></div> */}
                                                            <div className='col-md-3'><span className='fw-bold text-black'>Details</span><p className='mb-1 d-flex flex-column'>
                                                                {
                                                                    s.details.split("||").map((p, i) => (
                                                                        <span className='text-start' key={i}>↳ {p}</span>
                                                                    ))
                                                                }
                                                            </p></div>
                                                            <div className='col-md-3'><span className='fw-bold text-black'>Quantity</span><p className='mb-1'>{s.quantity}</p></div>
                                                            <div className='col-md-3'><span className='fw-bold text-black'>Tier</span><p className='mb-1'>{s.tier}</p></div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            <div className="col-md-1 text-center"><i className="feather-trash-2 text-black fs-5" onClick={() => { removeCampaign(i) }}></i></div>
                                        </div>
                                    ))
                                }
                                {/* </div> */}
                            </div>

                            <div className="row">
                                <div className="my-4">
                                    <h6 className="user-title m-0">Create form to accept required data for this campaign.</h6>
                                </div>
                                <div id="fb-editor" className="mb-4" />
                            </div>

                            <div className='row'>
                                <div className="acc-submit">
                                    {
                                        enableSaveButton
                                            ?
                                            (
                                                <>
                                                    <button className="btn btn-secondary" onClick={() => clear()}>Cancel</button>
                                                    <button className="btn btn-primary" onClick={() => updateCampaign()}>Update Changes</button>
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    <button className="btn btn-secondary" disabled>Cancel</button>
                                                    <button className="btn btn-primary" disabled>Update Changes</button>
                                                </>
                                            )
                                    }
                                </div>

                                {/* {savedCampaigns.length > 0 && <AddedCampaigns />} */}
                                {savedCampaigns.length > 0 && <AddedCampaigns campaigns={savedCampaigns} TotalCampaigns={totalCampaigns} ItemLimit={pageItemLimit} />}
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div className="mouse-cursor cursor-outer" style={{ visibility: 'visible' }} />
            <div className="mouse-cursor cursor-inner" style={{ visibility: 'visible' }} />
        </>
    )
}

export default UpdateCampaign