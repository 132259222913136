import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Header from '../../../components/Header';
import SideBar from '../../../components/SideBar';

import { getCookie, base64_decode } from '../../../func';
import NormalOrders from '../../../components/NormalOrders';
import CampaignOrders from '../../../components/CampaignOrders';

const pageItemLimit = 10;

const Dashboard = () => {
    const navigate = useNavigate();
    const [role, setRole] = useState('');
    const [orders, setOrders] = useState([]);
    const [campaignOrders, setCampaignOrders] = useState([]);

    const [totalOrders, setTotalOrders] = useState([]);
    const [totalCampaignOrders, setTotalCampaignOrders] = useState(undefined);
    const [totalServices, setTotalServices] = useState([]);
    const [totalPackages, setTotalPackages] = useState([]);
    const [totalUsers, setTotalUsers] = useState([]);

    const [selectedExtras, setSelectedExtras] = useState([]);
    const [selectedOrderDetails, setSelectedOrderDetails] = useState([]);

    const [selectedPackageServices, setSelectedPackageServices] = useState([]);

    useEffect(() => {
        if (getCookie("token") === undefined || getCookie("token") === null) {
            navigate(`/login`, { replace: true });
        }
        else {
            var temptoken = base64_decode(getCookie("token"));
            setRole(temptoken.split("|")[3]);

            fetchDashboardStats();
        }
    }, [])

    // useEffect(() => {
    //     if (selectedPackageServices.length > 0) {
    //         console.log("selectedPackageServices: ", selectedPackageServices);
    //     }
    // }, [selectedPackageServices])

    const fetchDashboardStats = () => {
        const json = JSON.stringify({ "token": getCookie("token"), "limit": pageItemLimit, "offset": 0 });
        const url = `${process.env.REACT_APP_BASE_API}/api.php?action=get_dashboard_stats`;

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                // console.log("data:", response.data);

                setTotalOrders(response.data.total_orders);
                setTotalCampaignOrders(response.data.total_campaign_orders);
                setTotalServices(response.data.total_services);
                setTotalPackages(response.data.total_packages);
                setTotalUsers(response.data.total_users);

                setOrders(response.data.orders);
                setCampaignOrders(response.data.campaign_orders);
                //setPackages(response.data.packages);
            }
            // else {
            //     handleMessage({ type: 'danger', message: response.data.message, show: true });
            // }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    }

    const showOrderDetails = (order_details) => {
        // // console.log("order_details: ", JSON.parse(order_details));
        // setSelectedExtras([...JSON.parse(order_details)["extras"]]);
        // // console.log("order_details: ", JSON.parse(order_details)["orderDetails"]);
        // setSelectedOrderDetails([...JSON.parse(order_details)["orderDetails"]])
        // setSelectedPackageServices([...JSON.parse(order_details)["selectedCampaign"]]);

        var tempDetails = JSON.parse(order_details);
        setSelectedExtras(tempDetails.hasOwnProperty("extras") ? [...tempDetails["extras"]] : []);
        setSelectedOrderDetails(tempDetails.hasOwnProperty("orderDetails") ? [...tempDetails["orderDetails"]] : []);
        setSelectedPackageServices(tempDetails.hasOwnProperty("selectedCampaign") ? [...tempDetails["selectedCampaign"]] : []);
    }

    // const Orders = () => {
    //     return <>
    //         <h6 className="user-title">Orders</h6>
    //         <div className="table-responsive">
    //             <table className="table mb-0 custom-table">
    //                 <thead className="thead-light">
    //                     <tr>
    //                         <th>#</th>
    //                         <th>Details</th>
    //                         <th>Amount</th>
    //                         <th>Date</th>
    //                         <th>Payment Method</th>
    //                         <th>Status</th>
    //                         {orders.length > 0 && orders.order_details !== "" && <th>Action</th>}
    //                     </tr>
    //                 </thead>
    //                 <tbody>
    //                     {
    //                         orders.length > 0 ?
    //                             orders.map((order, index) => (
    //                                 <tr key={index}>
    //                                     <td>{index + 1}</td>
    //                                     <td>{order.package_name}</td>
    //                                     <td className="text-light-success">${order.order_price}</td>
    //                                     <td className="text-body">{order.order_date}</td>
    //                                     <td className="text-body" style={{ textTransform: "capitalize" }}>{order.payment_method}</td>
    //                                     <td><span className="badge-success" style={{ textTransform: "capitalize" }}>{order.order_status}</span></td>
    //                                     {
    //                                         order.order_details !== "" &&
    //                                         <td>
    //                                             <button type="button" className="ms-2 btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#detailsModal" onClick={() => { showOrderDetails(order.order_details) }}>
    //                                                 <i className="feather-more-vertical"></i>
    //                                             </button>
    //                                         </td>
    //                                     }
    //                                 </tr>
    //                             ))
    //                             :
    //                             <tr>
    //                                 <td colSpan={6} className='text-center'>
    //                                     <span>You have not order any service yet!</span>
    //                                 </td>
    //                             </tr>
    //                     }
    //                 </tbody>
    //             </table>
    //         </div>
    //     </>
    // }

    // const CampaignOrders = () => {
    //     return <>
    //         <h6 className="user-title mt-4">Campaign Orders</h6>
    //         <div className="table-responsive">
    //             <table className="table mb-0 custom-table">
    //                 <thead className="thead-light">
    //                     <tr>
    //                         <th>#</th>
    //                         <th>Details</th>
    //                         <th>Amount</th>
    //                         <th>Date</th>
    //                         <th>Payment Method</th>
    //                         <th>Status</th>
    //                         {campaignOrders.length > 0 && campaignOrders.order_details !== "" && <th>Action</th>}
    //                     </tr>
    //                 </thead>
    //                 <tbody>
    //                     {
    //                         campaignOrders.length > 0 ?
    //                             campaignOrders.map((co, index) => (
    //                                 <tr key={index}>
    //                                     <td>{index + 1}</td>
    //                                     <td>{co.campaign_title}</td>
    //                                     <td className="text-light-success">${co.order_price}</td>
    //                                     <td className="text-body">{co.order_date}</td>
    //                                     <td className="text-body" style={{ textTransform: "capitalize" }}>{co.payment_method}</td>
    //                                     <td><span className="badge-success" style={{ textTransform: "capitalize" }}>{co.order_status}</span></td>
    //                                     {
    //                                         co.order_details !== "" &&
    //                                         <td>
    //                                             <button type="button" className="ms-2 btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#detailsModal" onClick={() => { showOrderDetails(co.order_details) }}>
    //                                                 <i className="feather-more-vertical"></i>
    //                                             </button>
    //                                         </td>
    //                                     }
    //                                 </tr>
    //                             ))
    //                             :
    //                             <tr>
    //                                 <td colSpan={6} className='text-center'>
    //                                     <span>You have not order any service yet!</span>
    //                                 </td>
    //                             </tr>
    //                     }
    //                 </tbody>
    //             </table>
    //         </div>
    //     </>
    // }

    return (
        <>
            <Header />

            <div className="content">
                <div className="container">
                    <div className="row">

                        <SideBar />

                        <div className="col-lg-9">
                            <div className="widget-title">
                                <h4>Dashboard</h4>
                            </div>
                            {role === "admin" && (
                                <div className="row">
                                    <div className="col-md-6 col-lg-3">
                                        <div className="dash-widget">
                                            <div className="dash-img">
                                                <span className="dash-icon bg-yellow">
                                                    <img src="/assets/img/icons/dash-icon-01.svg" alt="total orders" />
                                                </span>
                                            </div>
                                            <div className="dash-info">
                                                <div className="dash-order">
                                                    <h6>Total Orders</h6>
                                                    <h5>{totalOrders}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-3">
                                        <div className="dash-widget">
                                            <div className="dash-img">
                                                <span className="dash-icon bg-blue">
                                                    <img src="/assets/img/icons/service-icon.svg" alt="total service" />
                                                </span>
                                            </div>
                                            <div className="dash-info">
                                                <div className="dash-order">
                                                    <h6>Total Service</h6>
                                                    <h5>{totalServices}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-3">
                                        <div className="dash-widget">
                                            <div className="dash-img">
                                                <span className="dash-icon bg-blue">
                                                    <img src="/assets/img/icons/wallet-add.svg" alt="total packages" />
                                                </span>
                                            </div>
                                            <div className="dash-info">
                                                <div className="dash-order">
                                                    <h6>Total Packages</h6>
                                                    <h5>{totalPackages}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-3">
                                        <div className="dash-widget">
                                            <div className="dash-img">
                                                <span className="dash-icon bg-light-danger">
                                                    <img src="/assets/img/icons/myself.svg" alt="total users" style={{ width: "20px" }} />
                                                </span>
                                            </div>
                                            <div className="dash-info">
                                                <div className="dash-order">
                                                    <h6>Total User</h6>
                                                    <h5>{totalUsers}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* <div className="row">
                                <div className="col-lg-8 d-flex flex-column">
                                    <h6 className="user-title">Recent Booking</h6>
                                    <div className="table-responsive recent-booking flex-fill">
                                        <table className="table mb-0">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <h2 className="table-avatar">
                                                            <a href="#" className="avatar avatar-m me-2"><img className="avatar-img rounded" src="/assets/img/services/service-06.jpg" alt="User Image" /></a>
                                                            <a href="#">Computer Repair<span><i className="feather-calendar" /> 22 Sep 2023</span></a>
                                                        </h2>
                                                    </td>
                                                    <td>
                                                        <h2 className="table-avatar table-user">
                                                            <a href="#" className="avatar avatar-m me-2"><img className="avatar-img" src="/assets/img/profiles/avatar-02.jpg" alt="User Image" /></a>
                                                            <a href="#">
                                                                John Smith
                                                                <span><span className="__cf_email__" data-cfemail="177d787f7957726f767a677b723974787a">[email&nbsp;protected]</span></span>
                                                            </a>
                                                        </h2>
                                                    </td>
                                                    <td className="text-end">
                                                        <a href="#" className="btn btn-light-danger">Cancel</a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h2 className="table-avatar">
                                                            <a href="#" className="avatar avatar-m me-2"><img className="avatar-img rounded" src="/assets/img/services/service-04.jpg" alt="User Image" /></a>
                                                            <a href="#">Car Repair Services<span><i className="feather-calendar" /> 20 Sep 2023</span></a>
                                                        </h2>
                                                    </td>
                                                    <td>
                                                        <h2 className="table-avatar table-user">
                                                            <a href="#" className="avatar avatar-m me-2"><img className="avatar-img" src="/assets/img/profiles/avatar-03.jpg" alt="User Image" /></a>
                                                            <a href="#">
                                                                Timothy
                                                                <span><span className="__cf_email__" data-cfemail="24504d494b504c5d64415c45495448410a474b49">[email&nbsp;protected]</span></span>
                                                            </a>
                                                        </h2>
                                                    </td>
                                                    <td className="text-end">
                                                        <a href="#" className="btn btn-light-danger">Cancel</a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h2 className="table-avatar">
                                                            <a href="#" className="avatar avatar-m me-2"><img className="avatar-img rounded" src="/assets/img/services/service-07.jpg" alt="User Image" /></a>
                                                            <a href="#">Interior Designing<span><i className="feather-calendar" /> 19 Sep 2023</span></a>
                                                        </h2>
                                                    </td>
                                                    <td>
                                                        <h2 className="table-avatar table-user">
                                                            <a href="#" className="avatar avatar-m me-2"><img className="avatar-img" src="/assets/img/profiles/avatar-06.jpg" alt="User Image" /></a>
                                                            <a href="#">
                                                                Jordan
                                                                <span><span className="__cf_email__" data-cfemail="7f15100d1b1e113f1a071e120f131a511c1012">[email&nbsp;protected]</span></span>
                                                            </a>
                                                        </h2>
                                                    </td>
                                                    <td className="text-end">
                                                        <a href="#" className="btn btn-light-danger">Cancel</a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h2 className="table-avatar">
                                                            <a href="#" className="avatar avatar-m me-2"><img className="avatar-img rounded" src="/assets/img/services/service-08.jpg" alt="User Image" /></a>
                                                            <a href="#">Steam Car Wash<span><i className="feather-calendar" /> 18 Sep 2023</span></a>
                                                        </h2>
                                                    </td>
                                                    <td>
                                                        <h2 className="table-avatar table-user">
                                                            <a href="#" className="avatar avatar-m me-2"><img className="avatar-img" src="/assets/img/profiles/avatar-09.jpg" alt="User Image" /></a>
                                                            <a href="#">
                                                                Armand
                                                                <span><span className="__cf_email__" data-cfemail="f293809f939c96b2978a939f829e97dc919d9f">[email&nbsp;protected]</span></span>
                                                            </a>
                                                        </h2>
                                                    </td>
                                                    <td className="text-end">
                                                        <a href="#" className="btn btn-light-danger">Cancel</a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h2 className="table-avatar">
                                                            <a href="#" className="avatar avatar-m me-2"><img className="avatar-img rounded" src="/assets/img/services/service-09.jpg" alt="User Image" /></a>
                                                            <a href="#">House Cleaning Services<span><i className="feather-calendar" /> 17 Sep 2023</span></a>
                                                        </h2>
                                                    </td>
                                                    <td>
                                                        <h2 className="table-avatar table-user">
                                                            <a href="#" className="avatar avatar-m me-2"><img className="avatar-img" src="/assets/img/profiles/avatar-10.jpg" alt="User Image" /></a>
                                                            <a href="#">
                                                                Joseph
                                                                <span><span className="__cf_email__" data-cfemail="a2c8cdd1c7d2cae2c7dac3cfd2cec78cc1cdcf">[email&nbsp;protected]</span></span>
                                                            </a>
                                                        </h2>
                                                    </td>
                                                    <td className="text-end">
                                                        <a href="#" className="btn btn-light-danger">Cancel</a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-lg-4 d-flex flex-column">
                                    <h6 className="user-title">Recent Transaction</h6>
                                    <div className="table-responsive transaction-table flex-fill">
                                        <table className="table mb-0">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="table-book d-flex align-items-center">
                                                            <span className="book-img">
                                                                <img src="/assets/img/icons/trans-icon-01.svg" alt="Icon" />
                                                            </span>
                                                            <div>
                                                                <h6>Service Booking</h6>
                                                                <p><i className="feather-calendar" />22 Sep 2023 <span><i className="feather-clock" /> 10:12 AM</span></p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-end">
                                                        <h5 className="trans-amt">$280.00</h5>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="table-book d-flex align-items-center">
                                                            <span className="book-img">
                                                                <img src="/assets/img/icons/trans-icon-02.svg" alt="image" />
                                                            </span>
                                                            <div>
                                                                <h6>Service Refund</h6>
                                                                <p><i className="feather-calendar" />22 Sep 2023 <span><i className="feather-clock" /> 10:12 AM</span></p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-end">
                                                        <h5 className="trans-amt">$100.00</h5>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="table-book d-flex align-items-center">
                                                            <span className="book-img">
                                                                <img src="/assets/img/icons/trans-icon-03.svg" alt="image" />
                                                            </span>
                                                            <div>
                                                                <h6>Wallet Topup</h6>
                                                                <p><i className="feather-calendar" />22 Sep 2023 <span><i className="feather-clock" /> 10:12 AM</span></p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-end">
                                                        <h5 className="trans-amt">$1000.00</h5>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="table-book d-flex align-items-center">
                                                            <span className="book-img">
                                                                <img src="/assets/img/icons/trans-icon-01.svg" alt="Icon" />
                                                            </span>
                                                            <div>
                                                                <h6>Service Booking</h6>
                                                                <p><i className="feather-calendar" />22 Sep 2023 <span><i className="feather-clock" /> 10:12 AM</span></p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-end">
                                                        <h5 className="trans-amt">$280.00</h5>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="table-book d-flex align-items-center">
                                                            <span className="book-img">
                                                                <img src="/assets/img/icons/trans-icon-01.svg" alt="Icon" />
                                                            </span>
                                                            <div>
                                                                <h6>Service Booking</h6>
                                                                <p><i className="feather-calendar" />22 Sep 2023 <span><i className="feather-clock" /> 10:12 AM</span></p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-end">
                                                        <h5 className="trans-amt">$280.00</h5>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div> */}
                            <NormalOrders orders={orders} showOrderDetails={showOrderDetails} TotalOrders={totalOrders} ItemLimit={pageItemLimit} />

                            <CampaignOrders orders={campaignOrders} showOrderDetails={showOrderDetails} TotalOrders={totalCampaignOrders} ItemLimit={pageItemLimit} />
                            {/* {orders.length > 0 && <Orders />}

                            {campaignOrders.length > 0 && <CampaignOrders />} */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="mouse-cursor cursor-outer" style={{ visibility: 'visible' }} />
            <div className="mouse-cursor cursor-inner" style={{ visibility: 'visible' }} />

            <div className="modal fade" id="detailsModal" tabIndex={-1} aria-labelledby="detailsModalLabel" aria-hidden="true" style={{ display: 'none' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header p-0">
                            <h1 className="modal-title fs-5" id="exampleModalLiveLabel">Order Details</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setSelectedOrderDetails([]); setSelectedExtras([]); }} />
                        </div>
                        <div className="modal-body px-0">
                            <p className='mb-0 text-black fw-bold'>Details provided by client for this order</p>
                            <div className='row'>
                                {
                                    selectedOrderDetails.map((o, index) => (
                                        <div className='col-md-6' key={index}>
                                            <div className="form-group mb-1">
                                                <label className="col-form-label pb-0">{o.label} <span className="text-danger">*</span></label>
                                                {/* <input type="text" className="form-control" placeholder="Enter Your Name" /> */}
                                                {o.userinput.split("^").map((v, i) => (
                                                    <p className='text-black ps-2 mb-0' key={i}>{v}</p>
                                                ))}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            {
                                selectedPackageServices.length > 0 && (
                                    <div className='row'>
                                        <div className="col-md-12">
                                            <div className="form-group mb-1">
                                                <label className="col-form-label pb-0 text-black fw-bold">Selected campaign details</label>
                                                <ul className="booking-date">
                                                    {selectedPackageServices.map((pkg, index) => (
                                                        <li key={index} className='d-flex flex-column'>
                                                            {pkg.tier !== "" && <span className='mb-0 align-self-start'>{pkg.tier}</span>}
                                                            <div className='d-flex'>
                                                                <p className='mb-0' style={{ minWidth: "50px" }}>{pkg.quantity}</p>
                                                                <div className='d-flex flex-column align-items-start'>
                                                                    <span className='text-black'>{pkg.service}</span>
                                                                    <span>{pkg.details}</span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                            {
                                selectedExtras.length > 0 && (
                                    <div className='row'>
                                        <div className="col-md-12">
                                            <div className="form-group mb-1">
                                                <label className="col-form-label pb-0 text-black fw-bold">Selected Extra Service in this order</label>
                                                {
                                                    selectedExtras.map((extra, index) => {
                                                        //return <p className="text-black ps-2 mb-0">{extra["feature"]} - {extra["price"]}</p>
                                                        return <span className="d-flex w-100 justify-content-between pe-4" key={index}>
                                                            <p className="text-black ps-2 mb-0">{extra["feature"]}</p> <p className="text-black ps-2 mb-0">Price: ${extra["price"]}</p>
                                                        </span>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className="modal-footer p-0">
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => { setSelectedOrderDetails([]); setSelectedExtras([]); }}>Close</button>
                            {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dashboard