import React, { useEffect, useState } from 'react'
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';

import Header from '../../../components/Header';
import SideBar from '../../../components/SideBar';

import Alert from '../../../components/Alert';

import Cookies from 'universal-cookie';

import { getCookie, eraseCookie, checkRole } from '../../../func';
import AddedPressReleaseServices from '../../../components/AddedPressReleaseServices';

const cookies = new Cookies();

const pageItemLimit = 10;

const AddPressReleaseService = () => {
    const navigate = useNavigate();

    const [services, setServices] = useState([]);
    const [totalServices, setTotalServices] = useState([]);
    const [title, setTitle] = useState('');
    // const [subtitle, setSubTitle] = useState('');
    const [description, setDescription] = useState('');
    const [images, setImages] = useState([]);
    // const [deliveryTime, setDeliveryTime] = useState('');
    // const [deliveryDuration, setDeliveryDuration] = useState('');
    // const [includes, setIncludes] = useState([{ include: "" }]);
    // const [includes, setIncludes] = useState([]);
    const [formjson, setFormJson] = useState([]);

    const [alert, setAlert] = useState({
        type: '',
        message: '',
        show: false
    });

    const [cookieName, setCookieName] = useState('');

    useEffect(() => {
        if (getCookie("token") !== undefined && getCookie("token") !== null) {
            if (checkRole(getCookie("token")) !== "admin")
                navigate(`/dashboard`, { replace: true });
            else {
                fetchServices();
                var temp = "new-service-" + Math.floor(Date.now() / 1000);
                // console.log("temp: ", temp);
                setCookieName(temp);

                //window["bindFormBuilder"](temp, '[{"type":"text","required":true,"label":"Email","className":"form-control","name":"text-1713955913764-0","access":false,"subtype":"text"},{"type":"text","required":true,"label":"Links","className":"form-control","name":"text-1713955924051-0","access":false,"subtype":"text"},{"type":"text","required":true,"label":"Keywords","className":"form-control","name":"text-1713955931395-0","access":false,"subtype":"text"},{"type":"select","required":false,"label":"Select","className":"form-control","name":"select-1713956022737-0","access":false,"multiple":false,"values":[{"label":"Option 1","value":"option-1","selected":true},{"label":"Option 2","value":"option-2","selected":false},{"label":"Option 3","value":"option-3","selected":false}]},{"type":"textarea","required":false,"label":"Text Area","className":"form-control","name":"textarea-1713956025814-0","access":false,"subtype":"textarea"}]');
                window["bindFormBuilder"](temp);

                const cookieChangeListener = (cookie, value) => {
                    if (cookie.name === temp) {
                        // console.log('The cookie ', cookie, ' changed to ', cookie.value);
                        setFormJson((typeof cookie.value !== 'undefined' && cookie.value.length > 0) ? [...cookie.value] : []);
                    }
                }

                cookies.addChangeListener(cookieChangeListener);

                return () => {
                    cookies.removeChangeListener(cookieChangeListener);
                }
            }
        }
        else {
            navigate(`/login`, { replace: true });
        }
    }, [])

    // useEffect(()=>{
    //     console.log("formjson", JSON.stringify(formjson));
    // }, [formjson])

    const handleMessage = (params) => {
        setAlert({ type: params.type, message: params.message, show: true });

        setTimeout(() => {
            setAlert({ type: '', message: '', show: false });
        }, 3000);
    }

    const changeHandler = (e) => {
        let files = e.target.files;
        //console.log("files: ", files);

        var allFiles = [];
        for (var i = 0; i < files.length; i++) {

            let file = files[i];

            let ext = '';
            switch (file.type) {
                case "image/x-png":
                    ext = "png"
                    break;
                case "image/png":
                    ext = "png"
                    break;
                case "image/jpeg":
                    ext = "jpg"
                    break;
                case "image/webp":
                    ext = "webp"
                    break;
                default:
                    break;
            }

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let fileInfo = {
                    name: file.name.replace(`.${ext}`, ''),
                    type: file.type,
                    extention: ext,
                    size: Math.round(file.size / 1000) + ' kB',
                    base64: reader.result,
                    file: file,
                };

                allFiles.push(fileInfo);

                if (allFiles.length === files.length) {
                    // console.log(allFiles);
                    //if (this.props.multiple) this.props.onDone(allFiles);
                    //else this.props.onDone(allFiles[0]);
                    setImages(allFiles);
                }
            }
        }
    }

    const removeImage = (i) => {
        let array = [...images];
        array.splice(i, 1);
        setImages(array);
    }

    // const handleAddFeature = () => {
    //     setIncludes([...includes, { include: "" }]);
    // };

    // const handleFeatureChange = (event, index) => {
    //     let { name, value } = event.target;
    //     let onChangeValue = [...includes];
    //     onChangeValue[index][name] = value;
    //     setIncludes(onChangeValue);
    // };

    // const handleDeleteFeature = (index) => {
    //     const newArray = [...includes];
    //     newArray.splice(index, 1);
    //     setIncludes(newArray);
    // };

    const clear = () => {
        setTitle('');
        // setSubTitle('');
        setDescription('');
        setImages([]);
        // setDeliveryTime('');
        // setDeliveryDuration('');
        // setIncludes([{ include: "" }]);
        // setIncludes([]);
    }

    const fetchServices = () => {
        const json = JSON.stringify({ "token": getCookie("token"), "limit": pageItemLimit, "offset": 0 });
        const url = `${process.env.REACT_APP_BASE_API}/api.php?action=get_pressrelease_services`;

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                setTotalServices(response.data.total_pressrelease_services);
                setServices(response.data.pressrelease_service);
            }
            // else {
            //     handleMessage({ type: 'danger', message: response.data.message, show: true });
            // }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    }

    const addservice = () => {
        var x = window["returnFormJSON"]();
        // console.log("x: ", x);
        setFormJson([...JSON.parse(x)]);

        setTimeout(() => {
            if (title.trim() !== "" && description.trim() !== "" && images.length > 0) {
                const json = JSON.stringify({ "token": getCookie("token"), "title": title, "description": description, "images": images, "formjson": JSON.stringify(formjson) });
                const url = `${process.env.REACT_APP_BASE_API}/api.php?action=add_pressrelease_service`;

                axios.post(url, JSON.stringify({ params: json }), {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).then((response) => {
                    if (response.data.success === "true") {
                        //console.log("service added successfully");
                        clear();
                        eraseCookie(cookieName);
                        handleMessage({ type: 'success', message: response.data.message, show: true });
                    }
                    else {
                        handleMessage({ type: 'danger', message: response.data.message, show: true });
                    }
                }).catch((error) => {
                    console.error(`Error: ${error}`);
                });
            }
            else {
                if (title.trim() === "" && description.trim() === "" && images.length === 0)
                    handleMessage({ type: 'danger', message: "Please fill all the required fields", show: true });
                else if (title.trim() === "")
                    handleMessage({ type: 'danger', message: "Please provide service title", show: true });
                // else if (subtitle.trim() === "")
                //     handleMessage({ type: 'danger', message: "Please provide service subtitle", show: true });
                else if (description.trim() === "")
                    handleMessage({ type: 'danger', message: "Please provide service description", show: true });
                else if (images.length === 0)
                    handleMessage({ type: 'danger', message: "Please provide service image", show: true });
                // else if (deliveryTime.trim() === "")
                //     handleMessage({ type: 'danger', message: "Please provide service delivery time", show: true });
                // else if (deliveryDuration.trim() === "")
                //     handleMessage({ type: 'danger', message: "Please provide service duration", show: true });
            }
        }, 1500)
    }

    return (
        <>
            <Header />

            <div className="content">
                <div className="container">
                    <div className="row">

                        <SideBar />

                        <div className="col-lg-9">
                            <Alert type={alert.type} message={alert.message} show={alert.show} />
                            <div className="widget-title">
                                <h4>Add Press Release Service</h4>
                            </div>
                            <h6 className="user-title">General Information</h6>
                            <div className="general-info mb-4">
                                <div className="row">
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">Title <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" placeholder="Enter press release service title" value={title} onChange={(e) => setTitle(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12">
                                        <div className="form-group">
                                            <label className="col-form-label d-block">Description <span className="brief-bio float-end">Brief description for press release service.</span></label>
                                            <textarea className="form-control" rows={5} placeholder="Add a brief description of press release service....." value={description} onChange={(e) => setDescription(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12">
                                        <h6 className="user-title">Service Image</h6>
                                        <div className="service-image-box mb-4">
                                            {images.length > 0 && <div className="service-image mb-2">
                                                <img src={images[0]["base64"]} alt="user" style={{ width: "300px", height: "250px", borderRadius: "8px" }} />
                                            </div>}
                                            <div className="pro-info">
                                                <div className="d-flex">
                                                    <div className="img-upload">
                                                        <i className="feather-upload-cloud me-1" />Upload
                                                        <input type="file" onChange={changeHandler} accept="image/x-png,image/gif,image/jpeg" />
                                                    </div>
                                                    <button className="btn btn-remove" onClick={() => removeImage(0)}>Remove</button>
                                                </div>
                                                <p>*image size should be at least 640px in width and 260px in height,and less then 500kb. Allowed files .png and .jpg.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="row">
                                    <div className="col-12 d-flex align-items-center mb-3">
                                        <button className='btnaddfeature me-2' onClick={() => handleAddFeature()}>+</button>
                                        <h6 className="mb-0">Add including features</h6>
                                    </div>
                                    {includes.length > 0 && includes.map((f, index) => (
                                        <div className="row col-12" key={index}>
                                            <div className="col-12 col-sm-9">
                                                <div className="form-group">
                                                    <input type="text" name="include" className="form-control" placeholder="Enter feature heading" value={f.include} onChange={(event) => handleFeatureChange(event, index)} />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-3 d-flex align-items-center justify-content-end mb-3">
                                                {includes.length >= 1 && (
                                                    <button className="btnremovefeature me-2" onClick={() => handleDeleteFeature(index)}>Delete</button>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div> */}

                                <div className="row">
                                    <div className="my-4">
                                        <h6 className="user-title m-0">Create form to accept required data for this service.</h6>
                                    </div>
                                    <div id="fb-editor" className="mb-4" />
                                </div>

                            </div>
                            <div className='row'>
                                <div className="acc-submit mb-5">
                                    <button className="btn btn-secondary" onClick={() => clear()}>Cancel</button>
                                    <button className="btn btn-primary" onClick={() => addservice()}>Save Press Release Service</button>
                                </div>

                                {services.length > 0 && <AddedPressReleaseServices services={services} TotalServices={totalServices} ItemLimit={pageItemLimit}/>}
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="mouse-cursor cursor-outer" style={{ visibility: 'visible' }} />
            <div className="mouse-cursor cursor-inner" style={{ visibility: 'visible' }} />
        </>
    )
}

export default AddPressReleaseService