import React, { useEffect, useState } from 'react'
import axios from 'axios';
import './style.css';
import { BrowserProvider, formatEther, parseEther, toUtf8Bytes } from "ethers";
import { Link } from 'react-router-dom';

import { returnTextToCipher } from "../../func";

import Alert from '../../components/Alert';

//Binance mainnet for production
const binanceNetwork = {
    chainId: '0x38',//hexadecimal of 56
    chainName: 'Binance Smart Chain Mainnet',
    rpcUrls: ['https://bsc-dataseed1.ninicoin.io'],
    nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18,
    },
    blockExplorerUrls: ['https://bscscan.com/'],
};
//binance mainnet for production

//Binance testnet for testing
const binanceTestNetwork = {
    chainId: '0x61',//hexadecimal of 97
    chainName: 'Smart Chain - Testnet',
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
    nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18,
    },
    blockExplorerUrls: ['https://testnet.bscscan.com'],
};
//Binance testnet for testing

//Ethereum mainnet for production
const ethereumNetwork = {
    chainId: '0x1',//hexadecimal of 1
    chainName: 'Binance Smart Chain Mainnet',
    rpcUrls: ['https://bsc-dataseed1.ninicoin.io'],
    nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
    },
    blockExplorerUrls: ['https://bscscan.com/'],
};
//Ethereum mainnet for production

//Sepolia testnet for testing
const ethereumTestNetwork = {
    chainId: '0xaa36a7',//hexadecimal of 11155111
    chainName: 'Sepolia',
    rpcUrls: ['https://eth-sepolia.g.alchemy.com/v2/demo'],
    nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
    },
    blockExplorerUrls: ['https://sepolia.etherscan.io'],
};
//Sepolia testnet for testing

var intervalID = 0;

const CryptoPaymentsForm = ({ totalPrice, setCryptoOrderResponse, cryptoRecieverAddress, orderDetailsJSON }) => {
    const [isMetaMaskInstalled, setInMetamaskInstalled] = useState(false);
    const [isBNBWalletInstalled, setBNBWalletInstalled] = useState(false);

    const [selectedWallet, setSelectedWallet] = useState(null);
    const [account, setAccount] = useState(null);
    const [accountBalance, setAccountBalance] = useState(null);
    const [network, setNetwork] = useState(null);
    const [error, setError] = useState(null);

    const [bnbPrice, setBNBPrice] = useState(0.0);
    const [ethPrice, setETHPrice] = useState(0.0);

    const [orderPriceInBNB, setOrderPriceInBNB] = useState(0.0);
    const [orderPriceInETH, setOrderPriceInETH] = useState(0.0);

    let recieverAddress = `${process.env.REACT_APP_Reciever_CRYPTO_Address}`;//"0xe976bAB458187b97Ae75338E85BC13CFe10F4bFf";

    const [recieverETHAddress, setRecieverETHAddress] = useState("");
    const [recieverBNBAddress, setRecieverBNBAddress] = useState("");

    const [alert, setAlert] = useState({
        type: '',
        message: '',
        show: false
    });

    useEffect(() => {
        initialize();
        console.log("Mode: ", process.env.REACT_APP_TEST_PAYMENT)

        return () => {
            clearInterval(intervalID);
        }
    }, []);

    useEffect(() => {
        // console.log(`bnbPrice: ${bnbPrice}, ethPrice: ${ethPrice}`)
        if (bnbPrice > 0) {
            var tempBNB = convertToBNB(totalPrice);
            setOrderPriceInBNB(tempBNB);
        }

        if (ethPrice > 0) {
            var tempETH = convertToETH(totalPrice);
            setOrderPriceInETH(tempETH);
        }
    }, [bnbPrice, ethPrice])

    useEffect(() => {
        setRecieverETHAddress(cryptoRecieverAddress["ethereum"]);
        setRecieverBNBAddress(cryptoRecieverAddress["binance"]);
    }, [cryptoRecieverAddress])

    const handleMessage = (params) => {
        setAlert({ type: params.type, message: params.message, show: true });

        setTimeout(() => {
            setAlert({ type: '', message: '', show: false });
        }, 3000);
    }

    // useEffect(() => {
    //     console.log("network: ", typeof network);
    //     console.log("network: ", network);
    // }, [network]);

    const initialize = () => {
        // console.log("isMetaMaskInstalled: ", checkIsMetaMaskInstalled());
        setInMetamaskInstalled(checkIsMetaMaskInstalled());
        // console.log("isBNBWalletInstalled: ", checkIsBNBWalletInstalled());
        setBNBWalletInstalled(checkIsBNBWalletInstalled());
    }

    const convertToBNB = (amountInUSD) => {
        if (bnbPrice === null) {
            throw new Error('Failed to fetch BNB price');
        }

        const amountInBNB = parseFloat((amountInUSD / bnbPrice).toFixed(5));
        //console.log("amountInBNB: ", amountInBNB);
        return amountInBNB;
    }

    const convertToETH = (amountInUSD) => {
        if (ethPrice === null) {
            throw new Error('Failed to fetch ETH price');
        }

        const amountInETH = parseFloat((amountInUSD / ethPrice).toFixed(5));
        //console.log("amountInETH: ", amountInETH)
        return amountInETH;
    }

    const fetchCurrentBNBPrice = async () => {
        // const url = 'https://api.bscscan.com/api?module=stats&action=bnbprice&apikey=4BZ3KXJQQIER8NYE6N4MKYR97THAZVEIM6';
        const url = 'https://api.bscscan.com/api?module=stats&action=bnbprice';

        await axios.post(url).then((response) => {
            // console.log("response: ", response.data);
            if (response.data.status === "1") {
                // console.log("BNB price in USD: $", response.data.result["ethusd"]);
                setBNBPrice(response.data.result["ethusd"]);
                // setOrderPriceInBNB(convertToBNB(totalPrice));
            }
        }).catch((error) => {
            setTimeout(() => {
                fetchCurrentBNBPrice();
            }, 900);
            console.error(`Error: ${error}`);
        });
    }

    const fetchCurrentETHPrice = async () => {
        // const url = 'https://api.etherscan.io/api?module=stats&action=ethprice&apikey=7EJG35SVC94CSK84Z8DYXWRKJHNKYEZW6U';
        const url = 'https://api.etherscan.io/api?module=stats&action=ethprice';

        await axios.post(url).then((response) => {
            // console.log("response: ", response.data);
            if (response.data.status === "1") {
                // console.log("ETH price in USD: $", response.data.result["ethusd"]);
                setETHPrice(response.data.result["ethusd"]);
                // setOrderPriceInETH(convertToETH(totalPrice));
            }
        }).catch((error) => {
            setTimeout(() => {
                fetchCurrentETHPrice();
            }, 900);
            console.error(`Error: ${error}`);
        });
    }

    const checkIsMetaMaskInstalled = () => {
        if (typeof window.ethereum !== 'undefined')
            return Boolean(window.ethereum && window.ethereum.isMetaMask);
        else
            return false;
    }

    const checkIsBNBWalletInstalled = () => {
        if (typeof window.BinanceChain !== 'undefined')
            return Boolean(window.BinanceChain);
        else
            return false;
    }

    const handleConnectToMetamask = async () => {
        // console.log("window.ethereum: ", window.ethereum);
        if (typeof window.ethereum !== 'undefined' && typeof window.ethereum.isTrustWallet === 'undefined') {
            try {
                const provider = new BrowserProvider(window.ethereum);
                //const provider = new ethers.providers.Web3Provider(window.ethereum);
                // await provider.send("eth_requestAccounts", []);
                // const signer = provider.getSigner();
                const address = await provider.send("eth_requestAccounts", []);
                if (address.length > 0) {
                    // const address = await signer.getAddress();
                    const ethbalance = await provider.getBalance(address[0]);
                    const { chainId } = await provider.getNetwork();
                    // console.log("balance:", formatEther(ethbalance));
                    setAccountBalance(formatEther(ethbalance))
                    // console.log('Metamask Wallet connected:', address[0]);
                    // console.log("Connected to MetaMask:", address[0]);
                    setAccount(address[0]);
                    setSelectedWallet("metamask");
                    setNetwork(chainId);

                    // await fetchCurrentBNBPrice();
                    // await fetchCurrentETHPrice();
                    getUpdatedRatesAfter10seconds();
                }

                // const accounts = await provider.listAccounts();
                // console.log('Initial accounts:', accounts);

                // Listen for the accountsChanged event
                window.ethereum.on('accountsChanged', async (accounts) => {
                    // console.log("accountsChanged called");
                    const provider = new BrowserProvider(window.ethereum);
                    const ethbalance = await provider.getBalance(address[0]);
                    const { chainId } = await provider.getNetwork();

                    // console.log('Accounts changed:', accounts[0]);
                    setAccountBalance(formatEther(ethbalance));
                    setAccount(accounts[0]);
                    setSelectedWallet("metamask");
                    setNetwork(chainId);
                });
                window.ethereum.on('chainChanged', async (chainId) => {
                    const provider = new BrowserProvider(window.ethereum);
                    const address = await provider.send("eth_requestAccounts", []);
                    if (address.length > 0) {
                        const ethbalance = await provider.getBalance(address[0]);

                        // console.log("chainChanged => ethbalance: ", ethbalance);

                        setAccountBalance(formatEther(ethbalance));
                        setNetwork(chainId);
                    }
                });
                window.ethereum.on('disconnect', (accounts) => {
                    // console.log('Metamask Wallet Accounts disconnect:', accounts);
                    disconnect();
                });
            } catch (error) {
                // console.log(error);
                if (error.code === 4001) {
                    console.log('User rejected the connection request.');
                } else {
                    console.log('Error connecting to MetaMask.');
                }
                // console.error('Error connecting to MetaMask:', error);
                // alert('Error connecting to MetaMask');
            }
        }
        else if (typeof window.ethereum !== 'undefined' && typeof window.ethereum.isTrustWallet !== 'undefined') {
            handleMessage({ type: 'info', message: "Please disable TrustWallet and refresh to pay with metamask", show: true });
        }
        else {
            alert('MetaMask is not installed');
        }
    }

    const handleConnectToBNBWallet = async () => {
        // console.log("window.BinanceChain: ", window.BinanceChain)
        if (typeof window.BinanceChain !== 'undefined') {
            try {
                const provider = new BrowserProvider(window.BinanceChain);
                //const provider = new ethers.providers.Web3Provider(window.ethereum);
                // await provider.send("eth_requestAccounts", []);
                // const signer = provider.getSigner();
                const address = await provider.send("eth_requestAccounts", []);
                if (address.length > 0) {
                    // const address = await signer.getAddress();
                    const ethbalance = await provider.getBalance(address[0]);
                    const { chainId } = await provider.getNetwork();
                    // console.log("balance:", formatEther(ethbalance));
                    setAccountBalance(formatEther(ethbalance));
                    setAccount(address[0]);
                    setSelectedWallet("bnbchainwallet");
                    setNetwork(chainId);

                    // await fetchCurrentBNBPrice();
                    // await fetchCurrentETHPrice();
                    getUpdatedRatesAfter10seconds();
                }

                // await window.BinanceChain.request({ method: 'eth_requestAccounts' });
                // const accounts = await window.BinanceChain.request({ method: 'eth_accounts' });
                // // console.log('Binance Chain Wallet connected:', accounts[0]);
                // // alert(`Binance Chain Wallet connected: ${accounts[0]}`);
                // setAccount(accounts[0]);
                // setSelectedWallet("bnbchainwallet")

                // Listen for the accountsChanged event
                window.BinanceChain.on('accountsChanged', async (accounts) => {
                    const provider = new BrowserProvider(window.BinanceChain);
                    const ethbalance = await provider.getBalance(address[0]);
                    const { chainId } = await provider.getNetwork();

                    // console.log('Accounts changed:', accounts[0]);
                    setAccountBalance(formatEther(ethbalance));
                    setAccount(accounts[0]);
                    setSelectedWallet("bnbchainwallet");
                    setNetwork(chainId);
                });
                window.BinanceChain.on('chainChanged', async (chainId) => {
                    const provider = new BrowserProvider(window.BinanceChain);
                    // const address = await provider.send("eth_requestAccounts", []);
                    if (address.length > 0) {
                        const ethbalance = await provider.getBalance(address[0]);

                        // console.log("chainChanged => ethbalance: ", ethbalance);

                        setAccountBalance(formatEther(ethbalance));
                        setNetwork(chainId);
                    }
                });
                window.BinanceChain.on('disconnect', (accounts) => {
                    // console.log('BNB Chain Wallet Accounts disconnect:', accounts);
                    disconnect();
                });
            } catch (error) {
                console.error('Error connecting to Binance Chain Wallet:', error);
                alert('Error connecting to Binance Chain Wallet');
            }
        } else {
            alert('Binance Chain Wallet is not installed');
        }
    }

    const ConnectWalletLayout = () => {
        return (
            <div className="d-flex flex-column">
                {
                    isMetaMaskInstalled ?
                        (
                            <button className='mb-2 p-2 btn-metamask' onClick={() => handleConnectToMetamask()}>
                                <img src="/assets/img/metamask.png" style={{ width: "30px" }} alt='metamask wallet logo' /> <span className='ms-2'>Connect to Metamask</span>
                            </button>
                        )
                        :
                        (
                            <Link to={'https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn'} className='mb-2 p-2 btn-metamask' target='_blank'>
                                <img src="/assets/img/metamask.png" style={{ width: "30px" }} alt='metamask wallet logo' /> <span className='ms-2'>Install Metamask</span>
                            </Link>
                        )
                }

                {
                    isBNBWalletInstalled ?
                        (
                            <button className='mb-2 p-2 btn-bnbwallet' onClick={() => handleConnectToBNBWallet()}>
                                <img src="/assets/img/bnbchainwallet.png" style={{ width: "30px" }} alt='bnb chain wallet logo' /> <span className='ms-2'>Connect to BNB Wallet</span>
                            </button>
                        )
                        :
                        (
                            <Link to={'https://chromewebstore.google.com/detail/bnb-chain-wallet/fhbohimaelbohpjbbldcngcnapndodjp'} className='mb-2 p-2 btn-bnbwallet'>
                                <img src="/assets/img/bnbchainwallet.png" style={{ width: "30px" }} alt='bnb chain wallet logo' /> <span className='ms-2'>Install BNB Chain Wallet</span>
                            </Link>
                        )
                }

            </div>
        )
    }

    const ConnectedWalletLayout = () => {
        return (
            <div className="d-flex flex-column">
                <div className='d-flex flex-column align-items-center'>
                    <span className='ms-2 fw-bold fs-6'>Connected to {selectedWallet === "bnbchainwallet" ? "BNB Chain Wallet" : "Metamask"}</span>
                    <img src={`/assets/img/${selectedWallet === "bnbchainwallet" ? 'bnbchainwallet.png' : 'metamask.png'}`} style={{ width: "45px" }} alt=' wallet logo' />
                    <span className='mt-2 fw-bold'>{getFormatedBalance()}</span>
                    <span className='mb-2'>{account}</span>
                </div>

                <button className='mb-2 p-2 btn-eth' onClick={() => orderPriceInETH > 0 ? handlePayInEth() : null}>
                    <img src="/assets/img/eth.svg" alt="eth logo" style={{ width: "15px" }} /> <span className="ms-2">Pay in ETH {`${orderPriceInETH > 0 ? `(${orderPriceInETH})` : ``}`}</span>
                </button>
                <button className='mb-2 p-2 btn-bnb' onClick={() => orderPriceInBNB > 0 ? handlePayInBNB() : null}>
                    <img src="/assets/img/bnb.svg" alt="bnb logo" style={{ width: "20px" }} /> <span className="ms-2">Pay in BNB {`${orderPriceInBNB > 0 ? `(${orderPriceInBNB})` : ``}`}</span>
                </button>

                <button className='mb-2 p-2 btn btn-danger' onClick={() => disconnect()}><i className="feather-log-out me-2"></i> Disconnect</button>
            </div>
        )
    }

    const getFormatedBalance = () => {
        if (selectedWallet.toString() !== "" && (selectedWallet === "metamask" || selectedWallet === "bnbchainwallet")) {
            //for only testing not for production
            if (parseInt(network) === 11155111) //Sepolia testnet
            {
                return `${accountBalance} ETH`;
            }
            else if (parseInt(network) === 97) //Binance Smart Chain testnet
            {
                return `${accountBalance} BNB`;
            }
            //for only testing not for production

            //for production
            if (parseInt(network) === 1) //Ethereum Mainnet
            {
                return `${accountBalance} ETH`;
            }
            else if (parseInt(network) === 56) //BNB Smart Chain Mainnet
            {
                return `${accountBalance} BNB`;
            }
            //for production
        }
    }

    const disconnect = async () => {
        if (selectedWallet === "metamask" && window.ethereum.isMetaMask) {
            try {
                const provider = new BrowserProvider(window.ethereum);
                // Revoke permissions
                const permissions = await provider.send('wallet_revokePermissions', [{
                    eth_accounts: {}
                }]);

                // console.log('Permissions revoked:', permissions);

                // // List accounts to see the current state after revoking permissions
                // const accounts = await provider.listAccounts();
                // console.log('Accounts after revoking permissions:', accounts);

            } catch (error) {
                console.error('Error revoking permissions:', error);
            }
        }

        clearInterval(intervalID);
        setSelectedWallet(null);
        setAccount(null);
        setAccountBalance(null);
        setNetwork(null);
        setError(null);
        setBNBPrice(0.0);
        setETHPrice(0.0);
        setOrderPriceInBNB(0.0);
        setOrderPriceInETH(0.0);
        // console.log("Disconnected from wallet");
    };

    const switchOrAddNetwork = async (provider, networkDetails) => {
        try {
            await provider.send('wallet_switchEthereumChain', [{ chainId: networkDetails.chainId }]);
            // console.log('Network switched successfully');
        } catch (switchError) {
            if (switchError.code === 4902) {
                console.log('Network not found. Adding network...');
                try {
                    await provider.send('wallet_addEthereumChain', [networkDetails]);
                    // console.log('Network added successfully');
                } catch (addError) {
                    console.error('Failed to add network:', addError.message);
                }
            } else {
                console.error('Failed to switch network:', switchError.message);
            }
        }
    }

    // const handlePayInEth = async () => {
    //     const ethereumChainID = 11155111; //sepolia chain id for testing 
    //     // const ethereumChainID = 1; //Ethereum chain id for production 

    //     var provider = null;
    //     var transactionResponse = {};
    //     if (parseInt(network) !== ethereumChainID) {
    //         if (selectedWallet === "metamask") {
    //             provider = new BrowserProvider(window.ethereum);
    //             switchOrAddNetwork(provider, ethereumTestNetwork);
    //         }
    //         else if (selectedWallet === "bnbchainwallet") {
    //             handleMessage({ type: 'info', message: "Manually Switch your network in 'BNB Chain Wallet' to 'Ethereum Network'", show: true });
    //         }
    //     }
    //     else {
    //         if (selectedWallet === "metamask") {
    //             provider = new BrowserProvider(window.ethereum);
    //             const signer = await provider.getSigner();
    //             transactionResponse = await sendTransaction(signer, recieverETHAddress !== "" ? recieverETHAddress : recieverAddress, orderPriceInETH);
    //             console.log("transactionResponse: ", transactionResponse);
    //             getExplorerUrl(transactionResponse);
    //         }
    //         else if (selectedWallet === "bnbchainwallet") {
    //             provider = new BrowserProvider(window.BinanceChain);
    //             const signer = await provider.getSigner();
    //             transactionResponse = await sendTransaction(signer, recieverETHAddress !== "" ? recieverETHAddress : recieverAddress, orderPriceInETH);
    //             console.log("transactionResponse: ", transactionResponse);
    //             getExplorerUrl(transactionResponse);
    //         }
    //         // console.log("handlePayInEth clicked in else");
    //     }
    // }

    // const handlePayInBNB = async () => {
    //     const binanceChainID = 97; //binance smart chain id for testing 
    //     // const binanceChainID = 56; //Binance smart chain id for production 

    //     var provider = null;
    //     var transactionResponse = {};
    //     if (parseInt(network) !== binanceChainID) {
    //         if (selectedWallet === "metamask") {
    //             provider = new BrowserProvider(window.ethereum);
    //             switchOrAddNetwork(provider, binanceTestNetwork);
    //         }
    //         else if (selectedWallet === "bnbchainwallet") {
    //             handleMessage({ type: 'success', message: "Manually Switch your network in 'BNB Chain Wallet' to 'BNB Smart Chain Network'", show: true });
    //         }
    //     }
    //     else {
    //         if (selectedWallet === "metamask") {
    //             provider = new BrowserProvider(window.ethereum);
    //             const signer = await provider.getSigner();
    //             transactionResponse = await sendTransaction(signer, recieverBNBAddress !== "" ? recieverBNBAddress : recieverAddress, orderPriceInBNB);
    //             console.log("transactionResponse: ", transactionResponse);
    //             getExplorerUrl(transactionResponse);
    //         }
    //         else if (selectedWallet === "bnbchainwallet") {
    //             provider = new BrowserProvider(window.BinanceChain);
    //             const signer = await provider.getSigner();
    //             transactionResponse = await sendTransaction(signer, recieverBNBAddress !== "" ? recieverBNBAddress : recieverAddress, orderPriceInBNB);
    //             console.log("transactionResponse: ", transactionResponse);
    //             getExplorerUrl(transactionResponse);
    //         }
    //         // console.log("handlePayInBNB clicked in else");
    //     }
    // }

    const handlePayInEth = async () => {
        // const ethereumChainID = 11155111; //sepolia chain id for testing 
        // const ethereumChainID = 1; //Ethereum chain id for production 

        var provider = null;
        var transactionResponse = {};
        if (parseInt(network) !== (process.env.REACT_APP_TEST_PAYMENT === "ON" ? 11155111 : 1)) {
            if (selectedWallet === "metamask") {
                provider = new BrowserProvider(window.ethereum);
                switchOrAddNetwork(provider, process.env.REACT_APP_TEST_PAYMENT === "ON" ? ethereumTestNetwork : ethereumNetwork);
            }
            else if (selectedWallet === "bnbchainwallet") {
                handleMessage({ type: 'info', message: "Manually Switch your network in 'BNB Chain Wallet' to 'Ethereum Network'", show: true });
            }
        }
        else {
            if (selectedWallet === "metamask") {
                provider = new BrowserProvider(window.ethereum);
                const signer = await provider.getSigner();
                transactionResponse = await sendTransaction(signer, recieverETHAddress !== "" ? recieverETHAddress : recieverAddress, orderPriceInETH);
                console.log("transactionResponse: ", transactionResponse);
                getExplorerUrl(transactionResponse);
            }
            else if (selectedWallet === "bnbchainwallet") {
                provider = new BrowserProvider(window.BinanceChain);
                const signer = await provider.getSigner();
                transactionResponse = await sendTransaction(signer, recieverETHAddress !== "" ? recieverETHAddress : recieverAddress, orderPriceInETH);
                console.log("transactionResponse: ", transactionResponse);
                getExplorerUrl(transactionResponse);
            }
            // console.log("handlePayInEth clicked in else");
        }
    }

    const handlePayInBNB = async () => {
        // const binanceChainID = 97; //binance smart chain id for testing 
        // const binanceChainID = 56; //Binance smart chain id for production 

        var provider = null;
        var transactionResponse = {};
        if (parseInt(network) !== (process.env.REACT_APP_TEST_PAYMENT === "ON" ? 97 : 56)) {
            if (selectedWallet === "metamask") {
                provider = new BrowserProvider(window.ethereum);
                switchOrAddNetwork(provider, process.env.REACT_APP_TEST_PAYMENT === "ON" ? binanceTestNetwork : binanceNetwork);
            }
            else if (selectedWallet === "bnbchainwallet") {
                handleMessage({ type: 'success', message: "Manually Switch your network in 'BNB Chain Wallet' to 'BNB Smart Chain Network'", show: true });
            }
        }
        else {
            if (selectedWallet === "metamask") {
                provider = new BrowserProvider(window.ethereum);
                const signer = await provider.getSigner();
                transactionResponse = await sendTransaction(signer, recieverBNBAddress !== "" ? recieverBNBAddress : recieverAddress, orderPriceInBNB);
                console.log("transactionResponse: ", transactionResponse);
                getExplorerUrl(transactionResponse);
            }
            else if (selectedWallet === "bnbchainwallet") {
                provider = new BrowserProvider(window.BinanceChain);
                const signer = await provider.getSigner();
                transactionResponse = await sendTransaction(signer, recieverBNBAddress !== "" ? recieverBNBAddress : recieverAddress, orderPriceInBNB);
                console.log("transactionResponse: ", transactionResponse);
                getExplorerUrl(transactionResponse);
            }
            // console.log("handlePayInBNB clicked in else");
        }
    }

    const sendTransaction = async (signer, to, amount) => {
        // console.log("amount:", amount);
        // console.log("parseEther amount:", parseEther(amount.toString()));
        // console.log("orderDetailsJSON", orderDetailsJSON());
        if (accountBalance > amount) {
            if (signer) {
                const tx = {
                    to: to, // Replace with the recipient's address
                    value: parseEther(amount.toString()), // Amount to send in BNB (e.g., 0.1 BNB)
                    data: toUtf8Bytes(returnTextToCipher(orderDetailsJSON()))
                };

                try {
                    const txResponse = await signer.sendTransaction(tx);
                    // console.log("Transaction sent:", JSON.stringify(txResponse));
                    await txResponse.wait();
                    // console.log("Transaction mined:", JSON.stringify(txResponse));
                    return txResponse;
                } catch (error) {
                    console.error("Transaction failed:", error);
                }
            } else {
                console.error("Signer not initialized.");
            }
            return { "success": false }
        }
        else {
            clearInterval(intervalID);
            handleMessage({ type: 'info', message: "You dont have enough funds to pay for this transaction'", show: true });
            return { "success": false }
        }
    };

    const getExplorerUrl = (transactionResponse) => {
        // console.log(`transactionResponse.hasOwnProperty("success"): ${transactionResponse.hasOwnProperty("success")}`);
        // console.log(`chainID: ${parseInt(transactionResponse["chainId"])}`);
        // console.log(`Sent ${formatEther(transactionResponse["value"])} eth from: ${transactionResponse["from"]} to: ${transactionResponse["to"]}`);
        // console.log(`https://sepolia.etherscan.io/tx/${transactionResponse["hash"]}`);
        if (!transactionResponse.hasOwnProperty("success")) {
            if (parseInt(transactionResponse["chainId"]) === 97) {  //binance smart chain id for testing 
                console.log(`Sent ${formatEther(transactionResponse["value"])} bnb from: ${transactionResponse["from"]} to: ${transactionResponse["to"]}`);
                console.log(`https://testnet.bscscan.com/tx/${transactionResponse["hash"]}`);
                transactionResponse.explorerUrl = `https://testnet.bscscan.com/tx/${transactionResponse["hash"]}`;
                transactionResponse.priceInCrypto = `${formatEther(transactionResponse["value"])} TBNB`;
                // setCryptoOrderResponse({ status: "COMPLETED", priceInCrypto: formatEther(transactionResponse["value"]), payment_response: transactionResponse, chain: "Binance Smart Chain Testnet", wallet: selectedWallet })
                setCryptoOrderResponse({ status: "COMPLETED", payment_response: transactionResponse, chain: "Binance Smart Chain Testnet", wallet: selectedWallet })
            }
            else if (parseInt(transactionResponse["chainId"]) === 56) { //Binance smart chain id for production 
                console.log(`Sent ${formatEther(transactionResponse["value"])} bnb from: ${transactionResponse["from"]} to: ${transactionResponse["to"]}`);
                console.log(`https://bscscan.com//tx/${transactionResponse["hash"]}`);
                transactionResponse.explorerUrl = `https://bscscan.com/tx/${transactionResponse["hash"]}`;
                transactionResponse.priceInCrypto = `${formatEther(transactionResponse["value"])} BNB`;
                // setCryptoOrderResponse({ status: "COMPLETED", priceInCrypto: formatEther(transactionResponse["value"]), payment_response: transactionResponse, chain: "Binance Smart Chain Mainnet", wallet: selectedWallet })
                setCryptoOrderResponse({ status: "COMPLETED", payment_response: transactionResponse, chain: "Binance Smart Chain Mainnet", wallet: selectedWallet })
            }
            else if (parseInt(transactionResponse["chainId"]) === 11155111) { //sepolia chain id for testing 
                console.log(`Sent ${formatEther(transactionResponse["value"])} eth from: ${transactionResponse["from"]} to: ${transactionResponse["to"]}`);
                console.log(`https://sepolia.etherscan.io/tx/${transactionResponse["hash"]}`);
                transactionResponse.explorerUrl = `https://sepolia.etherscan.io/tx/${transactionResponse["hash"]}`;
                transactionResponse.priceInCrypto = `${formatEther(transactionResponse["value"])} SepoliaETH`;
                // setCryptoOrderResponse({ status: "COMPLETED", priceInCrypto: formatEther(transactionResponse["value"]), payment_response: transactionResponse, chain: "Seploia Ethereum Testnet", wallet: selectedWallet })
                setCryptoOrderResponse({ status: "COMPLETED", payment_response: transactionResponse, chain: "Seploia Ethereum Testnet", wallet: selectedWallet })
            }
            else if (parseInt(transactionResponse["chainId"]) === 1) { //Ethereum chain id for production 
                console.log(`Sent ${formatEther(transactionResponse["value"])} eth from: ${transactionResponse["from"]} to: ${transactionResponse["to"]}`);
                console.log(`https://etherscan.io/tx/${transactionResponse["hash"]}`);
                transactionResponse.explorerUrl = `https://etherscan.io/tx/${transactionResponse["hash"]}`;
                transactionResponse.priceInCrypto = `${formatEther(transactionResponse["value"])} ETH`;
                // setCryptoOrderResponse({ status: "COMPLETED", priceInCrypto: formatEther(transactionResponse["value"]), payment_response: transactionResponse, chain: "Ethereum Mainnet", wallet: selectedWallet })
                setCryptoOrderResponse({ status: "COMPLETED", payment_response: transactionResponse, chain: "Ethereum Mainnet", wallet: selectedWallet })
            }
        }
    }

    const getUpdatedRatesAfter10seconds = () => {
        intervalID = setInterval(async () => {
            await fetchCurrentBNBPrice();
            await fetchCurrentETHPrice();
        }, 10000);
    }

    return (
        <>
            <Alert type={alert.type} message={alert.message} show={alert.show} />
            {account ? <ConnectedWalletLayout /> : <ConnectWalletLayout />}
        </>
    )
}

export default CryptoPaymentsForm