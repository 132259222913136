import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { getCookie, base64_decode } from '../../func';

import Header from '../../components/Header';
import ServiceForm from '../../components/ServiceForm';
import CampaignSelector from '../../components/CampaignSelector';
import CryptoPaymentsForm from '../../components/CryptoPaymentsForm';
import ReviewsSection from '../../components/ReviewsSection';
import Footer from '../../components/Footer';

var selectedPaymentMethod = '';

const Campaign = () => {
    let location = useLocation();
    const navigate = useNavigate();
    let params = useParams();

    const [campaignDeails, setCampaignDetails] = useState({});
    const [activeTab, setActiveTab] = useState('details');

    const [totalPrice, setTotalPrice] = useState(0.0);
    // const [selectedExtras, setSelectedExtras] = useState([]);

    const [paymentMethod, setPaymentMethod] = useState('');

    const [serviceFormData, setServiceFormData] = useState([]);
    const [enableProceedButton, setEnableProceedButton] = useState(false);

    const [selectedPackage, setSelectedPackage] = useState(0);
    const [selectedPackageServices, setSelectedPackageServices] = useState([]);
    //const [cryptoOrderResponse, setCryptoOrderResponse] = useState({ status: null, priceInCrypto: null, payment_response: null, chain: null, wallet: null });
    const [cryptoOrderResponse, setCryptoOrderResponse] = useState({ status: null, payment_response: null, chain: null, wallet: null });

    const [paypalClientID, setPaypalClientID] = useState("");
    const [cryptoRecieverAddress, setCryptoReciverAddress] = useState({ "ethereum": "", "binance": "" });

    useEffect(() => {
        if (params.slug !== undefined && params.slug !== null && params.slug.toString().trim() !== "") {
            if (getCookie("token") === undefined || getCookie("token") === null) {
                navigate(`/login?url=${location.pathname}`, { replace: true });
            }
            else {
                fetchCampaignDetails(params.slug);
            }
        }
        else
            navigate(`/`, { replace: true });
    }, []);

    useEffect(() => {
        // console.log("paymentMethod:", paymentMethod);
        selectedPaymentMethod = paymentMethod;
    }, [paymentMethod])

    useEffect(() => {
        if (cryptoOrderResponse.status !== null) {
            debugger;
            saveOrder(cryptoOrderResponse.status, cryptoOrderResponse.payment_response)
        }
    }, [cryptoOrderResponse])

    useEffect(() => {
        if (selectedPackage > 0) {
            setTotalPrice(selectedPackage);

            var tempCampaignPackages = [...JSON.parse(campaignDeails["campaign_packages"])];
            // console.log("tempCampaignPackages: ", tempCampaignPackages.find(c => parseInt(c.price) === parseInt(selectedPackage))["services"]);
            setSelectedPackageServices(tempCampaignPackages.find(c => parseInt(c.price) === parseInt(selectedPackage))["services"])
        }
    }, [selectedPackage]);

    useEffect(() => {
        if (activeTab === "payment") {
            fetchPaymentGateways();
        }
    }, [activeTab])

    const fetchCampaignDetails = (slug) => {
        const json = JSON.stringify({ "slug": slug, "showform": true });
        const url = `${process.env.REACT_APP_BASE_API}/api.php?action=get_campaign_details`;

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                setCampaignDetails(response.data.campaign_details[0]);
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    }

    const fetchPaymentGateways = () => {
        const json = JSON.stringify({ "token": getCookie("token") });
        const url = `${process.env.REACT_APP_BASE_API}/api.php?action=get_all_gateway`;

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                // setCampaignDetails(response.data.campaign_details[0]);
                // console.log("gateways: ", response.data.gateways);
                var tempGateways = response.data.gateways;
                if (tempGateways.length > 0) {
                    //console.log(atob(tempGateways.filter(g => g.gateway_name === "Crypto - Binance")[0]["gateway_secret"]));
                    //console.log(atob(tempGateways.filter(g => g.gateway_name === "Crypto - Ethereum")[0]["gateway_secret"]));
                    setCryptoReciverAddress(
                        {
                            "ethereum": tempGateways.filter(g => g.gateway_name === "Crypto - Ethereum") > 0 ? atob(tempGateways.filter(g => g.gateway_name === "Crypto - Ethereum")[0]["gateway_secret"]) : "",
                            "binance": tempGateways.filter(g => g.gateway_name === "Crypto - Binance") > 0 ? atob(tempGateways.filter(g => g.gateway_name === "Crypto - Binance")[0]["gateway_secret"]) : ""
                        }
                    )
                    setPaypalClientID(tempGateways.filter(g => g.gateway_name === "Paypal") > 0 ? atob(tempGateways.filter(g => g.gateway_name === "Paypal")[0]["gateway_secret"]) : "")
                }
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    }

    const PageHeader = () => (
        <>
            <div className="bg-img">
                <img src="/assets/img/bg/work-bg-03.png" alt="img" className="bgimg1" />
                <img src="/assets/img/bg/work-bg-03.png" alt="img" className="bgimg2" />
                <img src="/assets/img/bg/feature-bg-03.png" alt="img" className="bgimg3" />
            </div>
            <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h2 className="breadcrumb-title">{campaignDeails.campaign_title}</h2>
                            {/* <nav aria-label="breadcrumb" className="page-breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">Details</li>
                                    <li className="breadcrumb-item active" aria-current="page">{campaignDeails.service_title}</li>
                                </ol>
                            </nav> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

    // const handleFeaturedExtra = (control, extra) => {
    //     // console.log("control: ", control.value);
    //     // console.log("extra: ", extra);
    //     var temp = [...selectedExtras];
    //     var p = totalPrice;

    //     if (temp.length === 0) { // add object if not exists
    //         temp.push(extra);
    //         setSelectedExtras(temp);
    //         p = p + parseFloat(extra.price);
    //     }
    //     else if (temp.length > 0) {
    //         var exist = temp.some(e => e.feature === extra.feature);
    //         if (exist) { // remove if object exists
    //             temp = temp.filter(function (obj) {
    //                 return obj.feature !== extra.feature;
    //             });
    //             setSelectedExtras(temp);
    //             p = p - parseFloat(extra.price);
    //         }
    //         else {
    //             temp.push(extra);
    //             setSelectedExtras(temp);
    //             p = p + parseFloat(extra.price);
    //         }
    //     }
    //     setTotalPrice(p);
    // }

    const preceedToPay = (step) => {
        if (step === 1) {
            setActiveTab("payment");
        }
        else if (step === 2) {
            setActiveTab("done");
        }
    }

    const handlePaymentMethod = (event) => {
        setPaymentMethod(event.target.value);
    }

    const initialOptions = {
        clientId: paypalClientID !== "" ? paypalClientID : process.env.REACT_APP_PAYPAL_CLIENTID,
        currency: process.env.REACT_APP_PAYPAL_CURRENCY,
        intent: "capture",
        components: "buttons",
        //"disable-funding": "credit,card,venmo,paylater"
        "disable-funding": "credit,venmo,paylater"
    };

    const createOrder = (data, actions, err) => {
        // console.log("create order data: ", data);
        // console.log("create order actions: ", actions);
        // console.log("create order err: ", err);
        return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
                {
                    description: campaignDeails["campaign_title"],
                    amount: {
                        currency_code: "USD",
                        value: totalPrice,
                    },
                },
            ],
        });
    }

    const onApprove = async (data, actions) => {
        // console.log("onApprove data: ", data);
        // console.log("onApprove actions: ", actions);
        const order = await actions.order.capture();
        // console.log("onApprove: ", order);

        //saveOrder(order["status"], JSON.stringify(order));
        saveOrder(order["purchase_units"][0]["payments"]["captures"][0]["status"], JSON.stringify(order));

        // console.log('order["status"]: ', order["status"])
        // console.log('order["purchase_units"][0]["payments"]["captures"][0]["id"]: ', order["purchase_units"][0]["payments"]["captures"][0]["id"])
        // console.log('order["purchase_units"][0]["payments"]["captures"][0]["status"]: ', order["purchase_units"][0]["payments"]["captures"][0]["status"]);

    }

    const onCancel = async (data) => {
        // console.log("onCancel data: ", data);
        console.log(data);
    }

    const onError = async (err) => {
        // console.log("onError err: ", err);
        console.log(err);
    }

    const saveOrder = (status, payment_response) => {
        var user_id = base64_decode(getCookie("token")).split("|")[0];
        var details = {
            orderDetails: [...serviceFormData],
            extras: [],
            selectedCampaign: [...selectedPackageServices]
        }
        // console.log("details: ", details);

        var json = '';
        var url = '';
        if (paymentMethod !== "crypto") {
            json = JSON.stringify({ "package_id": base64_decode(campaignDeails["campaign_id"]), "order_amount": totalPrice, "method": selectedPaymentMethod, "status": status, "payment_response": JSON.stringify(JSON.parse(payment_response)), "user_id": user_id, "order_details": JSON.stringify(details), "service_type": "campaign" });
            url = `${process.env.REACT_APP_BASE_API}/api.php?action=save_order`;
        }
        else if (paymentMethod === "crypto") {
            json = JSON.stringify({ "package_id": base64_decode(campaignDeails["campaign_id"]), "order_amount": totalPrice, "method": selectedPaymentMethod, "status": status, "payment_response": JSON.stringify(cryptoOrderResponse.payment_response), "user_id": user_id, "order_details": JSON.stringify(details), "service_type": "campaign" });
            url = `${process.env.REACT_APP_BASE_API}/api.php?action=save_order`;
        }

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                setTimeout(() => {
                    setActiveTab("done");
                }, 1000);
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    }

    const SummeryBox = () => (
        <div className="summary-box">
            <div className="booking-info">
                <div className="service-book">
                    {/* <div className="service-book-img">
                        <img src={`${process.env.REACT_APP_BASE_API}/${campaignDeails["package_title_image"]}`} alt="img" style={{ border: "1px solid #4c40ed" }} />
                    </div> */}
                    <div className="serv-profile w-100">
                        <span className="badge px-0 mb-0">{campaignDeails.campaign_title}</span>
                        <h2 className='mb-0 fw-light'>{campaignDeails.campaign_description}</h2>
                    </div>
                </div>
            </div>

            <div className="booking-summary">
                {
                    selectedPackageServices.length > 0 &&
                    (
                        <>
                            <h6 className="px-3 pt-3 mb-0">Campaign Details</h6>
                            <ul className="booking-date">
                                {selectedPackageServices.map((pkg, index) => (
                                    <li key={index} className='d-flex flex-column'>
                                        {pkg.tier !== "" && <span className='mb-0 align-self-start'>{pkg.tier}</span>}
                                        <div className='d-flex'>
                                            <p className='mb-0' style={{ minWidth: "50px" }}>{pkg.quantity}</p>
                                            <div className='d-flex flex-column align-items-start'>
                                                <span className='text-black'>{pkg.service}</span>
                                                {/* <span>{pkg.details}</span> */}
                                                {
                                                    pkg.details.split("||").map((p, i) => (
                                                        <span className='text-start' key={i}>↳ {p}</span>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </>
                    )
                }
                {/* {selectedExtras.length > 0 &&
                    (<>
                        <h6 className="px-3 pt-3 mb-0">Featured Extras</h6>
                        <ul className="booking-date">
                            {selectedExtras.map((ext, index) => (
                                <li key={index}>{ext.feature} <span>${ext.price}</span></li>
                            ))}
                        </ul>
                    </>)
                } */}
                <div className="booking-total">
                    <ul className="booking-total-list">
                        <li>
                            <span>Total</span>
                            <span className="total-cost">${totalPrice}</span>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    )

    const createOrderDetailsJson = () => {
        var user_id = base64_decode(getCookie("token")).split("|")[0];
        var details = {
            package_id: base64_decode(campaignDeails["campaign_id"]),
            service_type: "campaign",
            order_amount: totalPrice,
            user_id: user_id,
            orderDetails: [...serviceFormData],
            extras: [],
            selectedCampaign: [...selectedPackageServices]
        }
        return JSON.stringify(details);
    }

    return (
        <>
            <Header />

            <PageHeader />

            <div className="content book-content" id="SeoCampaign">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">

                            <ul className="step-register row">
                                <li className={`${activeTab === "details" ? "active col-md-4" : "activate col-md-4"}`}>
                                    <div className="multi-step-icon">
                                        <span><img src="/assets/img/icons/calendar-icon.svg" alt="img" /></span>
                                    </div>
                                    <div className="multi-step-info">
                                        <h6>Order Details</h6>
                                        <p>Please provide details</p>
                                    </div>
                                </li>
                                <li className={`${activeTab === "payment" ? "active col-md-4" : ((activeTab !== "details" && activeTab !== "payment") ? "activate col-md-4" : "col-md-4")}`}>
                                    <div className="multi-step-icon">
                                        <span><img src="/assets/img/icons/wallet-icon.svg" alt="img" /></span>
                                    </div>
                                    <div className="multi-step-info">
                                        <h6>Payment</h6>
                                        <p>Select Payment Gateway</p>
                                    </div>
                                </li>
                                <li className={`${activeTab === "done" ? "active col-md-4" : "col-md-4"}`}>
                                    <div className="multi-step-icon">
                                        <span><img src="/assets/img/icons/book-done.svg" alt="img" /></span>
                                    </div>
                                    <div className="multi-step-info">
                                        <h6>Done </h6>
                                        <p>Completion of Order</p>
                                    </div>
                                </li>
                            </ul>

                            {activeTab === "details" &&
                                (
                                    <>
                                        <CampaignSelector campaignPackages={campaignDeails["campaign_packages"]} SelectedPackage={setSelectedPackage} />

                                        <div className="row">
                                            <div className="col-lg-6">
                                                {/* {(campaignDeails["featured_extras"] !== undefined && campaignDeails["featured_extras"].length > 0) ? <h5 className="pay-title mt-4 mb-2">Order Details</h5> : <h5 className="pay-title">Order Details</h5>} */}
                                                <h5 className="pay-title mb-2">Order Details</h5>
                                                {
                                                    campaignDeails["campaign_form"] !== undefined && <ServiceForm formFields={campaignDeails["campaign_form"]} setEnableProceedButton={setEnableProceedButton} setServiceFormData={setServiceFormData} />
                                                }

                                                {/* {
                                            campaignDeails["featured_extras"] !== undefined &&
                                            <>
                                                {(campaignDeails["campaign_form"].trim() !== "" && campaignDeails["campaign_form"].trim() !== "[]") ? <h5 className="pay-title mt-4">Featured Extra</h5> : <h5 className="pay-title">Featured Extra</h5>}
                                                {
                                                    JSON.parse(campaignDeails["featured_extras"]).map((extra, index) => (
                                                        <div className="payment-card payment-bg mb-2" key={index}>
                                                            <div className="payment-head">
                                                                <div className="payment-title w-100">
                                                                    <label className="custom_check me-2 w-100 mb-0">
                                                                        <input type="checkbox" name="payment" className="card-payment" value={selectedExtras.some(e => e.feature === extra.feature)} onChange={(e) => handleFeaturedExtra(e.target, extra)} />
                                                                        <span className="checkmark" style={{ marginTop: "3px" }} />

                                                                        <div className='w-100 d-flex flex-row justify-content-between align-items-center ms-2'>
                                                                            <h5 className='m-0'>{extra["feature"]}</h5>
                                                                            <h6>$ {extra["price"]}</h6>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        } */}
                                            </div>
                                            <div className="col-lg-6">
                                                <h5 className="pay-title">Order Summary</h5>
                                                <SummeryBox />
                                                <div className="booking-pay">
                                                    {enableProceedButton ?
                                                        <button className="btn btn-primary btn-pay w-100" style={{ marginRight: "0px" }} onClick={() => preceedToPay(1)}>Proceed to Pay ${totalPrice !== 0 ? totalPrice : campaignDeails["package_price"]}</button>
                                                        :
                                                        <button className="btn btn-primary btn-pay w-100" style={{ marginRight: "0px" }} disabled>Proceed to Pay ${totalPrice !== 0 ? totalPrice : campaignDeails["package_price"]}</button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }

                            {activeTab === "payment" &&
                                (<div className="row">
                                    <div className="col-lg-6">
                                        <h5 className="pay-title">Payment Methods</h5>
                                        <div className="payment-card mb-1">
                                            <div className="payment-head">
                                                <div className="payment-title w-100">
                                                    <label className="custom_radio w-100 mb-0">
                                                        <input type="radio" name="payment" className="card-payment" value="paypal" checked={paymentMethod === 'paypal'} onChange={handlePaymentMethod} />
                                                        <span className="checkmark" />
                                                        <div className='w-100 d-flex flex-row justify-content-between align-items-center ms-2'>
                                                            <h6>Pay with Paypal</h6>
                                                            <img className="me-2" src="/assets/img/payment/paypal.png" style={{ width: "60px" }} alt="paypal img" />
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="payment-list p-4 my-2" style={{ display: (paymentMethod === "paypal" ? 'block' : 'none'), backgroundColor: "#f7f7ff", borderRadius: "5px" }}>
                                            <div className="row align-items-center">
                                                <div className="col-md-12">
                                                    <PayPalScriptProvider options={initialOptions}>
                                                        <PayPalButtons
                                                            style={{ layout: "vertical", tagline: false }}
                                                            createOrder={createOrder}
                                                            onApprove={onApprove}
                                                            onError={onError}
                                                            onCancel={onCancel}
                                                        />
                                                    </PayPalScriptProvider>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="payment-card mb-1">
                                            <div className="payment-head">
                                                <div className="payment-title w-100">
                                                    <label className="custom_radio w-100 mb-0">
                                                        <input type="radio" name="payment" className="card-payment" value="crypto" checked={paymentMethod === 'crypto'} onChange={handlePaymentMethod} />
                                                        <span className="checkmark" />
                                                        <div className='w-100 d-flex flex-row justify-content-between align-items-center ms-2'>
                                                            <h6>Pay with Crypto</h6>
                                                            <div>
                                                                <img className="me-2" src="/assets/img/eth.svg" style={{ width: "12px" }} alt="eth img" />
                                                                <img className="me-2" src="/assets/img/bnb.svg" style={{ width: "20px" }} alt="bnb img" />
                                                            </div>

                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="payment-list p-4 my-2" style={{ display: (paymentMethod === "crypto" ? 'block' : 'none'), backgroundColor: "#f7f7ff", borderRadius: "5px" }}>
                                            <div className="row align-items-center">
                                                <div className="col-md-12">
                                                    <CryptoPaymentsForm totalPrice={totalPrice} setCryptoOrderResponse={setCryptoOrderResponse} cryptoRecieverAddress={cryptoRecieverAddress} orderDetailsJSON={createOrderDetailsJson} />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-lg-6">
                                        <h5 className="pay-title">Order Summary</h5>
                                        <SummeryBox />
                                    </div>
                                </div>)
                            }

                            {activeTab === "done" &&
                                (
                                    <>
                                        <div className="row align-items-center">
                                            <div className="col-md-7">
                                                <div className="booking-done">
                                                    <h6>Successfully Completed Payment</h6>
                                                    <p>Your Order has been Successfully Competed</p>
                                                    <div className="book-submit">
                                                        <Link to={'/'} className="btn btn-primary">
                                                            <i className="feather-arrow-left-circle me-2"></i>Back to Home
                                                        </Link>

                                                        <Link to={'/dashboard'} className="btn btn-secondary">
                                                            Dashboard
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-5">
                                                <div className="booking-done">
                                                    <img src="/assets/img/booking-done.png" className="img-fluid" alt="done" />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>

            <section className="healthy-eight-section" style={{ backgroundColor: "#f7f8f9" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="section-heading section-heading-eight aos aos-init aos-animate" data-aos="fade-up">
                                {/* <img src="/assets/img/logo.png" alt="logo" loading="lazy" /> */}
                                <h3>How to submit your initial search engine optimization campaign:</h3>
                                <p className="w-75 mx-auto">It's simple: Select your budget, Enter your information, and then click "Submit."</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-12">
                            <div className="pets-content-all">
                                {/* <h2>Keeping your pets</h2> */}
                                <h4>Select your budget:</h4>
                                <p>
                                    To determine the cost of the SEO campaign you need to buy, use the pricing slider.
                                </p>
                                <p>
                                    Each time you adjust the slider's price, the "Campaign details" will also adjust.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="healthy-pets-img d-flex justify-content-end">
                                <img src="https://panel.seoestore.net/assets/img/campaigns-v3/choose-budget.png" alt="get seen" className="img-fluid w-75" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="healthy-eight-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-12">
                            <div className="healthy-pets-img d-flex justify-content-start">
                                <img src="https://panel.seoestore.net/assets/img/form.png" alt="get seen" className="img-fluid w-75" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="pets-content-all">
                                <h4>2. Enter your information:</h4>
                                <p>
                                    Fill out the "order details" form
                                </p>
                                <p>
                                    <b>1. Links</b><br />
                                    Enter one link per line in this section.
                                </p>
                                <p>
                                    <b>2. Keywords</b><br />
                                    Add one keyword per line to this link.
                                </p>
                                <p>
                                    3. If you don't select the appropriate article category, it will be determined automatically for your links and keywords.
                                </p>
                                <p>
                                    <b>4. Select "Submit Campaign."</b>
                                </p>
                                {/* <a href="#" className="btn btn-primary">Book Now</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="satisfied-client" style={{ backgroundColor: "#000" }}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="premium-heading aos-init aos-animate text-center" data-aos="fade-down">
                                <p><big>206,747</big> people have placed <big>33,524,847</big> orders with us.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="client-sections review-four">
                <div className="container">
                    <div className="services-header aos aos-init aos-animate" data-aos="fade-up">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-content section-client mb-0">
                                    <h2>Put your website at the top of Google!</h2>
                                    <p>To efficiently rank your website in Google, use SEO campaigns. Set a budget and begin your link-building efforts.</p>
                                    <a className="read-more align-self-center px-5 mt-0 mt-md-4" href="#SeoCampaign">Submit SEO Campaign</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="healthy-eight-section" style={{ backgroundColor: "#f7f8f9" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="section-heading section-heading-eight aos aos-init aos-animate" data-aos="fade-up">
                                {/* <img src="/assets/img/logo.png" alt="logo" loading="lazy" /> */}
                                <h2>Let's see more about the services</h2>
                                <p className="w-75 mx-auto">In the campaign details section, you will see the full details for the backlinks services for the selected SEO ccampaign</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-12">
                            <div className="healthy-pets-img d-flex justify-content-start">
                                <img src="https://panel.seoestore.net/assets/img/campaigns-v3/campaign-details.png" alt="get seen" className="img-fluid w-75" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="pets-content-all">
                                <img src="https://panel.seoestore.net/assets/img/service.png" alt="high DA" className="img-fluid w-75" />
                                <p>
                                    The orange color define the quantity of this service, when the blue color referring to the service name, click on the  to go to the full services details page
                                </p>

                                <img src="https://panel.seoestore.net/assets/img/campaigns-v3/extras.png" alt="extras" className="img-fluid w-75" />
                                <p>
                                    As we always recommend the extras (for each single order) each services comes with the best extras within your defined SEO campaign budget.
                                </p>

                                <img src="https://panel.seoestore.net/assets/img/campaigns-v3/tier2-service.png" alt="high DA" className="img-fluid w-75" />
                                <p>
                                    You may also notice that there is a text in a purple block & this showing that the next services will be used as tier2 for the links that will created from the above services.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="client-sections review-four">
                <div className="container">
                    <div className="services-header aos aos-init aos-animate" data-aos="fade-up">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-content section-client mb-0">
                                    <h2>Boost website traffic and attract more visitors.</h2>
                                    <a className="read-more align-self-center px-5 mt-0 mt-md-4" href="#SeoCampaign">Get Started</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="healthy-eight-section" style={{ backgroundColor: "#f7f8f9" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="section-heading section-heading-eight aos aos-init aos-animate" data-aos="fade-up">
                                <h2>Backlinks' Quality!</h2>
                            </div>

                            <div className="pets-content-all">
                                <p>
                                    This page's campaigns are all made to be link wheel campaigns. In this type of campaign, we submit high-quality backlinks for the ordered links to your website along with some additional high-quality backlinks (also known as link pyramids).
                                </p>

                                <p>
                                    Link pyramids, or tier projects, are among the most effective SEO techniques. It offers the proxy that is required for backlinks to be built to your primary website.
                                </p>

                                <p>
                                    All campaigns' tier 1 (direct) links will function on our Premium Sites List, which is made up of the best World Wide Web 2.0 sites and sites with high Domain Authority (DA).
                                </p>

                                <p className='mb-0'>
                                    High-end campaigns have amazing characteristics since we will publish high-quality content to the most well-known websites in the world with the highest domain authority.
                                </p>

                                <p>
                                    Google will adore your website if it has unique human-quality content and custom image design at Top global Websites.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="healthy-eight-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="section-heading section-heading-eight aos aos-init aos-animate" data-aos="fade-up">
                                <h2>Some advice on budgeting for SEO campaigns</h2>
                                <p className="w-75 mx-auto">These pre-designed SEO strategies offer the greatest quality, quantity, and extras all within predetermined spending limits.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-12">
                            <div className="pets-content-all">
                                <p>
                                    The key takeaway from this is that Google needs to see hyperlinks to your website that are regularly produced, not just once.
                                </p>
                                <p>
                                    Thus, attempt to divide your monthly budget into smaller halves, and then create a weekly campaign for your keywords and links.
                                </p>
                                <p>
                                    Another crucial piece of advice is to continue developing high-quality connections for at least three months, but preferably nine months, depending on how difficult your niche is.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="healthy-pets-img d-flex justify-content-end">
                                <img src="https://panel.seoestore.net/assets/img/campaigns-v3/tips.png" alt="get seen" className="img-fluid w-50" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="healthy-eight-section" style={{ backgroundColor: "#f7f8f9" }}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-12">
                            <div className="healthy-pets-img d-flex justify-content-start">
                                <img src="https://panel.seoestore.net/assets/img/campaigns-v3/up.png" alt="get seen" className="img-fluid w-50" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="pets-content-all">
                                <p>
                                    <b>Please note that</b> The SEO is a bit long term process, as it may take few months to get your website ranked in Google. (In some cases it takes few weeks) as there is many factors such as: Domain/page authority, Keywords competition, Social signals (Facebook, Google shares for website ...) "very important", On-site optimization, Domain age, Current rank & many other factors.
                                </p>
                                <p>
                                    We designed these package to be insure it will be the most effective within a defined budget, but also you can customize your campaigns by adding Single Orders.
                                </p>
                                {/* <a href="#" className="btn btn-primary">Book Now</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="client-sections review-four">
                <div className="container">
                    <div className="services-header aos aos-init aos-animate" data-aos="fade-up">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-content section-client mb-0">
                                    <h2>Ready to start?!</h2>
                                    <a className="read-more align-self-center px-5 mt-0 mt-md-4" href="#SeoCampaign">Submit SEO Campaign</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <ReviewsSection showslider={true} />

            <Footer />

            <div className="mouse-cursor cursor-outer" style={{ visibility: 'visible' }} />
            <div className="mouse-cursor cursor-inner" style={{ visibility: 'visible' }} />
        </>
    )
}

export default Campaign