import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom'

import Alert from '../Alert';

import { getCookie, getWords } from '../../func';
import Pagination from '../Pagination';

const AddedCampaigns = ({ campaigns, TotalCampaigns, ItemLimit }) => {
    const [tempcampaigns, setTempCampaigns] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);

    const [alert, setAlert] = useState({
        type: '',
        message: '',
        show: false
    });

    useEffect(() => {
        if (campaigns.length > 0)
            setTempCampaigns([...campaigns]);
    }, [campaigns])

    const handleMessage = (params) => {
        setAlert({ type: params.type, message: params.message, show: true });

        setTimeout(() => {
            setAlert({ type: '', message: '', show: false });
        }, 3000);
    }

    const activatedeativeCampaign = (action, id) => {
        const json = JSON.stringify({ "token": getCookie("token"), "campaign_id": id, "action": action });
        const url = `${process.env.REACT_APP_BASE_API}/api.php?action=activate_deactivate_campaign`;

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                var temp = [...tempcampaigns];
                temp.filter(s => s.campaign_id === btoa(btoa(id)))[0]["campaign_status"] = parseInt(action);
                setTempCampaigns([...temp]);
                handleMessage({ type: 'success', message: response.data.message, show: true });
            }
            else {
                handleMessage({ type: 'danger', message: response.data.message, show: true });
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    }

    const fetchCampaigns = (offset, limit) => {
        const json = JSON.stringify({ "token": getCookie("token"), "limit": limit, "offset": offset });
        const url = `${process.env.REACT_APP_BASE_API}/api.php?action=get_campaigns`;

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                // console.log(response.data.campaigns);
                setTempCampaigns([...response.data.campaigns]);
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        const indexOfLastRecord = pageNumber * ItemLimit;
        fetchCampaigns(indexOfLastRecord, ItemLimit);
    };

    // // Calculate the indices for slicing the data array
    // const indexOfLastRecord = (currentPage + 1) * itemsPerPage;
    // const indexOfFirstRecord = indexOfLastRecord - itemsPerPage;
    // const currentItems = data.slice(indexOfFirstRecord, indexOfLastRecord);
    const pageCount = Math.ceil(parseInt(TotalCampaigns) / ItemLimit);

    // const GenerateRecordNumber = (index) => {
    //     // return index + 1;
    //     return (ItemLimit * currentPage) + (index + 1);
    // }

    return (
        <>
            <Alert type={alert.type} message={alert.message} show={alert.show} />

            <h6 className="user-title mt-5">All Campaigns</h6>
            <div className="table-responsive">
                <table className="table mb-0 custom-table">
                    <thead className="thead-light">
                        <tr>
                            <th>#</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Status</th>
                            <th>Acion</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tempcampaigns.length > 0
                                ?
                                tempcampaigns.map((camp, index) => (
                                    <tr key={index}>
                                        <td>{atob(atob(camp.campaign_id))}</td>
                                        <td>
                                            <h2 className="table-avatar">
                                                {/* <div className="avatar avatar-m me-2">
                                                <img className="avatar-img rounded" src={`${process.env.REACT_APP_BASE_API}${camp.service_images}`} alt="service img" />
                                            </div> */}
                                                <div>
                                                    <Link to={`/campaign/${camp.campaign_slug}`}>{camp.campaign_title}</Link>
                                                    {/* <span>{camp.service_sub_title}</span> */}
                                                </div>
                                            </h2>
                                        </td>
                                        <td className="text-body">{getWords(camp.campaign_description, 5) + "..."}</td>
                                        <td>{camp.campaign_status === 1 ? <span className="badge-success">Active</span> : <span className="badge-danger">Inactive</span>}</td>
                                        <td>
                                            {
                                                camp.campaign_status === 1 ? <button className='btn btn-light-danger' onClick={() => activatedeativeCampaign("0", atob(atob(camp.campaign_id)))}>Deactivate</button> : <button className='btn btn-light-success' onClick={() => activatedeativeCampaign("1", atob(atob(camp.campaign_id)))}>Activate</button>
                                            }
                                            <Link to={`/update_campaign/${camp.campaign_slug}`} className='btn btn-sm btn-success ms-2' target='_blank'>Edit</Link>
                                        </td>
                                    </tr>
                                ))
                                :
                                (
                                    <tr>
                                        <td colSpan={5} className='text-center'>
                                            <span>Currently you have not added any campaign!</span>
                                        </td>
                                    </tr>
                                )
                        }
                    </tbody>
                </table>
            </div>

            {
                TotalCampaigns > ItemLimit && (<Pagination
                    pageCount={pageCount}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                />)
            }
        </>
    )
}

export default AddedCampaigns