import React from 'react'

const ReviewsSection = ({ showslider = false }) => {
    return (
        <section className={ showslider ? `review-client-section` : `review-client-section pb-0`}>
            <div className="container">

                <div className="section-heading section-heading-four">
                    <div className="row align-items-center">
                        <div className="col-md-12 aos aos-init aos-animate" data-aos="fade-up">
                            <h2>We give our clients tangible outcomes.</h2>
                            <p>Together, we help our customers achieve greater visibility and tangible outcomes.
                                We are interested in our clients' opinions and take pride in their satisfaction with our services.</p>
                        </div>
                        {/* <div className="col-md-6 aos aos-init aos-animate" data-aos="fade-up">
                            <h2>We get real results for our clients</h2>
                            <p>We work together with our clients to get more exposure and drive real results.
                                We care about our clients' opinion, they are happy about doing business with us.</p>
                        </div>
                        <div className="col-md-6 text-md-end aos aos-init aos-animate" data-aos="fade-up">
                            <a href="javascript:void(0)" className="btn btn-primary btn-view">VIEW ALL REVIEWS<i className="feather-arrow-right" /></a>
                        </div> */}
                    </div>
                </div>

                <div className="row aos aos-init aos-animate" data-aos="fade-up">
                    <div className="col-md-12">
                        <div className="owl-carousel client-four-slider common-four-slider owl-loaded owl-drag">
                            <div className="owl-stage-outer">
                                <div className="owl-stage" style={{ transform: 'translate3d(-2280px, 0px, 0px)', transition: 'all 2s ease 0s', width: '3800px' }}>
                                    <div className="owl-item cloned"> {/** style={{ width: '356px', marginRight: '24px' }} */}
                                        <div className="client-review-main">
                                            <div className="client-review-top">
                                                <div className="client-review-name">
                                                    <h6>Hashimoda Reena</h6>
                                                    <div className="rating">
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                    </div>
                                                </div>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua reader will be distracted Loremut labore et dolore.</p>
                                            </div>
                                            {/* <div className="client-review-img">
                                                <img src="assets/img/profiles/avatar-19.jpg" alt="User" />
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="owl-item cloned">
                                        <div className="client-review-main">
                                            <div className="client-review-top">
                                                <div className="client-review-name">
                                                    <h6>Jake Sulaine</h6>
                                                    <div className="rating">
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                    </div>
                                                </div>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua reader will be distracted Loremut labore et dolore.</p>
                                            </div>
                                            {/* <div className="client-review-img">
                                                <img src="assets/img/profiles/avatar-18.jpg" alt="User" />
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="owl-item cloned">
                                        <div className="client-review-main">
                                            <div className="client-review-top">
                                                <div className="client-review-name">
                                                    <h6>Daniela Fransis</h6>
                                                    <div className="rating">
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                    </div>
                                                </div>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua reader will be distracted Loremut labore et dolore.</p>
                                            </div>
                                            {/* <div className="client-review-img">
                                                <img src="assets/img/profiles/avatar-15.jpg" alt="User" />
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="owl-item">
                                        <div className="client-review-main">
                                            <div className="client-review-top">
                                                <div className="client-review-name">
                                                    <h6>Daniela Fransis</h6>
                                                    <div className="rating">
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                    </div>
                                                </div>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua reader will be distracted Loremut labore et dolore.</p>
                                            </div>
                                            {/* <div className="client-review-img">
                                                <img src="assets/img/profiles/avatar-15.jpg" alt="User" />
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="owl-item">
                                        <div className="client-review-main">
                                            <div className="client-review-top">
                                                <div className="client-review-name">
                                                    <h6>Hashimoda Reena</h6>
                                                    <div className="rating">
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                    </div>
                                                </div>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua reader will be distracted Loremut labore et dolore.</p>
                                            </div>
                                            {/* <div className="client-review-img">
                                                <img src="assets/img/profiles/avatar-19.jpg" alt="User" />
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="owl-item">
                                        <div className="client-review-main">
                                            <div className="client-review-top">
                                                <div className="client-review-name">
                                                    <h6>Jake Sulaine</h6>
                                                    <div className="rating">
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                    </div>
                                                </div>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua reader will be distracted Loremut labore et dolore.</p>
                                            </div>
                                            {/* <div className="client-review-img">
                                                <img src="assets/img/profiles/avatar-18.jpg" alt="User" />
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="owl-item active">
                                        <div className="client-review-main">
                                            <div className="client-review-top">
                                                <div className="client-review-name">
                                                    <h6>Daniela Fransis</h6>
                                                    <div className="rating">
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                    </div>
                                                </div>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua reader will be distracted Loremut labore et dolore.</p>
                                            </div>
                                            {/* <div className="client-review-img">
                                                <img src="assets/img/profiles/avatar-15.jpg" alt="User" />
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="owl-item cloned active">
                                        <div className="client-review-main">
                                            <div className="client-review-top">
                                                <div className="client-review-name">
                                                    <h6>Daniela Fransis</h6>
                                                    <div className="rating">
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                    </div>
                                                </div>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua reader will be distracted Loremut labore et dolore.</p>
                                            </div>
                                            {/* <div className="client-review-img">
                                                <img src="assets/img/profiles/avatar-15.jpg" alt="User" />
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="owl-item cloned active">
                                        <div className="client-review-main">
                                            <div className="client-review-top">
                                                <div className="client-review-name">
                                                    <h6>Hashimoda Reena</h6>
                                                    <div className="rating">
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                    </div>
                                                </div>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua reader will be distracted Loremut labore et dolore.</p>
                                            </div>
                                            {/* <div className="client-review-img">
                                                <img src="assets/img/profiles/avatar-19.jpg" alt="User" />
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="owl-item cloned">
                                        <div className="client-review-main">
                                            <div className="client-review-top">
                                                <div className="client-review-name">
                                                    <h6>Jake Sulaine</h6>
                                                    <div className="rating">
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                        <i className="fas fa-star filled" />
                                                    </div>
                                                </div>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua reader will be distracted Loremut labore et dolore.</p>
                                            </div>
                                            {/* <div className="client-review-img">
                                                <img src="assets/img/profiles/avatar-18.jpg" alt="User" />
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="owl-nav disabled">
                                <button type="button" role="presentation" className="owl-prev">
                                    <span aria-label="Previous">‹</span>
                                </button>
                                <button type="button" role="presentation" className="owl-next">
                                    <span aria-label="Next">›</span>
                                </button>
                            </div>
                            <div className="owl-dots">
                                <button className="owl-dot"><span /></button>
                                <button className="owl-dot active"><span /></button>
                            </div> */}
                        </div>
                    </div>
                </div>

                {
                    showslider && (<div id="company-slider" className="owl-carousel owl-theme testimonial-slider owl-loaded owl-drag">
                        {/**owl-carousel owl-theme testimonial-slider aos owl-loaded owl-drag aos-init aos-animate  data-aos="fade-up"*/}
                        <div className="owl-stage-outer">
                            <div className="owl-stage">
                                <div className="owl-item">
                                    <div className="company-logos">
                                        <img src="/assets/img/payment/paypal.png" alt="Logo" loading='lazy' />
                                    </div>
                                </div>
                                <div className="owl-item">
                                    <div className="company-logos">
                                        <img src="/assets/img/payment/cards.png" alt="Logo" loading='lazy' />
                                    </div>
                                </div>
                                <div className="owl-item">
                                    <div className="company-logos">
                                        <img src="/assets/img/payment/airtm.png" alt="Logo" loading='lazy' />
                                    </div>
                                </div>
                                <div className="owl-item">
                                    <div className="company-logos">
                                        <img src="/assets/img/payment/bitcoin.png" alt="Logo" loading='lazy' />
                                    </div>
                                </div>
                                <div className="owl-item">
                                    <div className="company-logos">
                                        <img src="/assets/img/payment/payoneer.png" alt="Logo" loading='lazy' />
                                    </div>
                                </div>
                                <div className="owl-item">
                                    <div className="company-logos">
                                        <img src="/assets/img/payment/neteller.png" alt="Logo" loading='lazy' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="owl-stage-outer">
                        <div className="owl-stage">
                            <div className="owl-item cloned">
                                <div className="company-logos">
                                    <img src="assets/img/icons/logo-03.svg" alt="Logo" />
                                </div>
                            </div>
                            <div className="owl-item cloned">
                                <div className="company-logos">
                                    <img src="assets/img/icons/logo-06.svg" alt="Logo" />
                                </div>
                            </div>
                            <div className="owl-item cloned">
                                <div className="company-logos">
                                    <img src="assets/img/icons/logo-04.svg" alt="Logo" />
                                </div>
                            </div>
                            <div className="owl-item cloned">
                                <div className="company-logos">
                                    <img src="assets/img/icons/logo-05.svg" alt="Logo" />
                                </div>
                            </div>
                            <div className="owl-item cloned">
                                <div className="company-logos">
                                    <img src="assets/img/icons/logo-03.svg" alt="Logo" />
                                </div>
                            </div>
                            <div className="owl-item cloned">
                                <div className="company-logos">
                                    <img src="assets/img/icons/logo-06.svg" alt="Logo" />
                                </div>
                            </div>
                            <div className="owl-item">
                                <div className="company-logos">
                                    <img src="assets/img/icons/logo-01.svg" alt="Logo" />
                                </div>
                            </div>
                            <div className="owl-item">
                                <div className="company-logos">
                                    <img src="assets/img/icons/logo-02.svg" alt="Logo" />
                                </div>
                            </div>
                            <div className="owl-item active">
                                <div className="company-logos">
                                    <img src="assets/img/icons/logo-03.svg" alt="Logo" />
                                </div>
                            </div>
                            <div className="owl-item active">
                                <div className="company-logos">
                                    <img src="assets/img/icons/logo-06.svg" alt="Logo" />
                                </div>
                            </div>
                            <div className="owl-item active">
                                <div className="company-logos">
                                    <img src="assets/img/icons/logo-04.svg" alt="Logo" />
                                </div>
                            </div>
                            <div className="owl-item active">
                                <div className="company-logos">
                                    <img src="assets/img/icons/logo-05.svg" alt="Logo" />
                                </div>
                            </div>
                            <div className="owl-item active">
                                <div className="company-logos">
                                    <img src="assets/img/icons/logo-03.svg" alt="Logo" />
                                </div>
                            </div>
                            <div className="owl-item active">
                                <div className="company-logos">
                                    <img src="assets/img/icons/logo-06.svg" alt="Logo" />
                                </div>
                            </div>
                            <div className="owl-item cloned">
                                <div className="company-logos">
                                    <img src="assets/img/icons/logo-01.svg" alt="Logo" />
                                </div>
                            </div>
                            <div className="owl-item cloned">
                                <div className="company-logos">
                                    <img src="assets/img/icons/logo-02.svg" alt="Logo" />
                                </div>
                            </div>
                            <div className="owl-item cloned">
                                <div className="company-logos">
                                    <img src="assets/img/icons/logo-03.svg" alt="Logo" />
                                </div>
                            </div>
                            <div className="owl-item cloned">
                                <div className="company-logos">
                                    <img src="assets/img/icons/logo-06.svg" alt="Logo" />
                                </div>
                            </div>
                            <div className="owl-item cloned">
                                <div className="company-logos">
                                    <img src="assets/img/icons/logo-04.svg" alt="Logo" />
                                </div>
                            </div>
                            <div className="owl-item cloned">
                                <div className="company-logos">
                                    <img src="assets/img/icons/logo-05.svg" alt="Logo" />
                                </div>
                            </div>
                        </div>
                    </div> */}
                        {/* <div className="owl-nav disabled">
                        <button type="button" role="presentation" className="owl-prev"><i className="fas fa-chevron-left" /></button>
                        <button type="button" role="presentation" className="owl-next"><i className="fas fa-chevron-right" /></button>
                    </div>
                    <div className="owl-dots disabled" /> */}
                    </div>)
                }
            </div>
        </section>
    )
}

export default ReviewsSection