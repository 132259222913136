import React from 'react'
import Header from '../../components/Header';
import ReviewsSection from '../../components/ReviewsSection';
import Footer from '../../components/Footer';

const SocialMediaVisibility = () => {
    return (
        <>
            <Header />

            <section className="hero-section" style={{ minHeight: "350px" }}>
                <div className="container">
                    <div className="home-banner">
                        <div className="row w-100">
                            <div className="col-lg-6 col-md-10 mx-auto">
                                <div className="section-search aos aos-init aos-animate" data-aos="fade-up">
                                    {/* <h1>Are You Looking for <br /><b>Effective SEO Services?</b></h1> */}
                                    <h1>Social Media Visibility</h1>
                                    <p className="mb-3 text-black fw-bold">Become More Visible on Social Media</p>
                                    <p className="mb-5">Astonish your audience time and time again with your social media presence. We assist with strategy and growth</p>

                                    <a className="btn btn-primary" href="#"><i className="feather-user me-2" />Get Started!</a>

                                </div>
                            </div>
                            <div className="col-lg-6 d-flex justify-content-center">
                                <img src="https://panel.seoestore.net/assets/img/pages/be-visible-in-social.png" className="img-fluid aos aos-init aos-animate" alt="img" data-aos="fade-up" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="work-section py-5"> {/**works-eight-section */}
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="section-heading section-heading-eight aos aos-init aos-animate" data-aos="fade-up">
                                <h2 style={{ color: "#5347e9" }}>Increase Your Social Media Presence and Be Visible on Social Media!</h2>
                                {/* <p className='w-75 mx-auto'>Start getting real exposure for your brand. We'll take care of it for you. We can get an article written and published on the network of over 200+ news sites, all done within just 5 days.</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center aos aos-init aos-animate" data-aos="fade-up">
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="works-eights-main">
                                <div className="works-eights-img">
                                    <img src="https://panel.seoestore.net/assets/img/pages/features-icons/rank-your-social.png" alt="steps" className='img-fluid w-75' />
                                </div>
                                <h3 style={{ color: "#4e42eb" }}>Rank your social media</h3>
                                <p className='text-start text-md-center'>These days, rating your social media pages on Google is much simpler and more important than ranking your websites.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="works-eights-main">
                                <div className="works-eights-img">
                                    <img src="https://panel.seoestore.net/assets/img/pages/features-icons/get-verified.png" alt="steps" className='img-fluid w-75' />
                                </div>
                                <h3 style={{ color: "#4e42eb" }}>Obtain verification</h3>
                                <p className='text-start text-md-center'>It's time to get verified if you want your clients to trust you on social media.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="works-eights-main">
                                <div className="works-eights-img">
                                    <img src="https://panel.seoestore.net/assets/img/pages/features-icons/share-your-news.png" alt="steps" className='img-fluid w-75' />
                                </div>
                                <h3 style={{ color: "#4e42eb" }}>Send out your news</h3>
                                <p className='text-center text-md-start'>Posting your news on the most popular news websites will increase publicity for your business.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="work-section pt-5 pb-5" style={{ backgroundColor: "#ffffff" }}>
                <div className="container">

                    <div className="row mb-4">
                        <div className="col-md-12 text-center">
                            <div className="section-heading section-heading-eight aos aos-init aos-animate mb-0" data-aos="fade-up">
                                {/* <img src="/assets/img/logo.png" alt="logo" loading="lazy" /> */}
                                <h2>Increase Traffic Through Your Social Media Presence with Our Excellent Service</h2>
                                <p className='w-75 mx-auto'>Obtain publication on more than 200 major news websites. Our articles demonstrate that you and your company are well-known public characters who want validation.</p>
                            </div>
                        </div>
                    </div>

                    {/* <div className="row pt-5 mb-0 mb-md-5"> */}
                    <div className="row align-items-center aos aos-init aos-animate" data-aos="fade-up">
                        <div className="col-lg-6 col-12">
                            <div className="pets-content-all">
                                <h3>Make your order with only a few simple clicks</h3>
                                <p>In just a few simple steps, increase the number of people who visit your website and your search engine rankings:</p>
                                <ul className='my-4'>
                                    <li style={{ listStyleType: 'disclosure-closed', listStylePosition: 'inside', color: "#4c40ed", marginBottom: "4px" }}><span className='text-muted fw-bold'>Featured on well-known news websites seen by millions of people</span></li>
                                    <li style={{ listStyleType: 'disclosure-closed', listStylePosition: 'inside', color: "#4c40ed", marginBottom: "4px" }}><span className='text-muted fw-bold'>Select the Press Release (High-End News Websites) option</span></li>
                                    <li style={{ listStyleType: 'disclosure-closed', listStylePosition: 'inside', color: "#4c40ed", marginBottom: "4px" }}><span className='text-muted fw-bold'>Make sure to select additional press release writing for the best outcome</span></li>
                                    <li style={{ listStyleType: 'disclosure-closed', listStylePosition: 'inside', color: "#4c40ed", marginBottom: "4px" }}><span className='text-muted fw-bold'>Enter your keywords and link(s)</span></li>
                                    <li style={{ listStyleType: 'disclosure-closed', listStylePosition: 'inside', color: "#4c40ed", marginBottom: "4px" }}><span className='text-muted fw-bold'>Send in your order</span></li>
                                    <li style={{ listStyleType: 'disclosure-closed', listStylePosition: 'inside', color: "#4c40ed", marginBottom: "4px" }}><span className='text-muted fw-bold'>Examine your reports to ensure flawless outcomes</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="healthy-pets-img d-flex justify-content-end">
                                <img src="https://panel.seoestore.net/assets/img/pages/features/be-visible-in-social.png" alt="get seen" className="img-fluid w-75" />
                            </div>
                        </div>
                    </div>
                    {/* </div> */}

                </div>
            </section>

            <section className="client-sections review-four">
                <div className="container">
                    <div className="services-header aos aos-init aos-animate" data-aos="fade-up">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-content section-client mb-0">
                                    <h2>Featured on 200 news sites, including USA Today and FOX</h2>
                                    <p>With the aid of the most reputable press release agency, expand your social media presence right now.</p>
                                    <div className='d-flex'>
                                        <a className="read-more align-self-center px-5 mt-0 mt-md-4 mx-2" href="#">Order Now!</a>
                                        {/* <a className="read-more align-self-center px-5 mt-0 mt-md-4 mx-2" href="#">More about press release service</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="client-sections review-four">
                <div className="container">
                    <div className="services-header aos aos-init aos-animate" data-aos="fade-up">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-content section-client mb-0">
                                    <h2>Featured Ranking Services</h2>
                                    <p>Using our services gives your brand a great push, rank your brand name with SEO campaigns and get some wide-reaching news links.</p>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-md-4 offset-md-2">
                                <div className="work-box aos aos-init aos-animate h-100 mb-0 d-flex flex-column justify-content-between p-2 rounded-2" data-aos="fade-up">                                    
                                    <img src="https://panel.seoestore.net/assets/img/seo-services/seo-campaigns.jpg" className='rounded-top mb-4' alt="seo campaign" />                                    
                                    <h3 className="title fs-4"><a href="/campaign/seo-campaign">SEO Campaign</a></h3>                                    
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="work-box aos aos-init aos-animate h-100 mb-0 d-flex flex-column justify-content-between p-2 rounded-2" data-aos="fade-up">                                    
                                    <img src="https://panel.seoestore.net/assets/img/seo-services/press-release-1.jpg" className='rounded-top mb-4' alt="press release" />                                    
                                    <h3 className="title fs-4"><a href="/pressrelease">Press Release</a></h3>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <ReviewsSection />

            <Footer />

            <div className="mouse-cursor cursor-outer" style={{ visibility: 'visible' }} />
            <div className="mouse-cursor cursor-inner" style={{ visibility: 'visible' }} />
        </>
    )
}

export default SocialMediaVisibility