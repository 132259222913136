import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';

import Header from '../../components/Header'
//import Alert from '../../components/Alert';

import { getCookie, setCookie, checkRole } from '../../func';

const VerifyAccount = () => {
    const navigate = useNavigate();
    let params = useParams();

    const [alert, setAlert] = useState({
        type: '',
        message: ''
    });

    // const [alert, setAlert] = useState({
    //     type: '',
    //     message: '',
    //     show: false
    // });

    useEffect(() => {
        if (getCookie("token") !== undefined && getCookie("token") !== null) {
            //user is already loggedin
            if (checkRole(getCookie("token")) === "admin")
                navigate(`/dashboard`, { replace: true });
            else
                navigate(`/dashboard`, { replace: true });
        }

        if (params.token !== undefined && params.token !== null && params.token.toString().trim() !== "")
            Verify(params.token);
        else {
            navigate(`/`, { replace: true });
        }
    }, [])

    // const handleMessage = (params) => {
    //     setAlert({ type: params.type, message: params.message, show: true });

    //     setTimeout(() => {
    //         setAlert({ type: '', message: '', show: false });
    //     }, 3200);
    // }

    const Verify = (token) => {
        const json = JSON.stringify({ "verify_token": token });
        const url = `${process.env.REACT_APP_BASE_API}/api.php?action=verify_user`;

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                // handleMessage({ type: 'success', message: response.data.message, show: true });
                setAlert({ type: "success", message: response.data.message });

                setTimeout(() => {
                    navigate(`/login`, { replace: true });
                }, 3500);
            }
            else {
                //handleMessage({ type: 'danger', message: response.data.message, show: true });
                setAlert({ type: "danger", message: response.data.message });
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
            //handleMessage({ type: 'danger', message: "Something went wrong. Please try later", show: true });
            setAlert({ type: "danger", message: "Something went wrong. Please try later" });
        });
    }

    return (
        <>
            <Header />

            <div className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="error-wrap text-center">
                                <div className="error-logo">
                                    <Link to={"/"}><img className="img-fluid" src="/assets/img/logo.png" alt="img" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="error-wrap py-5">
                                <center>
                                    {
                                        alert.type === "success" ?
                                            <>
                                                <h2>{alert.message}</h2>
                                                <p>Thank you for verifying your account. Continue to login</p>
                                                <Link to={"/login"} className="btn btn-primary">
                                                    <i className="feather-arrow-left-circle me-2" />Login
                                                </Link>
                                            </>
                                            :
                                            <>
                                                <h2>{alert.message}</h2>
                                                {/* <p>Thank you for verifying your account. Continue to login</p> */}
                                                <Link to={"/"} className="btn btn-primary">
                                                    <i className="feather-arrow-left-circle me-2" />Home
                                                </Link>
                                            </>
                                    }
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mouse-cursor cursor-outer" style={{ visibility: 'visible' }} />
            <div className="mouse-cursor cursor-inner" style={{ visibility: 'visible' }} />
        </>
    )
}

export default VerifyAccount