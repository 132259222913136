import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { getWords } from '../../func';

import Header from '../../components/Header';

const Service = () => {
  const navigate = useNavigate();
  let params = useParams();

  const [serviceDetails, setServiceDetails] = useState({});
  const [packages, setPackages] = useState([]);
  const [type, setType] = useState('grid');

  useEffect(() => {
    if (params.slug !== undefined && params.slug !== null && params.slug.toString().trim() !== "")
      fetchServiceDetails(params.slug);
    else
      navigate(`/`, { replace: true });
  }, []);

  useEffect(() => {
    if (params.slug !== undefined && params.slug !== null && params.slug.toString().trim() !== "")
      fetchServiceDetails(params.slug);
    else
      navigate(`/`, { replace: true });
  }, [params])

  const PageHeader = () => (
    <>
      <div className="bg-img">
        <img src="/assets/img/bg/work-bg-03.png" alt="img" className="bgimg1" />
        <img src="/assets/img/bg/work-bg-03.png" alt="img" className="bgimg2" />
        <img src="/assets/img/bg/feature-bg-03.png" alt="img" className="bgimg3" />
      </div>
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">{serviceDetails.service_title}</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">{serviceDetails.service_title}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  )

  const fetchServiceDetails = (slug) => {
    const json = JSON.stringify({ "service_slug": slug, "limit": 10000, "offset": 0 });
    const url = `${process.env.REACT_APP_BASE_API}/api.php?action=get_service_packages`;

    axios.post(url, JSON.stringify({ params: json }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then((response) => {
      if (response.data.success === "true") {
        //setPackages(response.data.services);
        setServiceDetails(response.data.service[0]);
        setPackages(response.data.packages);
      }
    }).catch((error) => {
      console.error(`Error: ${error}`);
    });
  }

  const CreateServicesGrid = () => {
    return (
      <div className="row">
        {
          packages.map((p, index) => (
            <div className="col-xl-4 col-md-6" key={index}>
              <div className="service-widget servicecontent">
                <div className="service-img">
                  <Link to={`/details/${p["package_slug"]}`}>
                    <img className="img-fluid serv-img" alt="Service img" src={`${process.env.REACT_APP_BASE_API}/${p["package_title_image"]}`} />
                  </Link>
                  {/* <div className="fav-item">
                    <a href="categories.html"><span className="item-cat">Car Wash</span></a>
                    <a href="javascript:void(0)" className="fav-icon">
                      <i className="feather-heart" />
                    </a>
                  </div>
                  <div className="item-info">
                    <a href="providers.html"><span className="item-img"><img src="/assets/img/profiles/avatar-01.jpg" className="avatar" alt="User" /></span></a>
                  </div> */}
                </div>
                <div className="service-content">
                  <h3 className="title">
                    <Link to={`/details/${p["package_slug"]}`}>{p.package_name}</Link>
                  </h3>
                  <p className='mb-2'>
                    {/* <i className="feather-map-pin" />Maryland City, USA<span className="rate"><i className="fas fa-star filled" />4.9</span> */}
                    {getWords(p.package_details.replace(/<[^>]+>/g, " ").replaceAll("&nbsp;", " "), 30) + '...'}
                  </p>
                  <div className="serv-info">
                    <h6>${p.package_price}</h6>
                    <Link to={`/details/${p["package_slug"]}`} className="btn btn-book">Order Now</Link>
                  </div>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    )
  }

  const CreateServicesList = () => {
    return (
      <div className="row">
        {
          packages.map((p, index) => (
            <div className="col-md-12" key={index}>
              <div className="service-list">
                <div className="service-cont">
                  <div className="service-cont-img">
                    <a href="service-details.html">
                      <img className="img-fluid serv-img" alt="Service img" src={`${process.env.REACT_APP_BASE_API}/${p["package_title_image"]}`} />
                    </a>
                    {/* <div className="fav-item">
                      <a href="javascript:void(0)" className="fav-icon">
                        <i className="feather-heart" />
                      </a>
                    </div> */}
                  </div>
                  <div className="service-cont-info">
                    {/* <span className="item-cat">Car Wash</span> */}
                    <h3 className="title">
                      <Link to={`/details/${p["package_slug"]}`}>{p.package_name}</Link>
                    </h3>
                    <p>
                      {/* <i className="feather-map-pin" />Maryland City, MD, USA */}
                      {getWords(p.package_details.replace(/<[^>]+>/g, " ").replaceAll("&nbsp;", " "), 25) + '...'}
                    </p>
                    {/* <div className="service-pro-img">
                      <img src="assets/img/profiles/avatar-01.jpg" alt="user" />
                      <span><i className="fas fa-star filled" />4.9</span>
                    </div> */}
                  </div>
                </div>
                <div className="service-action">
                  <h6>${p.package_price}
                    {/* <span className="old-price">$35.00</span> */}
                  </h6>
                  <Link to={`/details/${p["package_slug"]}`} className="btn btn-secondary">Order Now</Link>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    )
  }

  return (
    <>
      <Header />

      <PageHeader />

      <div className="content">
        <div className="container">
          {
            packages.length > 0 ?
              <div className="row">
                <div className="col-12">
                  <div className="row sorting-div">
                    <div className="col-lg-4 col-sm-12 ">
                      <div className="count-search">
                        <h6>Found {packages.length} Packages</h6>
                      </div>
                    </div>
                    <div className="col-lg-8 col-sm-12 d-flex justify-content-end ">
                      {/* <div className="sortbyset">
                    <div className="sorting-select">
                      <select className="form-control select">
                        <option>Price Low to High</option>
                        <option>Price High to Low</option>
                      </select>
                    </div>
                  </div> */}
                      <div className="grid-listview">
                        <ul>
                          <li>
                            <div href="service-grid.html" className={type === "grid" ? `active` : ''} onClick={() => setType("grid")}>
                              <i className="feather-grid" />
                            </div>
                          </li>
                          <li>
                            <div href="service-list.html" className={type === "list" ? `active` : ''} onClick={() => setType("list")}>
                              <i className="feather-list" />
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  {(type !== "" && type === "grid") ? <CreateServicesGrid /> : <CreateServicesList />}

                  {/* <div className="row">
                <div className="col-sm-12">
                  <div className="blog-pagination rev-page">
                    <nav>
                      <ul className="pagination justify-content-center">
                        <li className="page-item disabled">
                          <a className="page-link page-prev" href="javascript:void(0);" tabIndex={-1}><i className="fa-solid fa-arrow-left me-1" /> PREV</a>
                        </li>
                        <li className="page-item active">
                          <a className="page-link" href="javascript:void(0);">1</a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="javascript:void(0);">2</a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="javascript:void(0);">3</a>
                        </li>
                        <li className="page-item">
                          <a className="page-link page-next" href="javascript:void(0);">NEXT <i className="fa-solid fa-arrow-right ms-1" /></a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div> */}
                </div>
              </div>
              :
              <div className='row mt-5'>
                <div className="col-md-12">
                  <div className="service-list d-flex justify-content-center">
                    <h6 style={{ fontWeight: 'normal' }}>Currently no package added in this service</h6>
                  </div>
                </div>
              </div>
          }
        </div>
      </div>

      <div className="mouse-cursor cursor-outer" style={{ visibility: 'visible' }} />
      <div className="mouse-cursor cursor-inner" style={{ visibility: 'visible' }} />
    </>
  )
}

export default Service