import React from 'react'
// import './style.css';

const Pagination = ({ pageCount, onPageChange, currentPage }) => {
    // console.log("pageCount", pageCount)
    // console.log("currentPage", currentPage)

    const pageNumbers = [];
    for (let i = 1; i <= pageCount; i++) {
        if (i === (currentPage)) { //add 1 page before current page
            pageNumbers.push(i);
        }
        else if (i === (currentPage + 1)) { //add current page
            pageNumbers.push(i);
        }
        else if (i === (currentPage + 2)) { //add 1 page after current page
            pageNumbers.push(i);
        }
        else {
            if (pageCount >= 3 && currentPage === 0 && (i === (currentPage + 2) || i === currentPage + 3)) {
                pageNumbers.push(i);
            }
            else if (pageCount >= 3 && currentPage === (pageCount - 1) && ((i === currentPage) || i === (currentPage - 1))) {
                pageNumbers.push(i);
            }
        }
    }

    const handlePageChange = (pagenumber, status) => {
        if (status === "enabled")
            onPageChange(pagenumber);
    }

    return (
        <>
            {/* <nav className="pagination-container">
                <button
                    onClick={() => onPageChange(0)}
                    disabled={currentPage === 0}
                >
                    First
                </button>
                {pageNumbers.map(number => (
                    <button
                        key={number}
                        onClick={() => onPageChange(number - 1)}
                        className={`page-item ${currentPage === number - 1 ? 'active' : ''}`}
                    >
                        {number}
                    </button>
                ))}
                <button
                    onClick={() => onPageChange(pageCount - 1)}
                    disabled={currentPage === pageCount - 1}
                >
                    Last
                </button>
            </nav> */}

            <div className="blog-pagination mb-5 pb-5">
                <nav>
                    <ul className='pagination justify-content-center'>
                        {/* <li className={`page-item${currentPage === 0 ? ' disabled' : ''}`} onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 0}>
                            <div className="page-link page-prev">
                                <i className="fa-solid fa-arrow-left me-1"></i> PREV
                            </div>
                        </li> */}
                        <li className={`page-item${currentPage === 0 ? ' disabled' : ''}`} onClick={() => handlePageChange((currentPage - 1), (currentPage === 0 ? 'disabled' : 'enabled'))} disabled={currentPage === 0}>
                            <div className="page-link page-prev">
                                <i className="fa-solid fa-arrow-left me-1"></i> PREV
                            </div>
                        </li>

                        {/* {pageNumbers.map(number => (
                            <li className={`page-item ${currentPage === number - 1 ? 'active' : ''} mx-2`} onClick={() => onPageChange(number - 1)} key={number}>
                                <div className="page-link">{number}</div>
                            </li>
                        ))} */}
                        {pageNumbers.map(number => (
                            <li className={`page-item ${currentPage === number - 1 ? 'active' : ''} mx-2`} onClick={() => handlePageChange((number - 1), 'enabled')} key={number}>
                                <div className="page-link">{number}</div>
                            </li>
                        ))}

                        {/* <li className={`page-item${currentPage === (pageCount - 1) ? ' disabled' : ''}`} onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === pageCount - 1}>
                            <div className="page-link page-next">
                                NEXT <i className="fa-solid fa-arrow-right ms-1"></i>
                            </div>
                        </li> */}
                        <li className={`page-item${currentPage === (pageCount - 1) ? ' disabled' : ''}`} onClick={() => handlePageChange((currentPage + 1), (currentPage === (pageCount - 1) ? 'disabled' : 'enabled'))} disabled={currentPage === pageCount - 1}>
                            <div className="page-link page-next">
                                NEXT <i className="fa-solid fa-arrow-right ms-1"></i>
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        </>
    )
}

export default Pagination