import React, { useEffect, useState } from 'react'
import axios from 'axios'

const OurService2 = () => {
    const [services, setServices] = useState([]);

    useEffect(() => {
        if (services.length === 0)
            fetchServices(4, 0);
    }, []);

    const fetchServices = (limit, offset) => {

        var json = JSON.stringify({ "limit": limit, "offset": offset });

        const url = `${process.env.REACT_APP_BASE_API}/api.php?action=get_services`;

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                //setActiveService(response.data.services);
                setServices(response.data.services);
                // console.log(response.data.services);
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    }

    const getWords = (str) => {
        var wordLimit = 50;
        return str.split(/\s+/).slice(0, wordLimit).join(" ");
    }

    const CreateServiceLayout = () => {
        return services.map(({ service_title, service_description, service_images }, index) => (
            index % 2 === 0 ?
                (
                    <div className="container mt-5" key={index} data-aos="fade-right">
                        <div className="app-sec">
                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <div className="col-md-12">
                                        <div className="heading aos aos-init aos-animate" data-aos="fade-up">
                                            <h2>{service_title}</h2>
                                            {/* <p>{getWords(service_description)}</p> */}                                            
                                            <p className='package-widget' dangerouslySetInnerHTML={{__html: service_description?.replaceAll("margin: 0px; padding: 0px 0px 8px 1.3em; list-style: none;", "margin: 0px; padding: 0px 0px 8px 1.5em; list-style: none; display: inherit;")}}></p>
                                            {/* <h6>Scan the QR code to get the app now</h6> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12">
                                    <div className="aos aos-init aos-animate" data-aos="fade-up">
                                        <img src={`${process.env.REACT_APP_BASE_API}${service_images}`} className="img-fluid" alt="img" loading='lazy' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                :
                (
                    <div className="container mt-5" key={index} data-aos="fade-left">
                        <div className="app-sec">
                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <div className="aos aos-init aos-animate" data-aos="fade-up">
                                        <img src={`${process.env.REACT_APP_BASE_API}${service_images}`} className="img-fluid" alt="img" loading='lazy' />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12">
                                    <div className="col-md-12">
                                        <div className="heading aos aos-init aos-animate" data-aos="fade-up">
                                            <h2 className='mt-4 mt-md-0'>{service_title}</h2>
                                            {/* <p>{getWords(service_description)}</p> */}
                                            <p className='package-widget' dangerouslySetInnerHTML={{__html: service_description?.replaceAll("margin: 0px; padding: 0px 0px 8px 1.3em; list-style: none;", "margin: 0px; padding: 0px 0px 8px 1.5em; list-style: none; display: inherit;")}}></p>
                                            {/* <h6>Scan the QR code to get the app now</h6> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
        ))
    }

    return (
        <section className="app-section pb-2">
            <div className="row">
                <div className="col-md-12 text-center">
                    <div className="section-heading section-heading-eight aos aos-init aos-animate" data-aos="fade-up">
                        <img src="/assets/img/logo.png" alt="logo" loading='lazy' />
                        <h2>Our Services</h2>
                        <p className='w-75 mx-auto'>We have an extensive selection of services available in one spot, including high-quality SEO-related services as well as a variety of services to help you develop high-quality backlinks.</p>
                    </div>
                </div>
            </div>

            <CreateServiceLayout />
        </section>
    )
}

export default OurService2