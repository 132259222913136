import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom'

import { getCookie, base64_decode, eraseCookie } from '../../func';

const Header = () => {
  const navigate = useNavigate();

  const [activeServices, setActiveServices] = useState([]);
  const [activeCampaigns, setActiveCampaign] = useState([]);

  const [username, setUsername] = useState('');
  // const [email, setEmail] = useState('');
  // const [role, setRole] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    //console.log(checkRole(getCookie("token")));
    if (getCookie("token") !== undefined && getCookie("token") !== null) {
      //user is already loggedin
      var temptoken = base64_decode(getCookie("token"));
      // console.log(temptoken);

      setUsername(temptoken.split("|")[1]);
      // setEmail(temptoken.split("|")[2]);
      // setRole(temptoken.split("|")[3]);
      setLoggedIn(true);
    }
    fetchServices();
  }, [])

  const fetchServices = () => {
    var json = '';

    if (getCookie("token") !== null)
      json = JSON.stringify({ "token": getCookie("token"), "limit": 10000, "offset": 0 });
    else
      json = JSON.stringify({ "limit": 10000, "offset": 0 });

    const url = `${process.env.REACT_APP_BASE_API}/api.php?action=get_service_and_campaign_ids`;

    axios.post(url, JSON.stringify({ params: json }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then((response) => {
      if (response.data.success === "true") {
        // console.log(response.data);
        setActiveCampaign(response.data.campaigns);
        setActiveServices(response.data.services);
      }
    }).catch((error) => {
      console.error(`Error: ${error}`);
    });
  }

  const logout = () => {
    eraseCookie("token");

    setTimeout(() => {
      navigate(`/login`, { replace: true });
    }, 500)
  }

  // const CreateSeprator = () => {
  //   return <div className='row d-none d-sm-block'>
  //     <div className='col-12'>
  //       <div className='my-1 mx-2' style={{ height: "2px", backgroundColor: "#eee" }}></div>
  //     </div>
  //   </div>
  // }

  return (
    <>
      <header className="header header-one">
        <div className="container">
          <nav className="navbar navbar-expand-lg header-nav">
            <div className="navbar-header">
              <div id="mobile_btn" style={{zIndex: 2}}>
                <span className="bar-icon">
                  <span />
                  <span />
                  <span />
                </span>
              </div>
              <Link to={`/`} className="navbar-brand logo">
                <img src="/assets/img/logo.png" className="img-fluid" alt="Logo" loading='lazy'/>
              </Link>
              <Link to={`/`} className="navbar-brand logo-small" style={{zIndex: 1}}>
                {/* <img src="/assets/img/logo-small.png" className="img-fluid" alt="Logo" loading='lazy' /> */}
                <img src="/logo192.png" className="img-fluid p-2" alt="Logo" loading='lazy' />
              </Link>
            </div>
            <div className="main-menu-wrapper">
              <div className="menu-header">
                <Link to={`/`} className="menu-logo my-3">
                  <img src="/assets/img/logo.png" className="img-fluid" alt="Logo" loading='lazy' />
                </Link>
                <div id="menu_close" className="menu-close">
                  <i className="fas fa-times" />
                </div>
              </div>
              <ul className="main-nav">

                <li>
                  <Link to={"/"}>Home</Link>
                </li>

                <li className="has-submenu">
                  <Link to={"#"}>Services <i className="fas fa-chevron-down" /></Link>
                  <ul className="submenu">
                    {activeServices.map(({ service_id, service_slug, service_title }, index) => (
                      <li key={index}><Link to={`/service/${service_slug}`}>{service_title}</Link></li>
                    ))}
                    {/* <li><Link to={"/service/web2.0"}>Web 2.0</Link></li>
                    <li><Link to={"/service/backlinks"}>Backlinks</Link></li>
                    <li><Link to={"/service/compaigns"}>Compaigns</Link></li> */}
                  </ul>
                </li>

                <li className="has-submenu">
                  <Link to={"#"}>Campaigns <i className="fas fa-chevron-down" /></Link>
                  <ul className="submenu">
                    {activeCampaigns.map(({ campaign_id, campaign_slug, campaign_title }, index) => (
                      <li key={index}><Link to={`/campaign/${campaign_slug}`}>{campaign_title}</Link></li>
                    ))}
                  </ul>
                </li>

                <li>
                  <Link to={"#"}>About Us</Link>
                </li>
              </ul>
            </div>

            {!loggedIn ?
              <ul className="nav header-navbar-rht">
                <li className="nav-item">
                  {/* <p className='fw-semibold mb-0'>Login</p> */}
                  <Link className="nav-link header-login" to={"/login"}>
                    {/* <i className="feather-phone me-2" /> */}
                    Login
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link header-login" to={"/register"}>
                    {/* <i className="feather-phone me-2" /> */}
                    Register
                  </Link>
                </li>
              </ul>
              :
              <ul className="nav header-navbar-rht noti-pop-detail" style={{ zIndex: 2 }}>
                <li className="nav-item dropdown has-arrow account-item">
                  <div className="dropdown-toggle nav-link" data-bs-toggle="dropdown">
                    <div className="user-infos">
                      <span className="user-img">
                        {/* <img src="/assets/img/profiles/avatar-02.jpg" className="rounded-circle" alt="User" /> */}
                        <img src="/assets/img/icons/myself.svg" className="rounded-circle" alt="User" style={{ width: "25px", height: "25px" }} />
                      </span>
                      <div className="user-info ms-1">
                        <h6 style={{ marginBottom: "0px" }}>{username}</h6>
                        {/* <p>{email}</p> */}
                      </div>
                    </div>
                  </div>
                  <div className="dropdown-menu dropdown-menu-end emp">
                    {/* <a className="dropdown-item" href="customer-profile.html">
                    <i className="feather-user me-2" /> Profile
                  </a> */}
                    {/* <a className="dropdown-item" href="index.html">
                      <i className="feather-log-out me-2" /> Logout
                    </a> */}
                    <Link className="dropdown-item" to={"/dashboard"}>
                      <i className="feather-grid me-2" /> Dashboard
                    </Link>
                    <button className="dropdown-item" onClick={() => logout()}><i className="feather-log-out me-2" /> Logout</button>
                  </div>
                </li>
              </ul>
            }
          </nav>
        </div>
      </header>
    </>
  )
}

export default Header