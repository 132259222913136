import React from 'react'
import Header from '../../components/Header';
import Hero from '../../components/Hero';
import Features from '../../components/Features';
import Statistics from '../../components/Statistics';
import GrowingSection from '../../components/GrowingSection';
import AttractiveOffers from '../admin/AttractiveOffers';
import OurServices from '../../components/OurService';
import OurService2 from '../../components/OurServices2';
import ReviewsSection from '../../components/ReviewsSection';
import Footer from '../../components/Footer';
import ForEveryone from '../../components/ForEveryone';
import MoneyBackPromise from '../../components/MoneyBackPromise';

const Home = () => {
    return (
        <>
            <Header />

            <Hero />

            {/* <ForEveryone /> */}

            <Features />

            <ForEveryone />

            <Statistics />

            <GrowingSection index={1}/>

            <AttractiveOffers />

            {/* <OurServices /> */}

            <OurService2 />

            <MoneyBackPromise />

            <GrowingSection index={2}/>

            <ReviewsSection showslider={true} />

            <Footer />

            <div className="mouse-cursor cursor-outer" style={{ visibility: 'visible' }} />
            <div className="mouse-cursor cursor-inner" style={{ visibility: 'visible' }} />
        </>
    )
}

export default Home