// import logo from './logo.svg';
import './App.css';
import { Routes, Route } from "react-router-dom";
import Home from './containers/pages/Home';
import Service from "./containers/pages/Service";
import Details from "./containers/pages/Details";
import Login from "./containers/pages/Login";
import Register from "./containers/pages/Register";
import PasswordRecovery from "./containers/pages/PasswordRecovery";
import ResetPassword from "./containers/pages/ResetPassword";
import VerifyAccount from "./containers/pages/VerifyAccount";
import Dashboard from "./containers/pages/admin/Dashboard";
import AddService from "./containers/pages/admin/AddService";
import UpdateService from "./containers/pages/admin/UpdateService";
import AddPackage from './containers/pages/admin/AddPackage';
import UpdatePackage from './containers/pages/admin/UpdatePackage';
import OrderNow from './containers/pages/OrderNow';
import Orders from './containers/pages/admin/Orders';
import AddCampaign from './containers/pages/admin/AddCampaign';
import UpdateCampaign from './containers/pages/admin/UpdateCampaign';
import Campaign from './containers/pages/Campaign';
import Services from './containers/pages/admin/Services';
import Campaigns from './containers/pages/admin/Campaigns';
import OrderDetails from './containers/pages/admin/OrderDetails';
import PaymentSettings from './containers/pages/PaymentSettings';
import AddPressReleaseService from './containers/pages/admin/AddPressReleaseService';
import UpdatePressReleaseService from './containers/pages/admin/UpdatePressReleaseService';
import AddPressReleasePackage from './containers/pages/admin/AddPressReleasePackage';
import UpdatePressReleasePackage from './containers/pages/admin/UpdatePressReleasePackage';
import PressRelease from './containers/pages/PressRelease';
import RankHigh from './containers/pages/RankHigh';
import PromoteBrand from './containers/pages/PromoteBrand';
import ObtainQualityLeads from './containers/pages/ObtainQualityLeads';
import BoostRevenue from './containers/pages/BoostRevenue';
import SocialMediaVisibility from './containers/pages/SocialMediaVisibility';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/service/:slug" element={<Service />} />
      <Route path="/details/:slug" element={<Details />} />
      <Route path="/campaign/:slug" element={<Campaign />} />

      <Route path="/order_now" element={<OrderNow />} />
      <Route path="/order_now/:slug" element={<OrderNow />} />

      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/password_recovery" element={<PasswordRecovery />} />

      <Route path="/reset_password" element={<ResetPassword />} />
      <Route path="/reset_password/:token" element={<ResetPassword />} />

      <Route path="/verify_account" element={<VerifyAccount />} />
      <Route path="/verify_account/:token" element={<VerifyAccount />} />

      <Route path="/dashboard" element={<Dashboard />} />

      <Route path="/services" element={<Services />} />
      <Route path="/add_service" element={<AddService />} />
      <Route path="/update_service/:slug" element={<UpdateService />} />

      <Route path="/add_package" element={<AddPackage />} />
      <Route path="/update_package/:id" element={<UpdatePackage />} />

      <Route path="/campaigns" element={<Campaigns />} />
      <Route path="/add_campaign" element={<AddCampaign />} />
      <Route path="/update_campaign/:slug" element={<UpdateCampaign />} />

      <Route path="/orders" element={<Orders />} />
      <Route path="/order_details/:type/:id" element={<OrderDetails />} />

      <Route path="/payment_settings" element={<PaymentSettings />} />

      <Route path="/pressrelease" element={<PressRelease />} />

      <Route path="/rank_high" element={<RankHigh />} />

      <Route path="/promote_brand" element={<PromoteBrand />} />

      <Route path="/obtain_quality_leads" element={<ObtainQualityLeads />} />

      <Route path="/boost_revenue" element={<BoostRevenue />} />

      <Route path="/social_media_visibility" element={<SocialMediaVisibility />} />

      <Route path="/add_pressrelease_service" element={<AddPressReleaseService />} />
      <Route path="/update_pressrelease_service/:slug" element={<UpdatePressReleaseService />} />

      <Route path="/add_pressrelease_package" element={<AddPressReleasePackage />} />
      <Route path="/update_pressrelease_package/:id" element={<UpdatePressReleasePackage />} />

    </Routes>
  );
}

export default App;
