import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Badge from '../Badge'
import Alert from '../Alert';

import { getCookie, checkRole, base64_encode } from '../../func';
import Pagination from '../Pagination';
import { Link } from 'react-router-dom';

const CampaignOrders = ({ orders, showOrderDetails, TotalOrders, ItemLimit }) => {
    const [temporders, settempOrders] = useState([...orders]);
    const [editing, setEditing] = useState(0);
    const [statusUpdate, setStatusUpdate] = useState({});

    const [currentPage, setCurrentPage] = useState(0);

    const [alert, setAlert] = useState({
        type: '',
        message: '',
        show: false
    });

    useEffect(() => {
        settempOrders([...orders]);
    }, [orders])

    // useEffect(() => {
    //     const indexOfLastRecord = currentPage * ItemLimit;
    //     console.log("Campaign order currentPage: ", currentPage);
    //     console.log("Campaign order indexOfLastRecord: ", indexOfLastRecord);
    //     fetchOrders(indexOfLastRecord, ItemLimit);
    // }, [currentPage, ItemLimit]);

    // useEffect(() => {
    //     const indexOfLastRecord = currentPage * ItemLimit;
    //     console.log("Normal order currentPage: ", currentPage);
    //     console.log("Normal order indexOfLastRecord: ", indexOfLastRecord);
    // }, [currentPage, ItemLimit]);

    const fetchOrders = (offset, limit) => {
        const json = JSON.stringify({ "token": getCookie("token"), "limit": limit, "offset": offset, type: "campaign" });
        const url = `${process.env.REACT_APP_BASE_API}/api.php?action=get_orders`;

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                // console.log(response.data.campaign_orders)
                settempOrders([...response.data.campaign_orders]);
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    }

    const handleMessage = (params) => {
        setAlert({ type: params.type, message: params.message, show: true });

        setTimeout(() => {
            setAlert({ type: '', message: '', show: false });
        }, 3000);
    }

    const UpdateStatus = () => {
        if (typeof statusUpdate.status !== "undefined") {
            const json = JSON.stringify({ "token": getCookie("token"), "order_id": statusUpdate.id, "status": statusUpdate.status, "type": "campaign" });
            const url = `${process.env.REACT_APP_BASE_API}/api.php?action=update_order`;

            axios.post(url, JSON.stringify({ params: json }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                if (response.data.success === "true") {
                    handleMessage({ type: 'success', message: response.data.message, show: true });
                    var tempOrders = [...temporders];
                    tempOrders.filter(order => order.campaign_id === statusUpdate.id)[0]["order_status"] = statusUpdate.status;
                    settempOrders([...tempOrders]);
                }
                else {
                    handleMessage({ type: 'danger', message: response.data.message, show: true });
                }
            }).catch((error) => {
                console.error(`Error: ${error}`);
                handleMessage({ type: 'danger', message: "Unable to update campaign order status, please try again later!", show: true });
            });
        }
        setEditing(0);
        setStatusUpdate({});
    }

    const EnableEdit = (order_id) => {
        if (editing !== order_id) {
            setStatusUpdate({});
            setEditing(order_id);
        }
        else {
            setEditing(order_id);
        }
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        const indexOfLastRecord = pageNumber * ItemLimit;
        fetchOrders(indexOfLastRecord, ItemLimit);
    };

    // // Calculate the indices for slicing the data array
    // const indexOfLastRecord = (currentPage + 1) * itemsPerPage;
    // const indexOfFirstRecord = indexOfLastRecord - itemsPerPage;
    // const currentItems = data.slice(indexOfFirstRecord, indexOfLastRecord);
    const pageCount = Math.ceil(parseInt(TotalOrders) / ItemLimit);

    const GenerateRecordNumber = (index) => {
        // return index + 1;
        return (ItemLimit * currentPage) + (index + 1);
    }

    return (
        <>
            <Alert type={alert.type} message={alert.message} show={alert.show} />
            <div className="widget-title">
                <h4>Campaign Orders</h4>
            </div>
            <div className={`table-responsive${TotalOrders <= ItemLimit ? ' mb-5' : ''}`}>
                <table className="table mb-0 custom-table">
                    <thead className="thead-light">
                        <tr>
                            <th>#</th>
                            <th>Details</th>
                            <th>Amount</th>
                            <th>Date</th>
                            <th>Payment Method</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            temporders.length > 0 ?
                                temporders.map((order, index) => (
                                    <tr key={index}>
                                        <td className='text-black'>{GenerateRecordNumber(index)}</td>
                                        <td className='fw-bold'>{order.campaign_title}</td>
                                        <td className="text-light-success fw-bold">${order.order_price}</td>
                                        <td className="text-body">{order.order_date}</td>
                                        <td className="fw-bold" style={{ textTransform: "capitalize", color: "#326cfb" }}>{order.payment_method}</td>
                                        <td>
                                            {
                                                editing === order.order_id
                                                    ?
                                                    (
                                                        <div className="form-group mb-1">
                                                            <select className="select form-control" value={(typeof statusUpdate.status !== 'undefined' ? statusUpdate.status : order.order_status)} onChange={(e) => { setStatusUpdate({ "id": order.campaign_id, "status": e.target.value }) }}>
                                                                <option value="pending">Pending</option>
                                                                <option value="completed">Completed</option>
                                                                <option value="refunded">Refunded</option>
                                                            </select>
                                                        </div>
                                                    )
                                                    :
                                                    (<Badge status={order.order_status} />)
                                            }
                                        </td>
                                        {(getCookie("token") !== undefined && getCookie("token") !== null && checkRole(getCookie("token")) === "admin")
                                            ?
                                            (
                                                <td>
                                                    {
                                                        editing === order.order_id
                                                            ?
                                                            (
                                                                <button className='btn btn-success' onClick={() => { UpdateStatus() }}>
                                                                    <i className="feather-check"></i>
                                                                </button>
                                                            )
                                                            :
                                                            (
                                                                <button className='btn btn-sm btn-primary' onClick={() => { EnableEdit(order.order_id) }}>
                                                                    <i className="feather-edit"></i>
                                                                </button>
                                                            )
                                                    }
                                                    {
                                                        order.order_details !== "" &&
                                                        (
                                                            <>
                                                                {/* <button type="button" className="ms-2 btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#detailsModal" onClick={() => { showOrderDetails(order.order_details) }}>
                                                                    <i className="feather-more-vertical"></i>
                                                                </button> */}
                                                                <Link to={`/order_details/campaign/${base64_encode(order.order_id.toString())}`} className="ms-2 btn btn-sm btn-primary" target='_blank'><i className="feather-more-vertical"></i></Link>
                                                            </>
                                                        )
                                                    }
                                                </td>
                                            )
                                            :
                                            (
                                                <td>
                                                    {
                                                        order.order_details !== "" &&
                                                        (
                                                            <>
                                                                {/* <button type="button" className="ms-2 btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#detailsModal" onClick={() => { showOrderDetails(order.order_details) }}>
                                                                    <i className="feather-more-vertical"></i>
                                                                </button> */}
                                                                <Link to={`/order_details/campaign/${base64_encode(order.order_id.toString())}`} className="ms-2 btn btn-sm btn-primary" target='_blank'><i className="feather-more-vertical"></i></Link>
                                                            </>
                                                        )
                                                    }
                                                </td>
                                            )
                                        }

                                    </tr>
                                ))
                                :
                                <tr>
                                    <td colSpan={6} className='text-center'>
                                        <span>Currently you have not received any order!</span>
                                    </td>
                                </tr>
                        }
                    </tbody>
                </table>
            </div>

            {
                TotalOrders > ItemLimit && (<Pagination
                    pageCount={pageCount}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                />)
            }
        </>
    )
}

export default CampaignOrders