import React from 'react'

const SitesSlider = () => {
    return (
        <div id="company-slider" className="owl-carousel owl-theme testimonial-slider owl-loaded owl-drag my-5">
            <div className="owl-stage-outer">
                <div className="owl-stage">
                    <div className="owl-item">
                        <div className="company-logos d-flex justify-content-center align-items-center" style={{minHeight: "80px"}}>
                            <img src="assets/img/pr/yahoo.png" className='d-flex w-75' alt="Logo" loading='lazy' />
                        </div>
                    </div>
                    <div className="owl-item">
                        <div className="company-logos d-flex justify-content-center align-items-center" style={{minHeight: "80px"}}>
                            <img src="assets/img/pr/MSN.png" className='d-flex w-75' alt="Logo" loading='lazy' />
                        </div>
                    </div>
                    <div className="owl-item">
                        <div className="company-logos d-flex justify-content-center align-items-center" style={{minHeight: "80px"}}>
                            <img src="assets/img/pr/Business-Insider.png" className='d-flex w-75' alt="Logo" loading='lazy' />
                        </div>
                    </div>
                    <div className="owl-item">
                        <div className="company-logos d-flex justify-content-center align-items-center" style={{minHeight: "80px"}}>
                            <img src="assets/img/pr/Publish-On-Benzinga.png" className='d-flex w-75' alt="Logo" loading='lazy' />
                        </div>
                    </div>
                    <div className="owl-item">
                        <div className="company-logos d-flex justify-content-center align-items-center" style={{minHeight: "80px"}}>
                            <img src="assets/img/pr/Publish-On-Newsmax.png" className='d-flex w-75' alt="Logo" loading='lazy' />
                        </div>
                    </div>
                    <div className="owl-item">
                        <div className="company-logos d-flex justify-content-center align-items-center" style={{minHeight: "80px"}}>
                            <img src="assets/img/pr/Publish-On-Street-Insider.png" className='d-flex w-75' alt="Logo" loading='lazy' />
                        </div>
                    </div>
                    <div className="owl-item">
                        <div className="company-logos d-flex justify-content-center align-items-center" style={{minHeight: "80px"}}>
                            <img src="assets/img/pr/Publish-On-AsiaOne.png" className='d-flex w-75' alt="Logo" loading='lazy' />
                        </div>
                    </div>
                    <div className="owl-item">
                        <div className="company-logos d-flex justify-content-center align-items-center" style={{minHeight: "80px"}}>
                            <img src="assets/img/pr/fox40.png" className='d-flex w-75' alt="Logo" loading='lazy' />
                        </div>
                    </div>
                    <div className="owl-item">
                        <div className="company-logos d-flex justify-content-center align-items-center" style={{minHeight: "80px"}}>
                            <img src="assets/img/pr/Barchart.png" className='d-flex w-75' alt="Logo" loading='lazy' />
                        </div>
                    </div>
                    <div className="owl-item">
                        <div className="company-logos d-flex justify-content-center align-items-center" style={{minHeight: "80px"}}>
                            <img src="assets/img/pr/Digital-Journal-Logo.png" className='d-flex w-75' alt="Logo" loading='lazy' />
                        </div>
                    </div>
                    <div className="owl-item">
                        <div className="company-logos d-flex justify-content-center align-items-center" style={{minHeight: "80px"}}>
                            <img src="assets/img/pr/Minyanville.png" className='d-flex w-75' alt="Logo" loading='lazy' />
                        </div>
                    </div>
                    <div className="owl-item">
                        <div className="company-logos d-flex justify-content-center align-items-center" style={{minHeight: "80px"}}>
                            <img src="assets/img/pr/oppenpr.png" className='d-flex w-75' alt="Logo" loading='lazy' />
                        </div>
                    </div>
                    <div className="owl-item">
                        <div className="company-logos d-flex justify-content-center align-items-center" style={{minHeight: "80px"}}>
                            <img src="assets/img/pr/the-chronicle-journal-PR-publishing.png" className='d-flex w-75' alt="Logo" loading='lazy' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SitesSlider