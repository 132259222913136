import React, { useEffect, useState } from 'react'
import axios from "axios";
import { Link, useNavigate, useParams } from 'react-router-dom';

import Header from '../../../components/Header';
import SideBar from '../../../components/SideBar';

import Alert from '../../../components/Alert';

import Editor from 'react-simple-wysiwyg';

import Cookies from 'universal-cookie';

import { getCookie, eraseCookie, checkRole } from '../../../func';

const cookies = new Cookies();

const UpdateService = () => {
  const navigate = useNavigate();
  let params = useParams();

  const [packages, setPackages] = useState([]);

  const [previousData, setPreviousData] = useState({});
  const [title, setTitle] = useState('');
  const [subtitle, setSubTitle] = useState('');
  const [description, setDescription] = useState('');
  const [images, setImages] = useState([]);
  const [deliveryTime, setDeliveryTime] = useState('');
  const [deliveryDuration, setDeliveryDuration] = useState('');
  // const [includes, setIncludes] = useState([{ include: "" }]);
  const [includes, setIncludes] = useState([]);
  const [formjson, setFormJson] = useState([]);

  const [alert, setAlert] = useState({
    type: '',
    message: '',
    show: false
  });

  const [cookieName, setCookieName] = useState('');

  useEffect(() => {
    if (getCookie("token") !== undefined && getCookie("token") !== null) {
      if (checkRole(getCookie("token")) !== "admin")
        navigate(`/dashboard`, { replace: true });
      else {
        if (params.slug !== undefined && params.slug !== null && params.slug.toString().trim() !== "")
          fetchservice(params.slug);
        else {
          navigate(`/dashboard`, { replace: true });
        }
      }
    }
    else {
      navigate(`/login`, { replace: true });
    }
  }, [])

  useEffect(() => {
    if (previousData.id !== undefined && previousData.id !== '') {
      setTitle(previousData.title);
      setSubTitle(previousData.subtitle);
      setDescription(previousData.description);
      setImages([previousData.images]);
      setDeliveryTime(previousData.deliveryTime);
      setDeliveryDuration(previousData.deliveryDuration);
      setIncludes(previousData.includes);

      var temp = "edit-service-" + Math.floor(Date.now() / 1000);
      // console.log("temp: ", temp);
      setCookieName(temp);

      //window["bindFormBuilder"](temp, '[{"type":"text","required":true,"label":"Email","className":"form-control","name":"text-1713955913764-0","access":false,"subtype":"text"},{"type":"text","required":true,"label":"Links","className":"form-control","name":"text-1713955924051-0","access":false,"subtype":"text"},{"type":"text","required":true,"label":"Keywords","className":"form-control","name":"text-1713955931395-0","access":false,"subtype":"text"},{"type":"select","required":false,"label":"Select","className":"form-control","name":"select-1713956022737-0","access":false,"multiple":false,"values":[{"label":"Option 1","value":"option-1","selected":true},{"label":"Option 2","value":"option-2","selected":false},{"label":"Option 3","value":"option-3","selected":false}]},{"type":"textarea","required":false,"label":"Text Area","className":"form-control","name":"textarea-1713956025814-0","access":false,"subtype":"textarea"}]');
      window["bindFormBuilder"](temp, JSON.stringify(previousData.formjson));

      const cookieChangeListener = (cookie, value) => {
        if (cookie.name === temp) {
          // console.log('The cookie ', cookie, ' changed to ', cookie.value);
          setFormJson((typeof cookie.value !== 'undefined' && cookie.value.length > 0) ? [...cookie.value] : []);
        }
      }

      cookies.addChangeListener(cookieChangeListener);

      return () => {
        cookies.removeChangeListener(cookieChangeListener);
      }
    }
  }, [previousData])

  // useEffect(()=>{
  //   console.log("formjson: ", formjson);
  // }, [formjson])

  const handleMessage = (params) => {
    setAlert({ type: params.type, message: params.message, show: true });

    setTimeout(() => {
      setAlert({ type: '', message: '', show: false });
    }, 3000);
  }

  const changeHandler = (e) => {
    let files = e.target.files;
    //console.log("files: ", files);

    var allFiles = [];
    for (var i = 0; i < files.length; i++) {

      let file = files[i];

      let ext = '';
      switch (file.type) {
        case "image/x-png":
          ext = "png"
          break;
        case "image/png":
          ext = "png"
          break;
        case "image/jpeg":
          ext = "jpg"
          break;
        case "image/webp":
          ext = "webp"
          break;
        default:
          break;
      }

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let fileInfo = {
          name: file.name.replace(`.${ext}`, ''),
          type: file.type,
          extention: ext,
          size: Math.round(file.size / 1000) + ' kB',
          base64: reader.result,
          file: file,
        };

        allFiles.push(fileInfo);

        if (allFiles.length == files.length) {
          // console.log(allFiles);
          //if (this.props.multiple) this.props.onDone(allFiles);
          //else this.props.onDone(allFiles[0]);
          setImages(allFiles);
        }
      }
    }
  }

  const removeImage = (i) => {
    let array = [...images];
    array.splice(i, 1);
    setImages(array);
  }

  const handleAddFeature = () => {
    setIncludes([...includes, { include: "" }]);
  };

  const handleFeatureChange = (event, index) => {
    let { name, value } = event.target;
    let onChangeValue = [...includes];
    onChangeValue[index][name] = value;
    setIncludes(onChangeValue);
  };

  const handleDeleteFeature = (index) => {
    const newArray = [...includes];
    newArray.splice(index, 1);
    setIncludes(newArray);
  };

  const clear = () => {
    setTitle('');
    setSubTitle('');
    setDescription('');
    setImages([]);
    setDeliveryTime('');
    setDeliveryDuration('');
    // setIncludes([{ include: "" }]);
    setIncludes([]);
  }

  const fetchservice = (slug) => {
    const json = JSON.stringify({ "slug": slug });
    const url = `${process.env.REACT_APP_BASE_API}/api.php?action=get_service_details`;

    axios.post(url, JSON.stringify({ params: json }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then((response) => {
      if (response.data.success === "true") {
        var service_details = response.data.service_details[0];
        var serviceTimeDurationParts = service_details.service_delivery_time.split(" ");
        setPreviousData({
          id: service_details.service_id,
          slug: service_details.service_slug,
          title: service_details.service_title,
          subtitle: service_details.service_sub_title,
          description: service_details.service_description,
          images: service_details.service_images,
          deliveryTime: serviceTimeDurationParts[0],
          deliveryDuration: serviceTimeDurationParts[1],
          includes: service_details.service_includes !== "" ? JSON.parse(service_details.service_includes) : [],
          formjson: service_details.service_form !== "" ? JSON.parse(service_details.service_form) : []
        })

        fetchPackages(atob(service_details.service_id));
      }
      else {
        handleMessage({ type: 'danger', message: response.data.message, show: true });

        setTimeout(() => {
          navigate(`/dashboard`, { replace: true });
        }, 3000)
      }
    }).catch((error) => {
      console.error(`Error: ${error}`);
    });
  }

  const fetchPackages = (service_id) => {
    const json = JSON.stringify({ "token": getCookie("token"), "service_id": service_id, "limit": 10000, "offset": 0 });
    const url = `${process.env.REACT_APP_BASE_API}/api.php?action=get_packages`;

    axios.post(url, JSON.stringify({ params: json }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then((response) => {
      if (response.data.success === "true") {
        setPackages(response.data.packages);
      }
      else {
        handleMessage({ type: 'danger', message: response.data.message, show: true });
      }
    }).catch((error) => {
      console.error(`Error: ${error}`);
    });
  }

  const updateservice = () => {
    var x = window["returnFormJSON"]();
    // console.log("x: ", x);
    setFormJson([...JSON.parse(x)]);

    setTimeout(() => {
      var nonEmptyIncludes = includes.filter(value => value.include !== '');
      var includes_string = nonEmptyIncludes.length > 0 ? JSON.stringify(includes) : "";
      if (title.trim() !== "" && subtitle.trim() !== "" && description.trim() !== "" && images.length > 0 && deliveryTime.trim() !== "" && deliveryDuration.trim() !== "") {
        const json = JSON.stringify({ "token": getCookie("token"), "oldtitle": previousData.title, "title": title, "subtitle": subtitle, "description": description, "images": images, "deliverytime": `${deliveryTime.trim()} ${deliveryDuration.trim()}`, "includes": includes_string, "formjson": JSON.stringify(formjson) });
        const url = `${process.env.REACT_APP_BASE_API}/api.php?action=update_service`;

        axios.post(url, JSON.stringify({ params: json }), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }).then((response) => {
          if (response.data.success === "true") {
            //console.log("service added successfully");
            clear();
            eraseCookie(cookieName);
            handleMessage({ type: 'success', message: response.data.message, show: true });
            fetchservice(params.slug);
          }
          else {
            handleMessage({ type: 'danger', message: response.data.message, show: true });
          }
        }).catch((error) => {
          console.error(`Error: ${error}`);
        });
      }
      else {
        if (title.trim() === "" && subtitle.trim() === "" && description.trim() === "" && images.length === 0 && deliveryTime.trim() === "" && deliveryDuration.trim() === "")
          handleMessage({ type: 'danger', message: "Please fill all the required fields", show: true });
        else if (title.trim() === "")
          handleMessage({ type: 'danger', message: "Please provide service title", show: true });
        else if (subtitle.trim() === "")
          handleMessage({ type: 'danger', message: "Please provide service subtitle", show: true });
        else if (description.trim() === "")
          handleMessage({ type: 'danger', message: "Please provide service description", show: true });
        else if (images.length === 0)
          handleMessage({ type: 'danger', message: "Please provide service image", show: true });
        else if (deliveryTime.trim() === "")
          handleMessage({ type: 'danger', message: "Please provide service delivery time", show: true });
        else if (deliveryDuration.trim() === "")
          handleMessage({ type: 'danger', message: "Please provide service duration", show: true });
      }
    }, 1500)
  }

  const AddedPackages = () => {
    return (
      <>
        <h6 className="user-title mt-5">All Packages</h6>
        <div className="table-responsive">
          <table className="table mb-0 custom-table">
            <thead className="thead-light">
              <tr>
                <th>#</th>
                <th>Service</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Average<br />Unique<br />Domains</th>
                <th>Status</th>
                <th>Acion</th>
              </tr>
            </thead>
            <tbody>
              {packages.map((p, index) => (
                <tr key={index}>
                  <td>{atob(p.package_id)}</td>
                  <td>
                    <h2 className="table-avatar">
                      <div className="avatar avatar-m me-2">
                        <img className="avatar-img rounded" src={`${process.env.REACT_APP_BASE_API}${p.package_title_image}`} alt="package img" />
                      </div>
                      <div>
                        <Link to={`/details/${p.package_slug}`} target='_blank'>{p.package_name}</Link><span>{getWords(p.package_details.replace(/<[^>]+>/g, " ").replaceAll("&nbsp;", " "), 5)+"..."}</span>
                      </div>
                    </h2>
                  </td>
                  <td className="text-body">{p.package_price}</td>
                  <td>
                    <div className='d-flex' style={{ justifyContent: "space-between" }}>
                      <b>Min:</b> <span>{p.minimum_quantity}</span>
                    </div>
                    <div className='d-flex' style={{ justifyContent: "space-between" }}>
                      <b>Max:</b> <span>{p.maximum_quantity}</span>
                    </div>
                  </td>
                  <td>{p.average_unique_domains}</td>
                  <td>{p.status === 1 ? <span className="badge-success">Active</span> : <span className="badge-danger">Inactive</span>}</td>
                  <td>
                    {
                      p.status === 1 ? <button className='btn btn-light-danger' onClick={() => activatedeativePackage("0", atob(p.package_id))}>Deactivate</button> : <button className='btn btn-light-success' onClick={() => activatedeativePackage("1", atob(p.package_id))}>Activate</button>
                    }
                    <Link to={`/update_package/${atob(p.package_id)}`} className='btn btn-sm btn-success ms-2' target='_blank'>Edit</Link>
                  </td>
                </tr>
              ))}
              {/* <tr>
                <td>1</td>
                <td>
                  <h2 className="table-avatar">
                    <div className="avatar avatar-m me-2">
                      <img className="avatar-img rounded" src="assets/img/services/service-06.jpg" alt="User Image" />
                    </div>
                    <div>
                      Computer Repair<span><i className="feather-calendar" /> 22 Sep 2023</span>
                    </div>
                  </h2>
                </td>
                <td className="text-body">Description</td>
                <td><span className="badge-success">Active</span></td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </>
    )
  }

  const activatedeativePackage = (action, id) => {
    const json = JSON.stringify({ "token": getCookie("token"), "package_id": id, "action": action });
    const url = `${process.env.REACT_APP_BASE_API}/api.php?action=activate_deactivate_package`;

    axios.post(url, JSON.stringify({ params: json }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then((response) => {
      if (response.data.success === "true") {
        var temp = [...packages];
        temp.filter(s => s.package_id === btoa(id))[0]["status"] = parseInt(action);
        setPackages([...temp]);
        handleMessage({ type: 'success', message: response.data.message, show: true });
      }
      else {
        handleMessage({ type: 'danger', message: response.data.message, show: true });
      }
    }).catch((error) => {
      console.error(`Error: ${error}`);
    });
  }

  const getWords = (str, wordLimit) => {
    // var wordLimit = 50;
    return str.split(/\s+/).slice(0, wordLimit).join(" ");
}

  return (
    <>
      <Header />

      <div className="content">
        <div className="container">
          <div className="row">

            <SideBar />

            <div className="col-lg-9">
              <Alert type={alert.type} message={alert.message} show={alert.show} />
              <div className="widget-title">
                <h4>Update Service</h4>
              </div>
              <h6 className="user-title">General Information</h6>
              <div className="general-info mb-4">
                <div className="row">
                  <div className="col-sm-6 col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">Title <span className="text-danger">*</span></label>
                      <input type="text" className="form-control" placeholder="Enter service title" value={title} onChange={(e) => setTitle(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">Sub-title <span className="text-danger">*</span></label>
                      <input type="text" className="form-control" placeholder="Enter service sub-title" value={subtitle} onChange={(e) => setSubTitle(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12">
                    <div className="form-group">
                      <label className="col-form-label d-block">Description <span className="brief-bio float-end">Brief description for service.</span></label>
                      {/* <textarea id="txtDescription" className="form-control" rows={5} placeholder="Add a brief description of service....." value={description} onChange={(e) => setDescription(e.target.value)} /> */}
                      <Editor value={description} onChange={(e) => setDescription(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12">
                    <h6 className="user-title">Service Image</h6>
                    <div className="service-image-box mb-4">
                      {
                        (images.length > 0)
                        &&
                        <img src={images[0].hasOwnProperty('base64') ? images[0]["base64"] : `${process.env.REACT_APP_BASE_API}${images}`} alt="service img" style={{ width: "300px", height: "250px", borderRadius: "8px" }} />
                      }
                      {/* {(images !== "" && images.length > 0 && images[0].hasOwnProperty('base64')) ?
                        <div className="service-image mb-2">
                          <img src={images[0]["base64"]} alt="user" style={{ width: "300px", height: "250px", borderRadius: "8px" }} />
                          <img src={images[0]["base64"].includes(";base64,") ? images[0]["base64"] : `${process.env.REACT_APP_BASE_API}/${images}`} alt="user" style={{ width: "300px", height: "250px", borderRadius: "8px" }} />
                        </div>
                        :
                        <div className="service-image mb-2">
                          <img src={images[0]["base64"]} alt="user" style={{ width: "300px", height: "250px", borderRadius: "8px" }} />
                          <img src={images !== "" && `${process.env.REACT_APP_BASE_API}/${images}`} alt="user" style={{ width: "300px", height: "250px", borderRadius: "8px" }} />
                        </div>
                      } */}
                      <div className="pro-info">
                        <div className="d-flex">
                          <div className="img-upload">
                            <i className="feather-upload-cloud me-1" />Upload
                            <input type="file" onChange={changeHandler} accept="image/x-png,image/gif,image/jpeg" />
                          </div>
                          <button className="btn btn-remove" onClick={() => removeImage(0)}>Remove</button>
                        </div>
                        <p>*image size should be at least 640px in width and 260px in height,and less then 500kb. Allowed files .png and .jpg.</p>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-6 col-md-6 row pe-0'>
                    <div className="col-sm-6 col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Delivery Time <span className="text-danger">*</span></label>
                        <input type="number" className="form-control" placeholder="Enter delivery time" min={1} max={1000} value={deliveryTime} onChange={(e) => setDeliveryTime(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 pe-0">
                      <div className="form-group">
                        <label className="col-form-label">Duration <span className="text-danger">*</span></label>
                        <select className="form-control-select" value={deliveryDuration} onChange={(e) => setDeliveryDuration(e.target.value)}>
                          <option value="">Select Duration</option>
                          <option value="day">Day</option>
                          <option value="week">Week</option>
                          <option value="month">Month</option>
                          <option value="year">Year</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 d-flex align-items-center mb-3">
                    <button className='btnaddfeature me-2' onClick={() => handleAddFeature()}>+</button>
                    <h6 className="mb-0">Add including features</h6>
                  </div>
                  {includes.length > 0 && includes.map((f, index) => (
                    <div className="row col-12" key={index}>
                      <div className="col-12 col-sm-9">
                        <div className="form-group">
                          {/* <label className="col-form-label"></label> */}
                          <input type="text" name="include" className="form-control" placeholder="Enter feature heading" value={f.include} onChange={(event) => handleFeatureChange(event, index)} />
                        </div>
                      </div>
                      <div className="col-12 col-sm-3 d-flex align-items-center justify-content-end mb-3">
                        {includes.length >= 1 && (
                          <button className="btnremovefeature me-2" onClick={() => handleDeleteFeature(index)}>Delete</button>
                        )}
                        {/* {index === includes.length - 1 && (
                                                    <button className="btnaddfeature" onClick={() => handleAddFeature()}>Add</button>
                                                )} */}
                      </div>
                    </div>
                  ))}
                </div>

                <div className="row">
                  {/* <div className="bg-gray-100 p-4">
                                        <div className="bg-white p-4 rounded-md shadow-md">
                                            <div className="mb-4">
                                                Create form to accept required data for this service.
                                            </div>
                                            <div id="fb-editor"/>
                                        </div>
                                    </div> */}
                  <div className="my-4">
                    <h6 className="user-title m-0">Create form to accept required data for this service.</h6>
                  </div>
                  <div id="fb-editor" className="mb-4" />
                </div>

              </div>
              <div className="acc-submit">
                <button className="btn btn-secondary" onClick={() => clear()}>Cancel</button>
                <button className="btn btn-primary" onClick={() => updateservice()}>Update Changes</button>
              </div>

              {packages.length > 0 && <AddedPackages />}
            </div>

          </div>
        </div>
      </div>

      <div className="mouse-cursor cursor-outer" style={{ visibility: 'visible' }} />
      <div className="mouse-cursor cursor-inner" style={{ visibility: 'visible' }} />
    </>
  )
}

export default UpdateService