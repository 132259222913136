import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useNavigate, useParams, Link } from 'react-router-dom';

import Header from '../../../components/Header';
import SideBar from '../../../components/SideBar';

import { getCookie, base64_decode, returnCipherToText } from '../../../func';
import Badge from '../../../components/Badge';

import { formatEther, parseEther, toUtf8String } from "ethers";

const OrderDetails = () => {
    const navigate = useNavigate();
    let params = useParams();

    const [order, setOrder] = useState({})
    const [selectedExtras, setSelectedExtras] = useState([]);
    const [selectedOrderDetails, setSelectedOrderDetails] = useState([]);

    const [selectedPackageServices, setSelectedPackageServices] = useState([]);

    useEffect(() => {
        if (getCookie("token") === undefined || getCookie("token") === null) {
            navigate(`/login`, { replace: true });
        }
        else {
            if (params.type !== undefined && params.type !== null && params.type.toString().trim() !== "" && params.id !== undefined && params.id !== null && params.id.toString().trim() !== "") {
                fetchOrderDetails(params.id, params.type);
            }
            else {
                navigate(`/orders`, { replace: true });
            }
        }
    }, [])

    // useEffect(() => {
    //     if (typeof order.PaymentResponse !== "undefined") {
    //         console.log("PaymentResponse", order.PaymentResponse);
    //         // console.log("data", returnCipherToText(toUtf8String(order.PaymentResponse.data)));
    //         // console.log("data", new TextDecoder().decode(order.PaymentResponse.data))
    //         console.log("result: ", getResultsFromResponse(order.PaymentResponse.chainId, order.PaymentResponse.hash, order.PaymentResponse.value));
    //     }
    // }, [order])

    const fetchOrderDetails = (order_id, type) => {
        const json = JSON.stringify({ "token": getCookie("token"), "order_id": base64_decode(order_id), "type": type });
        const url = `${process.env.REACT_APP_BASE_API}/api.php?action=get_order_details`;

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                if (type === "campaign") {
                    // console.log("data:", response.data.campaign_orders);
                    // setSelectedOrderDetails(response.data.campaign_orders);
                    var tempDetails = JSON.parse(response.data.campaign_orders[0].order_details); //JSON.parse(order_details);
                    setSelectedExtras(tempDetails.hasOwnProperty("extras") ? [...tempDetails["extras"]] : []);
                    setSelectedOrderDetails(tempDetails.hasOwnProperty("orderDetails") ? [...tempDetails["orderDetails"]] : []);
                    setSelectedPackageServices(tempDetails.hasOwnProperty("selectedCampaign") ? [...tempDetails["selectedCampaign"]] : []);

                    setOrder({
                        Title: response.data.campaign_orders[0].campaign_title,
                        Amount: response.data.campaign_orders[0].order_price,
                        Date: response.data.campaign_orders[0].order_date,
                        Method: response.data.campaign_orders[0].payment_method,
                        Status: response.data.campaign_orders[0].order_status,
                        PaymentStatus: response.data.campaign_orders[0].payment_status,
                        PaymentResponse: JSON.parse(atob(atob(response.data.campaign_orders[0].payment_response)))
                    })
                }
                else if (type === "normal") {
                    // console.log("data:", response.data.orders);
                    // setSelectedOrderDetails(response.data.orders);
                    var tempDetails = JSON.parse(response.data.orders[0].order_details); //JSON.parse(order_details);
                    setSelectedExtras(tempDetails.hasOwnProperty("extras") ? [...tempDetails["extras"]] : []);
                    setSelectedOrderDetails(tempDetails.hasOwnProperty("orderDetails") ? [...tempDetails["orderDetails"]] : []);
                    setSelectedPackageServices(tempDetails.hasOwnProperty("selectedCampaign") ? [...tempDetails["selectedCampaign"]] : []);

                    setOrder({
                        Title: response.data.orders[0].package_name,
                        Amount: response.data.orders[0].order_price,
                        Date: response.data.orders[0].order_date,
                        Method: response.data.orders[0].payment_method,
                        Status: response.data.orders[0].order_status,
                        PaymentStatus: response.data.orders[0].payment_status,
                        PaymentResponse: JSON.parse(atob(atob(response.data.orders[0].payment_response)))
                    })
                }
            }
            // else {
            //     handleMessage({ type: 'danger', message: response.data.message, show: true });
            // }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    }

    const getResultsFromResponse = (chainID, hash, value) => {
        // console.log("typeof chainID: ", typeof chainID);
        // console.log("chainID: ", chainID);

        // console.log("typeof hash: ", typeof hash);
        // console.log("hash: ", hash);

        // console.log("typeof value: ", typeof value);
        // console.log("value: ", value);

        var result = {};
        if (parseInt(chainID) === 97) {  //binance smart chain id for testing
            result.explorerUrl = `https://testnet.bscscan.com`;
            result.transactionUrl = `https://testnet.bscscan.com/tx/${hash}`;
            result.priceInCrypto = formatEther(value);
            result.currency = "TBNB";
        }
        else if (parseInt(chainID) === 56) { //Binance smart chain id for production 
            result.explorerUrl = `https://bscscan.com`;
            result.transactionUrl = `https://bscscan.com/tx/${hash}`;
            result.priceInCrypto = formatEther(value);
            result.currency = "BNB";
        }
        else if (parseInt(chainID) === 11155111) { //sepolia chain id for testing
            result.explorerUrl = `https://sepolia.etherscan.io`;
            result.transactionUrl = `https://sepolia.etherscan.io/tx/${hash}`;
            result.priceInCrypto = formatEther(value);
            result.currency = "SepoliaETH";
        }
        else if (parseInt(chainID) === 1) { //ethereum mainnet
            result.explorerUrl = `https://etherscan.io`;
            result.transactionUrl = `https://etherscan.io/tx/${hash}`;
            result.priceInCrypto = formatEther(value);
            result.currency = "ETH";
        }
        return result;
    }

    const TransactionDetails = () => {
        if (order.Method === "crypto") {
            const { currency, explorerUrl, transactionUrl, priceInCrypto } = getResultsFromResponse(order.PaymentResponse.chainId, order.PaymentResponse.hash, order.PaymentResponse.value);

            const formatedTo = order.PaymentResponse.to.slice(0, 10) + "..." + order.PaymentResponse.to.slice(-8);
            const formatedFrom = order.PaymentResponse.from.slice(0, 10) + "..." + order.PaymentResponse.from.slice(-8);

            return <>
                <div class="widget-title"><h5>Transaction Details</h5></div>
                <div className='row mb-4' style={{ border: "1px solid #edecf8", borderRadius: "5px", background: "#f7f7ff" }}>
                    <div className='col-md-3'>
                        <label className="col-form-label pb-0 fw-bold">From</label>
                        <p className='pb-2 mb-2'><Link to={`${explorerUrl}/address/${order.PaymentResponse.from}`} style={{ textTransform: "capitalize", color: "#326cfb" }} target='_blank'>{formatedFrom}</Link></p>
                    </div>
                    <div className='col-md-3'>
                        <label className="col-form-label pb-0 fw-bold">To</label>
                        <p className='text-black pb-2 mb-2'><Link to={`${explorerUrl}/address/${order.PaymentResponse.to}`} style={{ textTransform: "capitalize", color: "#326cfb" }} target='_blank'>{formatedTo}</Link></p>
                    </div>
                    <div className='col-md-3'>
                        <label className="col-form-label pb-0 fw-bold">Amount In Crypto</label>
                        <p className='pb-2 mb-2 fw-bold' style={{ textTransform: "capitalize", color: "#326cfb" }}>{priceInCrypto} {currency}</p>
                    </div>
                    <div className='col-md-3'>
                        <label className="col-form-label pb-0 fw-bold">Transaction</label>
                        <p className='pb-2 mb-2'><Link to={transactionUrl} style={{ textTransform: "capitalize", color: "#326cfb" }} target='_blank'>View on Explorer</Link></p>
                    </div>
                </div>
            </>
        }
        else if (order.Method !== "crypto") {
            return <>
                <div class="widget-title"><h5>Transaction Details</h5></div>
                <div className='row mb-4' style={{ border: "1px solid #edecf8", borderRadius: "5px", background: "#f7f7ff" }}>
                    <div className='col-md-5'>
                        <label className="col-form-label pb-0 fw-bold">From</label>
                        {/* <p className='py-2 mb-0'>{order.PaymentResponse.payer.name.given_name}</p> */}
                        <p className='text-black pb-2 mb-2'>{order.PaymentResponse.payer.email_address}</p>
                    </div>
                    <div className='col-md-5'>
                        <label className="col-form-label pb-0 fw-bold">To</label>
                        <p className='text-black pb-2 mb-2'>{order.PaymentResponse.purchase_units[0].payee.email_address}</p>
                    </div>
                    <div className='col-md-2'>
                        <label className="col-form-label pb-0 fw-bold">Amount Paid</label>
                        <p className='pb-2 mb-2 fw-bold' style={{ textTransform: "capitalize", color: "#326cfb" }}>{order.PaymentResponse.purchase_units[0].amount.value} {order.PaymentResponse.purchase_units[0].amount.currency_code}</p>
                    </div>
                </div>
            </>
        }
    }

    return (
        <>
            <Header />

            <div className="content">
                <div className="container">
                    <div className="row">

                        <SideBar />

                        <div className="col-lg-9">
                            <div className="widget-title">
                                {params.type === "campaign" ? <h4>Campaign Order Details</h4> : <h4>Order Details</h4>}
                            </div>
                            <div className="general-info mb-4">
                                <div className='row mb-4'>
                                    <div className='col-md-3'>
                                        <label className="col-form-label pb-0 fw-bold mb-2">{params.type === "campaign" ? "Campaign Title" : "Package Title"}</label>
                                        <p className='text-black py-2 mb-2'>{order.Title}</p>
                                    </div>
                                    <div className='col-md-1'>
                                        <label className="col-form-label pb-0 fw-bold mb-2">Amount</label>
                                        <p className='text-black py-2 mb-2 text-light-success fw-bold'>${order.Amount}</p>
                                    </div>
                                    <div className='col-md-3'>
                                        <label className="col-form-label pb-0 fw-bold mb-2">Date</label>
                                        <p className='text-black py-2 mb-2'>{order.Date}</p>
                                    </div>
                                    <div className='col-md-3'>
                                        <label className="col-form-label pb-0 fw-bold mb-2">Payment Method</label>
                                        <p className='py-2 mb-2 fw-bold' style={{ textTransform: "capitalize", color: "#326cfb" }}>{order.Method}</p>
                                    </div>
                                    <div className='col-md-2'>
                                        <label className="col-form-label pb-0 fw-bold mb-2">Status</label>
                                        {/* <p className='text-black py-2 mb-2'style={{textTransform: "capitalize", color: "#326cfb"}}>{order.Status}</p> */}
                                        <Badge status={order.Status} />
                                    </div>
                                </div>

                                {typeof order.Method !== "undefined" && <TransactionDetails />}

                                <p className='text-black fw-bold'>Details provided by client for this order</p>
                                <div className='row mb-3'>
                                    {
                                        selectedOrderDetails.map((o, index) => (
                                            <div className='col-md-6' key={index}>
                                                <div className="form-group mb-1">
                                                    <label className="col-form-label pb-0 fw-bold">{o.label} <span className="text-danger">*</span></label>
                                                    {/* <input type="text" className="form-control" placeholder="Enter Your Name" /> */}
                                                    {o.userinput.split("^").map((v, i) => (
                                                        <p className='text-black px-2 py-2 mb-0 bg-light rounded-2 mb-2' key={i}>{v}</p>
                                                    ))}
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                {
                                    selectedPackageServices.length > 0 && (
                                        <div className='row'>
                                            <div className="col-md-12">
                                                <div className="form-group mb-1">
                                                    <label className="col-form-label pb-0 text-black fw-bold">Selected campaign details</label>
                                                    <ul className="booking-date">
                                                        {selectedPackageServices.map((pkg, index) => (
                                                            <li key={index} className='d-flex flex-column'>
                                                                {pkg.tier !== "" && <span className='mb-0 align-self-start'>{pkg.tier}</span>}
                                                                <div className='d-flex'>
                                                                    <p className='mb-0' style={{ minWidth: "50px" }}>{pkg.quantity}</p>
                                                                    <div className='d-flex flex-column align-items-start'>
                                                                        <span className='text-black'>{pkg.service}</span>
                                                                        {/* <span>{pkg.details}</span> */}
                                                                        {
                                                                            pkg.details.split("||").map((p, i) => (
                                                                                <span className='text-start' key={i}>↳ {p}</span>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                                {
                                    selectedExtras.length > 0 && (
                                        <div className='row'>
                                            <div className="col-md-12">
                                                <div className="form-group mb-1">
                                                    <label className="col-form-label pb-0 text-black fw-bold">Selected Extra Service in this order</label>
                                                    {
                                                        selectedExtras.map((extra, index) => {
                                                            //return <p className="text-black ps-2 mb-0">{extra["feature"]} - {extra["price"]}</p>
                                                            return <span className="d-flex w-100 justify-content-between pe-4" key={index}>
                                                                <p className="text-black ps-2 mb-0">{extra["feature"]}</p> <p className="text-black ps-2 mb-0">Price: <b>${extra["price"]}</b></p>
                                                            </span>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mouse-cursor cursor-outer" style={{ visibility: 'visible' }} />
            <div className="mouse-cursor cursor-inner" style={{ visibility: 'visible' }} />
        </>
    )
}

export default OrderDetails