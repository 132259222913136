import React from 'react'
import { Link } from 'react-router-dom'

const GrowingSection = ({ index }) => {
    const createSection = () => {
        if (index === 1) {
            return (
                <section className="client-sections review-four">
                    <div className="container">
                        <div className="services-header aos aos-init aos-animate" data-aos="fade-up">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="section-content section-client mb-0">
                                        <h2>Benefit from MonkLink SEO</h2>
                                        {/* <div className="our-img-all">
                    <img src="/assets/img/icons/scissor-white.svg" alt="image" />
                  </div> */}
                                        <p className='fw-bold fs-5 mt-4 mb-3'>Become one of the 100,000+ Clients who have already grown with us.</p>
                                        <p>Make the next move and use our service to join the thousands of businesses that are expanding.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )
        }
        else if (index === 2) {
            return (
                <section className="client-sections review-four">
                    <div className="container">
                        <div className="services-header aos aos-init aos-animate" data-aos="fade-up">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="section-content section-client mb-0">
                                        <h2>Increase your web presence right now.</h2>
                                        {/* <div className="our-img-all">
                    <img src="/assets/img/icons/scissor-white.svg" alt="image" />
                  </div> */}
                                        {/* <p className='fw-bold fs-5 mt-4 mb-3'>Join the 100,000+ Clients already growing with us</p> */}
                                        <p>Gain free access and begin building the authority of your website to improve your Google ranking.</p>
                                        <Link className="read-more align-self-center px-5 mt-0 mt-md-4" to={"/register"}>Get Free Access</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )
        }
    }

    return (
        createSection()
    )
}

export default GrowingSection