import React, { useEffect, useState } from 'react'
import axios from 'axios';

import Header from '../../components/Header';

import SitesSlider from '../../components/SitesSlider';
import ReviewsSection from '../../components/ReviewsSection';
import Footer from '../../components/Footer';

const PressRelease = () => {
    const [sites, setSites] = useState([]);

    useEffect(() => {
        fetchPressreleaseSites();
    }, [])

    const fetchPressreleaseSites = () => {
        const json = JSON.stringify({ "empty": "empty" });
        const url = `${process.env.REACT_APP_BASE_API}/api.php?action=get_pressrelease_site`;

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                setSites(response.data.sites);
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    }

    return (
        <>
            <Header />

            {/* <PageHeader /> */}

            {/* <div className="content">
                <div className="about-sec">
                    <div className="container">
                        <div className="row align-items-center">                            
                            <div className="col-lg-6">
                                <div className="about-content">
                                    <h6>ABOUT OUR COMPANY</h6>
                                    <h2>Best Solution For Cleaning Services</h2>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim minim veniam, quis nostrud exercitation ullamco laboris nisi esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <ul>
                                                <li>At vero et accusamus iusto dignissimos</li>
                                                <li>At vero et accusamus iusto dignissimos</li>
                                            </ul>
                                        </div>
                                        <div className="col-md-6">
                                            <ul>
                                                <li>Nam libero tempore, cum soluta nobis</li>
                                                <li>Nam libero tempore, cum soluta nobis</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-img">
                                    <div className="about-exp">
                                        <span>12+ years of experiences</span>
                                    </div>
                                    <div className="abt-img">
                                        <img src="assets/img/press-release-2024.png" className="img-fluid" alt="img" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <section className="hero-section" style={{ minHeight: "400px" }}>
                <div className="container">
                    <div className="home-banner">
                        <div className="row w-100">
                            <div className="col-lg-7 col-md-10 mx-auto">
                                <div className="section-search aos aos-init aos-animate" data-aos="fade-up">
                                    {/* <h1>Are You Looking for <br /><b>Effective SEO Services?</b></h1> */}
                                    <h1>Get Featured on <br /><span className="text-spin2 styled aqua ms-2 ms-md-3"></span><br />with <b>Press Release</b></h1>
                                    {/* <h3 className="mt-5">You Can Easily Rank Higher and Attract Organic Traffic</h3>
                                    <p className="mb-3">MonkLink Provides Smart SEO Solutions to Solve All Your Problems</p>
                                    <a className="btn btn-primary" href="/register"><i className="feather-user me-2" />Get Free Access</a>
                                    <p className="mt-3">Create your free account (No credit card required!)</p> */}
                                    <ul className='points my-4'>
                                        <li>Authority Backlinks & High-End News Site Access</li>
                                        <li>News websites having domain authority of up to 92</li>
                                        <li>Total authority over the choice of location</li>
                                        <li>In-content, organic follow links</li>
                                        <li>Attract more than 1 billion readers each month</li>
                                    </ul>
                                    <div className='d-flex'>
                                        <a className="btn btn-primary me-md-4" href="#"><i className="feather-user me-2" />Place Your Order!</a>
                                        <a className="btn btn-primary" href="#"><i className="feather-user me-2" />View News Sites!</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <img src="assets/img/press-release-2024.png" className="img-fluid aos aos-init aos-animate" alt="img" data-aos="fade-up" />
                                {/* <div className="banner-imgs">
                                    <div className="banner-1 shape-1">
                                        <img className="img-fluid" alt="banner" src="assets/img/banner1.svg" loading="lazy" />
                                    </div>
                                    <div className="banner-2 shape-3">
                                        <img className="img-fluid" alt="banner" src="assets/img/banner2.svg" loading="lazy" />
                                    </div><div className="banner-3 shape-3"><img className="img-fluid" alt="banner" src="assets/img/banner3.svg" loading="lazy" />
                                    </div>
                                    <div className="banner-4 shape-2">
                                        <img className="img-responsive" alt="banner" src="assets/img/banner4.svg" loading="lazy" />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="satisfied-client">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="premium-heading aos-init aos-animate text-center" data-aos="fade-down">
                                <p><big>206,747</big> people have placed <big>33,524,847</big> orders with us.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <SitesSlider />

            <section className="healthy-eight-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-12">
                            <div className="pets-content-all">
                                {/* <h2>Keeping your pets</h2> */}
                                <h3>Seek Recognition & Visibility On Numerous High-Value International Media Platforms</h3>
                                <p>
                                    Both time and money should be saved. We'll handle it on your behalf! In only five days, we can complete the writing and publication of an article on the network of more than 500 global media websites and the most reputable publications, such as <b>Yahoo</b>, and <b>MSN</b>, and <b>Business Insider</b>,and <b>Benzinga</b>, and <b>AsiaOne</b>, all done within just 5 days!
                                </p>
                                <p>
                                    By choosing the website that best meets your needs, you may achieve any of your goals, including increasing brand recognition, reaching specific audiences, attracting qualified customers, and improving your Google ranking.
                                </p>
                                <a href="#" className="read-more mb-4 mb-md-0">Place Your Order Now!</a>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="healthy-pets-img d-flex justify-content-end">
                                <img src="https://panel.seoestore.net/assets/img/pr-lp/getSeen.png" alt="get seen" className="img-fluid w-75" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="healthy-eight-section" style={{ backgroundColor: "#f7f8f9" }}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-12">
                            <div className="healthy-pets-img d-flex justify-content-start">
                                <img src="https://panel.seoestore.net/assets/img/pr-lp/9.png" alt="get seen" className="img-fluid w-75" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="pets-content-all">
                                <h3>Obtain A Higher Google Ranking</h3>
                                <p>
                                    You should be on the first page right now. Each press release you upload on your website might result in the creation of a brand-new page that gets indexed by Google. You may increase traffic and reference links to your website by having an indexed page.
                                </p>
                                <p>
                                    Ensure that your press release appears highly in all of the main search engines to increase your internet visibility.
                                </p>
                                {/* <a href="#" className="btn btn-primary">Book Now</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="healthy-eight-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-12">
                            <div className="pets-content-all">
                                {/* <h2>Keeping your pets</h2> */}
                                <h3>Promote Your Brand</h3>
                                <p>
                                    Press releases are a useful tool for online reputation management (ORM) strategies. Getting good press for your business will boost its online reputation and help establish your website as a reliable resource for information about your goods and services.
                                </p>
                                <p>
                                    Press releases have the potential to boost sales and provide your company with excellent exposure.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="healthy-pets-img d-flex justify-content-end">
                                <img src="https://panel.seoestore.net/assets/img/pr-lp/10.png" alt="get seen" className="img-fluid w-75" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="healthy-eight-section" style={{ backgroundColor: "#f7f8f9" }}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-12">
                            <div className="healthy-pets-img d-flex justify-content-start">
                                <img src="https://panel.seoestore.net/assets/img/pr-lp/hookyour.png" alt="get seen" className="img-fluid w-75" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="pets-content-all">
                                <h3>Captivate Your Audience</h3>
                                <p>
                                    Why search elsewhere when you can be certain that your news release will be included on the highest authority news site and more than 500 global media sites? We ensure that your news appears on the best national and international news websites, premier regional and local news portals, prominent websites for radio stations and TV channels, news channels that are specific to trade and industry, online news blogs, and other websites that are pertinent to your business.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="healthy-eight-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-12">
                            <div className="pets-content-all">
                                {/* <h2>Keeping your pets</h2> */}
                                <h3>Increase Sales</h3>
                                <p>
                                    Press releases posted on reputable blogs, social media platforms, and news websites raise the internet exposure and reputation of your business. Increased online exposure, more indexed pages, improved brand recognition, and SEO advantages all work together to increase visitors and, ultimately, revenue.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="healthy-pets-img d-flex justify-content-end">
                                <img src="https://panel.seoestore.net/assets/img/pr-lp/Sales.png" alt="get seen" className="img-fluid w-75" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="client-sections review-four">
                <div className="container">
                    <div className="services-header aos aos-init aos-animate" data-aos="fade-up">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-content section-client mb-0">
                                    <h2>Are You Prepared To Be Seen And Acknowledged Worldwide?</h2>
                                    <p>With effective press coverage and digital PR linkages, you can boost brand recognition and grow your return on investment.</p>
                                    <a className="read-more align-self-center px-5 mt-0 mt-md-4" href="#">Order Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <ReviewsSection />

            <section className="work-section pt-5 pb-0" style={{ backgroundColor: "#ffffff" }}>
                <div className="container">

                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="section-heading section-heading-eight aos aos-init aos-animate mb-0" data-aos="fade-up">
                                <img src="/assets/img/logo.png" alt="logo" loading="lazy" />
                                <h2>Give the debut of your brand a buzz!</h2>
                                <p className='w-75 mx-auto'>Featured on the most reputable news websites—"with millions of readers"—is ideal for improving your SEO. <br />Increase traffic to your website and search engine rankings to strengthen your online presence!</p>
                            </div>
                        </div>
                    </div>

                    <div className="row pt-5 mb-0 mb-md-5">
                        <div className="col-6 col-md-3 mb-4">
                            <div className="work-box aos aos-init aos-animate h-100 mb-0 d-flex flex-column justify-content-between" data-aos="fade-up">
                                <div className="work-icon">
                                    <span style={{ minWidth: "80%" }} className='p-3 mb-3'>
                                        <img src="https://panel.seoestore.net/assets/img/press/press-2024/yahoo.svg" alt="img" className='img-fluid' style={{ maxHeight: "80%" }} />
                                    </span>
                                </div>
                                <div className='row'>
                                    <div className='col-6 d-flex flex-column'>
                                        <span style={{ color: "#4c40ed", fontWeight: 600 }}>240.8m</span>
                                        <p style={{ fontWeight: 600 }}>Visitors</p>
                                    </div>
                                    <div className='col-6 d-flex flex-column'>
                                        <span style={{ color: "#4c40ed", fontWeight: 600 }}>93</span>
                                        <p style={{ fontWeight: 600 }}>Authority</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 mb-4">
                            <div className="work-box aos aos-init aos-animate h-100 mb-0 d-flex flex-column justify-content-between" data-aos="fade-up">
                                <div className="work-icon">
                                    <span style={{ minWidth: "80%" }} className='p-3 mb-3'>
                                        <img src="https://panel.seoestore.net/assets/img/pr-logos/MSN.svg" alt="img" className='img-fluid' style={{ maxHeight: "80%" }} />
                                    </span>
                                </div>
                                <div className='row'>
                                    <div className='col-6 d-flex flex-column'>
                                        <span style={{ color: "#4c40ed", fontWeight: 600 }}>689.1m</span>
                                        <p style={{ fontWeight: 600 }}>Visitors</p>
                                    </div>
                                    <div className='col-6 d-flex flex-column'>
                                        <span style={{ color: "#4c40ed", fontWeight: 600 }}>94</span>
                                        <p style={{ fontWeight: 600 }}>Authority</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 mb-4">
                            <div className="work-box aos aos-init aos-animate h-100 mb-0 d-flex flex-column justify-content-between" data-aos="fade-up">
                                <div className="work-icon">
                                    <span style={{ minWidth: "80%" }} className='p-3 mb-3'>
                                        <img src="https://panel.seoestore.net/assets/img/pr-logos/Business-Insider.svg" alt="img" className='img-fluid' style={{ maxHeight: "80%" }} />
                                    </span>
                                </div>
                                <div className='row'>
                                    <div className='col-6 d-flex flex-column'>
                                        <span style={{ color: "#4c40ed", fontWeight: 600 }}>75.9m</span>
                                        <p style={{ fontWeight: 600 }}>Visitors</p>
                                    </div>
                                    <div className='col-6 d-flex flex-column'>
                                        <span style={{ color: "#4c40ed", fontWeight: 600 }}>94</span>
                                        <p style={{ fontWeight: 600 }}>Authority</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 mb-4">
                            <div className="work-box aos aos-init aos-animate h-100 mb-0 d-flex flex-column justify-content-between" data-aos="fade-up">
                                <div className="work-icon">
                                    <span style={{ minWidth: "80%" }} className='p-3 mb-3'>
                                        <img src="https://panel.seoestore.net//assets/img/pr-logos/Publish-On-Associated.svg" alt="img" className='img-fluid' style={{ maxHeight: "80%" }} />
                                    </span>
                                </div>
                                <div className='row'>
                                    <div className='col-6 d-flex flex-column'>
                                        <span style={{ color: "#4c40ed", fontWeight: 600 }}>87m</span>
                                        <p style={{ fontWeight: 600 }}>Visitors</p>
                                    </div>
                                    <div className='col-6 d-flex flex-column'>
                                        <span style={{ color: "#4c40ed", fontWeight: 600 }}>92</span>
                                        <p style={{ fontWeight: 600 }}>Authority</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 mb-4">
                            <div className="work-box aos aos-init aos-animate h-100 mb-0 d-flex flex-column justify-content-between" data-aos="fade-up">
                                <div className="work-icon">
                                    <span style={{ minWidth: "80%" }} className='p-3 mb-3'>
                                        <img src="https://panel.seoestore.net//assets/img/pr-logos/the-globeandmail.png" alt="img" className='img-fluid' style={{ maxHeight: "80%" }} />
                                    </span>
                                </div>
                                <div className='row'>
                                    <div className='col-6 d-flex flex-column'>
                                        <span style={{ color: "#4c40ed", fontWeight: 600 }}>8.3m</span>
                                        <p style={{ fontWeight: 600 }}>Visitors</p>
                                    </div>
                                    <div className='col-6 d-flex flex-column'>
                                        <span style={{ color: "#4c40ed", fontWeight: 600 }}>92</span>
                                        <p style={{ fontWeight: 600 }}>Authority</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 mb-4">
                            <div className="work-box aos aos-init aos-animate h-100 mb-0 d-flex flex-column justify-content-between" data-aos="fade-up">
                                <div className="work-icon">
                                    <span style={{ minWidth: "80%" }} className='p-3 mb-3'>
                                        <img src="https://panel.seoestore.net//assets/img/pr-logos/Publish-On-Benzinga.svg" alt="img" className='img-fluid' style={{ maxHeight: "80%" }} />
                                    </span>
                                </div>
                                <div className='row'>
                                    <div className='col-6 d-flex flex-column'>
                                        <span style={{ color: "#4c40ed", fontWeight: 600 }}>17.6m</span>
                                        <p style={{ fontWeight: 600 }}>Visitors</p>
                                    </div>
                                    <div className='col-6 d-flex flex-column'>
                                        <span style={{ color: "#4c40ed", fontWeight: 600 }}>88</span>
                                        <p style={{ fontWeight: 600 }}>Authority</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 mb-4">
                            <div className="work-box aos aos-init aos-animate h-100 mb-0 d-flex flex-column justify-content-between" data-aos="fade-up">
                                <div className="work-icon">
                                    <span style={{ minWidth: "80%" }} className='p-3 mb-3'>
                                        <img src="https://panel.seoestore.net//assets/img/pr-logos//Publish-On-Newsmax.svg" alt="img" className='img-fluid' style={{ maxHeight: "80%" }} />
                                    </span>
                                </div>
                                <div className='row'>
                                    <div className='col-6 d-flex flex-column'>
                                        <span style={{ color: "#4c40ed", fontWeight: 600 }}>27m</span>
                                        <p style={{ fontWeight: 600 }}>Visitors</p>
                                    </div>
                                    <div className='col-6 d-flex flex-column'>
                                        <span style={{ color: "#4c40ed", fontWeight: 600 }}>85</span>
                                        <p style={{ fontWeight: 600 }}>Authority</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 mb-4">
                            <div className="work-box aos aos-init aos-animate h-100 mb-0 d-flex flex-column justify-content-between" data-aos="fade-up">
                                <div className="work-icon">
                                    <span style={{ minWidth: "80%" }} className='p-3 mb-3'>
                                        <img src="https://panel.seoestore.net//assets/img/pr-logos//Publish-On-Street-Insider.svg" alt="img" className='img-fluid' style={{ maxHeight: "80%" }} />
                                    </span>
                                </div>
                                <div className='row'>
                                    <div className='col-6 d-flex flex-column'>
                                        <span style={{ color: "#4c40ed", fontWeight: 600 }}>4.5m</span>
                                        <p style={{ fontWeight: 600 }}>Visitors</p>
                                    </div>
                                    <div className='col-6 d-flex flex-column'>
                                        <span style={{ color: "#4c40ed", fontWeight: 600 }}>83</span>
                                        <p style={{ fontWeight: 600 }}>Authority</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 mb-4">
                            <div className="work-box aos aos-init aos-animate h-100 mb-0 d-flex flex-column justify-content-between" data-aos="fade-up">
                                <div className="work-icon">
                                    <span style={{ minWidth: "80%" }} className='p-3 mb-3'>
                                        <img src="https://panel.seoestore.net//assets/img/pr-logos//Publish-On-AsiaOne.svg" alt="img" className='img-fluid' style={{ maxHeight: "80%" }} />
                                    </span>
                                </div>
                                <div className='row'>
                                    <div className='col-6 d-flex flex-column'>
                                        <span style={{ color: "#4c40ed", fontWeight: 600 }}>6.4m</span>
                                        <p style={{ fontWeight: 600 }}>Visitors</p>
                                    </div>
                                    <div className='col-6 d-flex flex-column'>
                                        <span style={{ color: "#4c40ed", fontWeight: 600 }}>82</span>
                                        <p style={{ fontWeight: 600 }}>Authority</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 mb-4">
                            <div className="work-box aos aos-init aos-animate h-100 mb-0 d-flex flex-column justify-content-between" data-aos="fade-up">
                                <div className="work-icon">
                                    <span style={{ minWidth: "80%" }} className='p-3 mb-3'>
                                        <img src="https://panel.seoestore.net//assets/img/pr-logos/fox40.png" alt="img" className='img-fluid' style={{ maxHeight: "80%" }} />
                                    </span>
                                </div>
                                <div className='row'>
                                    <div className='col-6 d-flex flex-column'>
                                        <span style={{ color: "#4c40ed", fontWeight: 600 }}>158k</span>
                                        <p style={{ fontWeight: 600 }}>Visitors</p>
                                    </div>
                                    <div className='col-6 d-flex flex-column'>
                                        <span style={{ color: "#4c40ed", fontWeight: 600 }}>80</span>
                                        <p style={{ fontWeight: 600 }}>Authority</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 mb-4">
                            <div className="work-box aos aos-init aos-animate h-100 mb-0 d-flex flex-column justify-content-between" data-aos="fade-up">
                                <div className="work-icon">
                                    <span style={{ minWidth: "80%" }} className='p-3 mb-3'>
                                        <img src="https://panel.seoestore.net//assets/img/pr-logos//Barchart.svg" alt="img" className='img-fluid' style={{ maxHeight: "80%" }} />
                                    </span>
                                </div>
                                <div className='row'>
                                    <div className='col-6 d-flex flex-column'>
                                        <span style={{ color: "#4c40ed", fontWeight: 600 }}>7.8m</span>
                                        <p style={{ fontWeight: 600 }}>Visitors</p>
                                    </div>
                                    <div className='col-6 d-flex flex-column'>
                                        <span style={{ color: "#4c40ed", fontWeight: 600 }}>62</span>
                                        <p style={{ fontWeight: 600 }}>Authority</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 mb-4">
                            <div className="work-box aos aos-init aos-animate h-100 mb-0 d-flex flex-column justify-content-between" data-aos="fade-up">
                                <div className="work-icon">
                                    <span style={{ minWidth: "80%" }} className='p-3 mb-3'>
                                        <img src="https://panel.seoestore.net//assets/img/pr-logos/Digital-Journal-Logo.png" alt="img" className='img-fluid' style={{ maxHeight: "80%" }} />
                                    </span>
                                </div>
                                <div className='row'>
                                    <div className='col-6 d-flex flex-column'>
                                        <span style={{ color: "#4c40ed", fontWeight: 600 }}>1.017M</span>
                                        <p style={{ fontWeight: 600 }}>Visitors</p>
                                    </div>
                                    <div className='col-6 d-flex flex-column'>
                                        <span style={{ color: "#4c40ed", fontWeight: 600 }}>87</span>
                                        <p style={{ fontWeight: 600 }}>Authority</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 mb-4">
                            <div className="work-box aos aos-init aos-animate h-100 mb-0 d-flex flex-column justify-content-between" data-aos="fade-up">
                                <div className="work-icon">
                                    <span style={{ minWidth: "80%" }} className='p-3 mb-3'>
                                        <img src="https://panel.seoestore.net/assets/img/pr-logos/Minyanville.svg" alt="img" className='img-fluid' style={{ maxHeight: "80%" }} />
                                    </span>
                                </div>
                                <div className='row'>
                                    <div className='col-6 d-flex flex-column'>
                                        <span style={{ color: "#4c40ed", fontWeight: 600 }}>14.3k</span>
                                        <p style={{ fontWeight: 600 }}>Visitors</p>
                                    </div>
                                    <div className='col-6 d-flex flex-column'>
                                        <span style={{ color: "#4c40ed", fontWeight: 600 }}>69</span>
                                        <p style={{ fontWeight: 600 }}>Authority</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 mb-4">
                            <div className="work-box aos aos-init aos-animate h-100 mb-0 d-flex flex-column justify-content-between" data-aos="fade-up">
                                <div className="work-icon">
                                    <span style={{ minWidth: "80%" }} className='p-3 mb-3'>
                                        <img src="https://panel.seoestore.net//assets/img/pr-logos//macau-business-logo.png" alt="img" className='img-fluid' style={{ maxHeight: "80%" }} />
                                    </span>
                                </div>
                                <div className='row'>
                                    <div className='col-6 d-flex flex-column'>
                                        <span style={{ color: "#4c40ed", fontWeight: 600 }}>195K</span>
                                        <p style={{ fontWeight: 600 }}>Visitors</p>
                                    </div>
                                    <div className='col-6 d-flex flex-column'>
                                        <span style={{ color: "#4c40ed", fontWeight: 600 }}>79</span>
                                        <p style={{ fontWeight: 600 }}>Authority</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 mb-4">
                            <div className="work-box aos aos-init aos-animate h-100 mb-0 d-flex flex-column justify-content-between" data-aos="fade-up">
                                <div className="work-icon">
                                    <span style={{ minWidth: "80%" }} className='p-3 mb-3'>
                                        <img src="https://panel.seoestore.net/assets/img/pr-logos/oppenpr.svg" alt="img" className='img-fluid' style={{ maxHeight: "80%" }} />
                                    </span>
                                </div>
                                <div className='row'>
                                    <div className='col-6 d-flex flex-column'>
                                        <span style={{ color: "#4c40ed", fontWeight: 600 }}>169.0</span>
                                        <p style={{ fontWeight: 600 }}>Visitors</p>
                                    </div>
                                    <div className='col-6 d-flex flex-column'>
                                        <span style={{ color: "#4c40ed", fontWeight: 600 }}>70</span>
                                        <p style={{ fontWeight: 600 }}>Authority</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 mb-4">
                            <div className="work-box aos aos-init aos-animate h-100 mb-0 d-flex flex-column justify-content-between" data-aos="fade-up">
                                <div className="work-icon">
                                    <span style={{ minWidth: "80%" }} className='p-3 mb-3'>
                                        <img src="https://panel.seoestore.net/assets/img/pr-logos/the-chronicle-journal-PR-publishing.svg" alt="img" className='img-fluid' style={{ maxHeight: "80%" }} />
                                    </span>
                                </div>
                                <div className='row'>
                                    <div className='col-6 d-flex flex-column'>
                                        <span style={{ color: "#4c40ed", fontWeight: 600 }}>80.3k</span>
                                        <p style={{ fontWeight: 600 }}>Visitors</p>
                                    </div>
                                    <div className='col-6 d-flex flex-column'>
                                        <span style={{ color: "#4c40ed", fontWeight: 600 }}>58</span>
                                        <p style={{ fontWeight: 600 }}>Authority</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="btn btn-primary w-25 mx-auto" data-bs-toggle="modal" data-bs-target="#staticBackdrop">SHOW ALL NEWS SITES</div>
                    </div>
                </div>
            </section>

            <section className="work-section py-5"> {/**works-eight-section */}
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="section-heading section-heading-eight aos aos-init aos-animate" data-aos="fade-up">
                                {/* <img src="/assets/img/logo.png" alt="logo" loading='lazy' /> */}
                                <h2 style={{ color: "#5347e9" }}>Work Process</h2>
                                <p className='w-75 mx-auto'>Start getting real exposure for your brand. We'll take care of it for you. We can get an article written and published on the network of over 200+ news sites, all done within just 5 days.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center aos aos-init aos-animate" data-aos="fade-up">
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="works-eights-main">
                                <div className="works-eights-img">
                                    <img src="https://panel.seoestore.net/assets/img/pr-lp/writing.png" alt="steps" className='img-fluid w-75' />
                                    {/* <i className="feather-users" style={{ fontSize: "3.5rem", fontWeight: 800, color: "#4e42eb" }}></i> */}
                                    <div className="works-eights-arrow">
                                        <img src="/assets/img/icons/arrow-eight-1.svg" alt="customer" />
                                    </div>
                                </div>
                                {/* <h4>205,375</h4> */}
                                <h3 style={{ color: "#4e42eb" }}>Writing</h3>
                                <p className='text-center text-md-start'>Get your press release written by experienced writers who can craft the perfect news story to generate interest in your brandw with natural, in-content follow links.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="works-eights-main">
                                <div className="works-eights-img">
                                    <img src="https://panel.seoestore.net/assets/img/pr-lp/publishing.png" alt="steps" className='img-fluid w-75' />
                                    {/* <i className="feather-clock" style={{ fontSize: "3.5rem", fontWeight: 800, color: "#4e42eb" }}></i> */}
                                    <div className="works-eights-arrow">
                                        <img src="/assets/img/icons/arrow-eight-1.svg" alt="customer" />
                                    </div>
                                </div>
                                {/* <h4>6+ Years</h4> */}
                                <h3 style={{ color: "#4e42eb" }}>Publishing</h3>
                                <p className='text-center text-md-start'>Complete control, select news site that best fits you & Get featured on Yahoo, MSN, Business Insider, Benzinga, and AsiaOne, and 400+ news that receive 100 million visitors every month.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="works-eights-main">
                                <div className="works-eights-img">
                                    <img src="https://panel.seoestore.net/assets/img/pr-lp/get-result.png" alt="steps" className='img-fluid w-75' />
                                    {/* <i className="feather-globe" style={{ fontSize: "3.5rem", fontWeight: 800, color: "#4e42eb" }}></i> */}
                                    {/* <div className="works-eights-arrow">
                                        <img src="/assets/img/icons/arrow-eight-1.svg" alt="countries" />
                                    </div> */}
                                </div>
                                {/* <h4>70+</h4> */}
                                <h3 style={{ color: "#4e42eb" }}>Get Results</h3>
                                <p className='text-center text-md-start'>We send you a full report with live links, and you enjoy the boost in exposure and sales.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row py-4">
                        <div className="col-md-12 text-center">
                            <div className="section-heading section-heading-eight aos aos-init aos-animate mb-0" data-aos="fade-up">
                                <p className='mb-3'>Reach the market players and create a powerful market impact!</p>
                                <a className="btn btn-primary" href="#">Place Your Order Now!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />

            <div className="mouse-cursor cursor-outer" style={{ visibility: 'visible' }} />
            <div className="mouse-cursor cursor-inner" style={{ visibility: 'visible' }} />

            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">All News Sites</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <div className="section-heading section-heading-eight aos aos-init aos-animate mb-0" data-aos="fade-up">
                                        {/* <img src="/assets/img/logo.png" alt="logo" loading="lazy" /> */}
                                        <h2>All News Sites</h2>
                                        <p className='w-75 mx-auto'>Our growing network of news sites ranks high on Google to deliver you quality readers. Here are some of the news websites you will get featured on it. Please note that we will keep updating this list.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">

                                    <div class="table-responsive">
                                        <table class="table mb-0 custom-table" id="pressreleaseTable">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th width="5%">#</th>
                                                    <th width="25%">New Sites</th>
                                                    <th>Domain <br />Authority</th>
                                                    <th>Moz <br />Rank</th>
                                                    <th>Global <br />Rank</th>
                                                    <th>Social <br />Followers</th>
                                                    <th>Monthly <br />Readers</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    sites.length > 0
                                                        ?
                                                        sites.map((site, index) => (
                                                            <tr>
                                                                <td width="5%" className='text-black fw-bold'>{index + 1}</td>
                                                                <td width="25%" style={{ whiteSpace: "wrap", fontWeight: "bold" }}><a href={site.site["url"]}>{site.site["name"]}</a></td>
                                                                <td><a href={site.da["url"]}>{site.da["name"]}</a></td>
                                                                <td><a href={site.moz_rank["url"]}>{site.moz_rank["name"]}</a></td>
                                                                <td>
                                                                    {
                                                                        site.global_rank["url"] !== "" ?
                                                                            (<a href={site.global_rank["url"]}>{site.global_rank["name"]}</a>)
                                                                            :
                                                                            (<span style={{ color: "#4c40ed" }}>{site.global_rank["name"]}</span>)
                                                                    }
                                                                    {/* <a href={site.global_rank["url"]}>{site.global_rank["name"]}</a> */}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        site.social_followers["url"] !== "" ?
                                                                            (<a href={site.social_followers["url"]}>{site.social_followers["name"]}</a>)
                                                                            :
                                                                            (<span style={{ color: "#4c40ed" }}>{site.social_followers["name"]}</span>)
                                                                    }
                                                                    {/* <a href={site.social_followers["url"]}>{site.social_followers["name"]}</a> */}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        site.monthly_readers["url"] !== "" ?
                                                                            (<a href={site.monthly_readers["url"]}>{site.monthly_readers["name"]}</a>)
                                                                            :
                                                                            (<span style={{ color: "#4c40ed" }}>{site.monthly_readers["name"]}</span>)
                                                                    }
                                                                    {/* <a href={site.monthly_readers["url"]}>{site.monthly_readers["name"]}</a> */}
                                                                </td>
                                                            </tr>
                                                        ))
                                                        :
                                                        (<tr>
                                                            <td colspan="7" class="text-center">
                                                                <span>Currently no pressrelease site available!</span>
                                                            </td>
                                                        </tr>)
                                                }
                                                {/* <tr>
                                                    <td colspan="6" class="text-center">
                                                        <span>Currently you have not received any order!</span>
                                                    </td>
                                                </tr> */}
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            {/* <button type="button" className="btn btn-primary">Understood</button> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PressRelease