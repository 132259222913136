import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useNavigate } from 'react-router-dom';

import Header from '../../../components/Header';
import SideBar from '../../../components/SideBar';

import Alert from '../../../components/Alert';

import { getCookie, checkRole } from '../../../func';

const AddPressReleasePackage = () => {
    const navigate = useNavigate();

    const [services, setServices] = useState([]);
    const [selectedService, setSelectedService] = useState("");
    const [name, setName] = useState("");
    const [details, setDetails] = useState("");
    const [images, setImages] = useState([]);
    const [price, setPrice] = useState(0);
    const [features, setFeatures] = useState([]);

    const [alert, setAlert] = useState({
        type: '',
        message: '',
        show: false
    });

    useEffect(() => {
        if (getCookie("token") !== undefined && getCookie("token") !== null) {
            if (checkRole(getCookie("token")) !== "admin")
                navigate(`/dashboard`, { replace: true });
            else
                fetchpressreleaseserviceids();
        }
        else {
            navigate(`/login`, { replace: true });
        }
    }, [])

    useEffect(()=>{
        console.log("selectedService: ", selectedService);
    }, [selectedService])

    const handleMessage = (params) => {
        setAlert({ type: params.type, message: params.message, show: true });

        setTimeout(() => {
            setAlert({ type: '', message: '', show: false });
        }, 3000);
    }

    const changeHandler = (e) => {
        let files = e.target.files;
        //console.log("files: ", files);

        var allFiles = [];
        for (var i = 0; i < files.length; i++) {

            let file = files[i];

            let ext = '';
            switch (file.type) {
                case "image/x-png":
                    ext = "png"
                    break;
                case "image/png":
                    ext = "png"
                    break;
                case "image/jpeg":
                    ext = "jpg"
                    break;
                case "image/webp":
                    ext = "webp"
                    break;
                default:
                    break;
            }

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let fileInfo = {
                    name: file.name.replace(`.${ext}`, ''),
                    type: file.type,
                    extention: ext,
                    size: Math.round(file.size / 1000) + ' kB',
                    base64: reader.result,
                    file: file,
                };

                allFiles.push(fileInfo);

                if (allFiles.length == files.length) {
                    console.log(allFiles);
                    //if (this.props.multiple) this.props.onDone(allFiles);
                    //else this.props.onDone(allFiles[0]);
                    setImages(allFiles);
                }
            }
        }
    }

    const removeImage = (i) => {
        let array = [...images];
        array.splice(i, 1);
        setImages(array);
    }

    const handleAddFeature = () => {
        setFeatures([...features, { feature: "", price: "" }]);
    };

    const handleFeatureChange = (event, index) => {
        let { name, value } = event.target;
        let onChangeValue = [...features];
        onChangeValue[index][name] = value;
        setFeatures(onChangeValue);
    };

    const handleDeleteFeature = (index) => {
        const newArray = [...features];
        newArray.splice(index, 1);
        setFeatures(newArray);
    };

    const fetchpressreleaseserviceids = () => {
        const json = JSON.stringify({ "token": getCookie("token"), "offset": 0, "limit": 100 });
        const url = `${process.env.REACT_APP_BASE_API}/api.php?action=get_pressrelease_service_ids`;

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                // console.log("data: ", response.data);
                setServices(response.data.services);
            }
            else {
                handleMessage({ type: 'danger', message: response.data.message, show: true });
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    }

    const clear = () => {
        setSelectedService('');
        setName('');
        setDetails('');
        setImages([]);
        setPrice(0);
        setFeatures([]);
    }

    const addpressreleasepackage = () => {
        debugger;
        var nonEmptyIncludes = features.filter(value => value.feature !== '');
        var includes_string = nonEmptyIncludes.length > 0 ? JSON.stringify(features) : "";
        if (name.trim() !== "" && details.trim() !== "" && images.length > 0 && price.trim() !== "" && selectedService.trim() !== "") {
            const json = JSON.stringify({ "token": getCookie("token"), "title": name, "description": details, "images": images, "price": price, "featured_extras": includes_string, "ps_id": selectedService });
            const url = `${process.env.REACT_APP_BASE_API}/api.php?action=add_pressrelease_package`;

            axios.post(url, JSON.stringify({ params: json }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                if (response.data.success === "true") {
                    //console.log("package added successfully");
                    clear();
                    handleMessage({ type: 'success', message: response.data.message, show: true });
                }
                else {
                    handleMessage({ type: 'danger', message: response.data.message, show: true });
                }
            }).catch((error) => {
                console.error(`Error: ${error}`);
            });
        }
        else {
            if (name.trim() === "" && details.trim() === "" && images.length === 0)
                handleMessage({ type: 'danger', message: "Please fill all the required fields", show: true });
            else if (name.trim() === "")
                handleMessage({ type: 'danger', message: "Please provide package name", show: true });
            else if (details.trim() === "")
                handleMessage({ type: 'danger', message: "Please provide package details", show: true });
            else if (images.length === 0)
                handleMessage({ type: 'danger', message: "Please provide package image", show: true });
            else if (price <= 0)
                handleMessage({ type: 'danger', message: "Please provide package price", show: true });
            else if (selectedService.trim() === "")
                handleMessage({ type: 'danger', message: "Please Select Service", show: true });
        }
    }

    return (
        <>
            <Header />

            <div className="content">
                <div className="container">
                    <div className="row">

                        <SideBar />

                        <div className="col-lg-9">
                            <Alert type={alert.type} message={alert.message} show={alert.show} />
                            <div className="widget-title">
                                <h4>Add Press Release Service Package</h4>
                            </div>
                            <h6 className="user-title">General Information</h6>
                            <div className="general-info mb-4">
                                <div className="row">
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">Service <span className="text-danger">*</span></label>
                                            <select className="form-control" value={selectedService} onChange={(e) => setSelectedService(e.target.value)}>
                                                <option value={"-"}>Select Service</option>
                                                {services.map((service, index) => {
                                                    return <option value={service.ps_id} key={index}>{service.ps_title}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">Name <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" placeholder="Enter package name" value={name} onChange={(e) => setName(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12">
                                        <div className="form-group">
                                            <label className="col-form-label d-block">Details <span className="text-danger">*</span> <span className="brief-bio float-end">Brief description for package.</span></label>
                                            <textarea className="form-control" rows={5} placeholder="Add a brief description of package....." value={details} onChange={(e) => setDetails(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12">
                                        <h6 className="user-title">Package Image</h6>
                                        <div className="service-image-box mb-4">
                                            {images.length > 0 && <div className="service-image mb-2">
                                                <img src={images[0]["base64"]} alt="user" style={{ width: "300px", height: "250px", borderRadius: "8px" }} />
                                            </div>}
                                            <div className="pro-info">
                                                <div className="d-flex">
                                                    <div className="img-upload">
                                                        <i className="feather-upload-cloud me-1" />Upload
                                                        <input type="file" onChange={changeHandler} accept="image/x-png,image/gif,image/jpeg" />
                                                    </div>
                                                    <button className="btn btn-remove" onClick={() => removeImage(0)}>Remove</button>
                                                </div>
                                                <p>*image size should be at least 640px in width and 260px in height,and less then 500kb. Allowed files .png and .jpg.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">Price <span className="text-danger">*</span></label>
                                            <input type="number" className="form-control" placeholder="Enter package price" min={1} max={1000} value={price} onChange={(e) => setPrice(e.target.value)} />
                                        </div>
                                    </div>
                                    {/* <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">Average Unique Domains<span className="text-danger">*</span></label>
                                            <input type="number" className="form-control" placeholder="Enter average unique domain" min={1} max={1000} value={averageuniquedomains} onChange={(e) => setAverageUniqueDomains(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">Minimum Quantity<span className="text-danger">*</span></label>
                                            <input type="number" className="form-control" placeholder="Enter minimum quantity" min={1} max={1000} value={minimumqunatity} onChange={(e) => setMinmumQuantity(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">Maximum Quantity<span className="text-danger">*</span></label>
                                            <input type="number" className="form-control" placeholder="Enter maximum quantity" min={1} max={1000} value={maximumQuantity} onChange={(e) => setMaximumQuantity(e.target.value)} />
                                        </div>
                                    </div> */}
                                </div>

                                <div className="row d-none">
                                    <div className="col-12 d-flex align-items-center mb-3">
                                        <button className='btnaddfeature me-2' onClick={() => handleAddFeature()}>+</button>
                                        <h6>Add Featured Extras</h6>
                                    </div>
                                    {features.map((f, index) => (
                                        <div className="row col-12" key={index}>
                                            <div className="col-sm-6 col-md-4">
                                                <div className="form-group">
                                                    {/* <label className="col-form-label">Feature heading</label> */}
                                                    <input type="text" name="feature" className="form-control" placeholder="Enter feature heading" value={f.feature} onChange={(event) => handleFeatureChange(event, index)} />
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-4">
                                                <div className="form-group">
                                                    {/* <label className="col-form-label">Feature price</label> */}
                                                    <input type="number" name="price" className="form-control" placeholder="Enter feature price" min={1} max={1000} value={f.price} onChange={(event) => handleFeatureChange(event, index)} />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-4 d-flex align-items-center justify-content-end mb-3">
                                                {features.length >= 1 && (
                                                    <button className="btnremovefeature me-2" onClick={() => handleDeleteFeature(index)}>Delete</button>
                                                )}
                                                {/* {index === features.length - 1 && (
                                                    <button className="btnaddfeature" onClick={() => handleAddFeature()}>Add</button>
                                                )} */}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="acc-submit">
                                <button className="btn btn-secondary" onClick={() => clear()}>Cancel</button>
                                <button className="btn btn-primary" onClick={() => addpressreleasepackage()}>Save Changes</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="mouse-cursor cursor-outer" style={{ visibility: 'visible' }} />
            <div className="mouse-cursor cursor-inner" style={{ visibility: 'visible' }} />
        </>
    )
}

export default AddPressReleasePackage