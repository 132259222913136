import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom'

import Alert from '../Alert';

import { getCookie, getWords } from '../../func';
import Pagination from '../Pagination';

const AddedPressReleaseServices = ({ services, TotalServices, ItemLimit }) => {
    const [tempservices, setTempServices] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);

    const [alert, setAlert] = useState({
        type: '',
        message: '',
        show: false
    });

    useEffect(() => {
        if (services.length > 0)
            setTempServices([...services]);
    }, [services])

    const handleMessage = (params) => {
        setAlert({ type: params.type, message: params.message, show: true });

        setTimeout(() => {
            setAlert({ type: '', message: '', show: false });
        }, 3000);
    }

    const activatedeativeService = (action, id) => {
        const json = JSON.stringify({ "token": getCookie("token"), "ps_id": id, "action": action });
        const url = `${process.env.REACT_APP_BASE_API}/api.php?action=activate_deactivate_pressrelease_service`;

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                var temp = [...tempservices];
                temp.filter(s => s.ps_id === btoa(id))[0]["ps_status"] = parseInt(action);
                setTempServices([...temp]);
                handleMessage({ type: 'success', message: response.data.message, show: true });
            }
            else {
                handleMessage({ type: 'danger', message: response.data.message, show: true });
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    }

    const fetchPressReleaseServices = (offset, limit) => {
        const json = JSON.stringify({ "token": getCookie("token"), "limit": limit, "offset": offset });
        const url = `${process.env.REACT_APP_BASE_API}/api.php?action=get_pressrelease_services`;

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                // console.log(response.data.services);
                setTempServices([...response.data.services]);
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        const indexOfLastRecord = pageNumber * ItemLimit;
        fetchPressReleaseServices(indexOfLastRecord, ItemLimit);
    };

    // // Calculate the indices for slicing the data array
    // const indexOfLastRecord = (currentPage + 1) * itemsPerPage;
    // const indexOfFirstRecord = indexOfLastRecord - itemsPerPage;
    // const currentItems = data.slice(indexOfFirstRecord, indexOfLastRecord);
    const pageCount = Math.ceil(parseInt(TotalServices) / ItemLimit);

    return (
        <>
            <Alert type={alert.type} message={alert.message} show={alert.show} />
            <h6 className="user-title mt-5">All Press Release Services</h6>
            <div className="table-responsive">
                <table className="table mb-0 custom-table">
                    <thead className="thead-light">
                        <tr>
                            <th>#</th>
                            <th>Service</th>
                            <th>Description</th>
                            {/* <th>Delivery</th> */}
                            <th>Status</th>
                            <th>Acion</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tempservices.length > 0
                                ?
                                tempservices.map((service, index) => (
                                    <tr key={index}>
                                        <td>{atob(service.ps_id)}</td>
                                        <td>
                                            <h2 className="table-avatar">
                                                <Link to={`/service/${service.ps_slug}`}>
                                                    <div className="avatar avatar-m me-2">
                                                        <img className="avatar-img rounded" src={`${process.env.REACT_APP_BASE_API}${service.ps_image}`} alt="service img" />
                                                    </div>
                                                </Link>
                                                <div>
                                                    <Link to={`/service/${service.ps_slug}`}>{service.ps_title}</Link>
                                                    {/* <span>{service.service_sub_title}</span> */}
                                                </div>
                                            </h2>
                                        </td>
                                        <td className="text-body">{getWords(service.ps_description.replace(/<[^>]+>/g, " ").replaceAll("&nbsp;", " "), 5) + "..."}</td>
                                        {/* <td className="text-body">{service.service_delivery_time}</td> */}
                                        <td>{service.ps_status === 1 ? <span className="badge-success">Active</span> : <span className="badge-danger">Inactive</span>}</td>
                                        <td>
                                            {
                                                service.ps_status === 1 ? <button className='btn btn-light-danger' onClick={() => activatedeativeService("0", atob(service.ps_id))}>Deactivate</button> : <button className='btn btn-light-success' onClick={() => activatedeativeService("1", atob(service.ps_id))}>Activate</button>
                                            }
                                            <Link to={`/update_pressrelease_service/${service.ps_slug}`} className='btn btn-sm btn-success ms-2' target='_blank'>Edit</Link>
                                        </td>
                                    </tr>
                                ))
                                :
                                (
                                    <tr>
                                        <td colSpan={6} className='text-center'>
                                            <span>Currently you have not added any service!</span>
                                        </td>
                                    </tr>
                                )
                        }
                    </tbody>
                </table>
            </div>

            {
                TotalServices > ItemLimit && (<Pagination
                    pageCount={pageCount}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                />)
            }
        </>
    )
}

export default AddedPressReleaseServices