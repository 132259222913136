import React, { useEffect, useState } from 'react'
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';

import Header from '../../components/Header';
import SideBar from '../../components/SideBar';

import Alert from '../../components/Alert';

import { getCookie, eraseCookie, checkRole, base64_decode } from '../../func';

const gateways = [{ name: "Paypal", value: "paypal" }, { name: "Payoneer", value: "payoneer" }, { name: "Crypto - Binance", value: "bnb" }, { name: "Crypto - Ethereum", value: "eth" }]

const PaymentSettings = () => {
  const navigate = useNavigate();
  const [selectedGateway, setSelectedGateway] = useState({});
  const [inputValue, setInputValue] = useState("");

  const [savedGateways, setSavedGateways] = useState([]);

  const [enableForEdit, setEnableForEdit] = useState({});
  const [newSecret, setNewSecret] = useState('');

  const [enableUpdateButton, setEnableUpdateButton] = useState(false);

  const [alert, setAlert] = useState({
    type: '',
    message: '',
    show: false
  });

  useEffect(() => {
    if (getCookie("token") !== undefined && getCookie("token") !== null) {
      if (checkRole(getCookie("token")) !== "admin")
        navigate(`/dashboard`, { replace: true });
      else {
        fetchPaymentGateways();
      }
    }
    else {
      navigate(`/login`, { replace: true });
    }
  }, [])

  // useEffect(() => {
  //   console.log("selectedGateway: ", selectedGateway);
  // }, [selectedGateway]);

  useEffect(() => {
    if (newSecret.trim() !== "" && newSecret.trim() !== enableForEdit.gateway_secret.trim())
      setEnableUpdateButton(true);
    else
      setEnableUpdateButton(false);
  }, [newSecret])

  const handleMessage = (params) => {
    setAlert({ type: params.type, message: params.message, show: true });

    setTimeout(() => {
      setAlert({ type: '', message: '', show: false });
    }, 3000);
  }

  const handleGatewaySelection = (value) => {
    if (value !== "") {
      var temp = gateways.filter(g => g.value === value);
      // console.log("temp: ", temp);
      setSelectedGateway(...temp);
    }
    else {
      setSelectedGateway({ name: "", value: "" });
    }
  }

  const fetchPaymentGateways = () => {
    const json = JSON.stringify({ "token": getCookie("token") });
    const url = `${process.env.REACT_APP_BASE_API}/api.php?action=get_all_gateway`;

    axios.post(url, JSON.stringify({ params: json }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then((response) => {
      if (response.data.success === "true") {
        // console.log("gateways: ", response.data.gateways);
        var temp = [];
        response.data.gateways.forEach((g) => {
          // console.log("hash:", g.gateway_secret);
          // console.log("decrypted: ", atob(atob(atob(atob(g.gateway_secret)))));

          // temp.push({ "gateway_name": g.gateway_name, "gateway_secret": atob(atob(atob(g.gateway_secret))) })
          temp.push({ "gateway_name": g.gateway_name, "gateway_secret": atob(g.gateway_secret) })
        })
        // setSavedGateways(response.data.gateways);
        setSavedGateways([...temp]);
      }
    }).catch((error) => {
      console.error(`Error: ${error}`);
    });
  }

  const CreateInputForPaymentGateway = () => {
    if (selectedGateway.value === "paypal") {
      return (
        <div className="form-group">
          <label className="col-form-label">Provide Paypal ClientID <span className="text-danger">*</span></label>
          <input type="text" className="form-control" placeholder="Enter paypal ClientID" value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
        </div>
      )
    }
    else if (selectedGateway.value === "payoneer") {
      return (
        <div className="form-group">
          <label className="col-form-label">Provide Payoneer Client Secret <span className="text-danger">*</span></label>
          <input type="text" className="form-control" placeholder="Enter payoneer Client Secret" value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
        </div>
      )
    }
    else if (selectedGateway.value === "bnb") {
      return (
        <div className="form-group">
          <label className="col-form-label">Provide Binance wallet address to receive payment <span className="text-danger">*</span></label>
          <input type="text" className="form-control" placeholder="Enter binance wallet address" value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
        </div>
      )
    }
    else if (selectedGateway.value === "eth") {
      return (
        <div className="form-group">
          <label className="col-form-label">Provide Ethereum wallet address to receive payment <span className="text-danger">*</span></label>
          <input type="text" className="form-control" placeholder="Enter ethereum wallet address" value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
        </div>
      )
    }
  }

  const SavedGatewaysLayout = () => {
    return savedGateways.map((g, index) => {
      switch (g.gateway_name) {
        case "Paypal":
          return (
            <div className='row mb-4' key={index}>
              <div className='col-md-12'>
                <div className='d-flex justify-content-between mb-2'>
                  <label className="col-form-label pb-0 fw-bold">Paypal ClientID</label>
                  <button className='btn btn-sm btn-success' data-bs-toggle="modal" data-bs-target="#detailsModal" onClick={() => setEnableForEdit({ "gateway_name": g.gateway_name, "gateway_secret": g.gateway_secret, "gateway_updated_secret": "", "label": "Paypal ClientID", "placeholder": "Enter paypal ClientID", "new_gateway_secret": "" })}>Edit</button>
                </div>
                {
                  <p className='text-black px-2 py-2 mb-0 bg-light rounded-2 mb-2 border-bottom'>{g.gateway_secret}</p>
                }
              </div>
            </div>
          );
        case "Payoneer":
          return (
            <div className='row mb-4' key={index}>
              <div className='col-md-12'>
                <div className='d-flex justify-content-between mb-2'>
                  <label className="col-form-label pb-0 fw-bold">Payoneer Client Secret</label>
                  <button className='btn btn-sm btn-success' data-bs-toggle="modal" data-bs-target="#detailsModal" onClick={() => setEnableForEdit({ "gateway_name": g.gateway_name, "gateway_secret": g.gateway_secret, "gateway_updated_secret": "", "label": "Payoneer Client Secret", "placeholder": "Enter payoneer Client Secret", "new_gateway_secret": "" })}>Edit</button>
                </div>
                <p className='text-black px-2 py-2 mb-0 bg-light rounded-2 mb-2 border-bottom'>{g.gateway_secret}</p>
              </div>
            </div>
          );
        case "Crypto - Binance":
          return (
            <div className='row mb-4' key={index}>
              <div className='col-md-12'>
                <div className='d-flex justify-content-between mb-2'>
                  <label className="col-form-label pb-0 fw-bold">Binance wallet address</label>
                  <button className='btn btn-sm btn-success' data-bs-toggle="modal" data-bs-target="#detailsModal" onClick={() => setEnableForEdit({ "gateway_name": g.gateway_name, "gateway_secret": g.gateway_secret, "gateway_updated_secret": "", "label": "Binance wallet address", "placeholder": "Enter binance wallet address", "new_gateway_secret": "" })}>Edit</button>
                </div>
                <p className='text-black px-2 py-2 mb-0 bg-light rounded-2 mb-2 border-bottom'>{g.gateway_secret}</p>
              </div>
            </div>
          );
        case "Crypto - Ethereum":
          return (
            <div className='row mb-4' key={index}>
              <div className='col-md-12'>
                <div className='d-flex justify-content-between mb-2'>
                  <label className="col-form-label pb-0 fw-bold">Ethereum wallet address</label>
                  <button className='btn btn-sm btn-success' data-bs-toggle="modal" data-bs-target="#detailsModal" onClick={() => setEnableForEdit({ "gateway_name": g.gateway_name, "gateway_secret": g.gateway_secret, "gateway_updated_secret": "", "label": "Ethereum wallet address", "placeholder": "Enter ethereum wallet address", "new_gateway_secret": "" })}>Edit</button>
                </div>
                <p className='text-black px-2 py-2 mb-0 bg-light rounded-2 mb-2 border-bottom'>{g.gateway_secret}</p>
              </div>
            </div>
          );
        default:
          return null;
      }
    })
  }

  const clear = () => {
    setSelectedGateway({});
    setInputValue("");
    setTimeout(() => {
      navigate(`/dashboard`, { replace: true });
    }, 500)
  }

  const handleSaveSettings = () => {
    if (inputValue.trim() !== "" && selectedGateway.value !== "")
      savePaymentGateways(inputValue.trim(), selectedGateway.name.trim());
  }

  const savePaymentGateways = (secret, name) => {
    if (secret.trim() !== "" && name.trim() !== "") {
      const json = JSON.stringify({ "token": getCookie("token"), "secret": secret, "name": name });
      const url = `${process.env.REACT_APP_BASE_API}/api.php?action=add_gateway`;

      axios.post(url, JSON.stringify({ params: json }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then((response) => {
        if (response.data.success === "true") {
          var temp = [...savedGateways];
          temp.push({ "gateway_name": name, "gateway_secret": secret })
          setSavedGateways([...temp]);

          setSelectedGateway({});
          setInputValue("");
          handleMessage({ type: 'success', message: response.data.message, show: true });
        }
        else {
          setSelectedGateway({});
          setInputValue("");
          handleMessage({ type: 'danger', message: response.data.message, show: true });
        }
      }).catch((error) => {
        console.error(`Error: ${error}`);
      });
    }
    else {
      handleMessage({ type: 'danger', message: "Please select payment gateway and provide sceret!", show: true });
    }
  }

  const updatePaymentGateway = () => {
    if (newSecret.trim() !== "" && newSecret.trim() !== enableForEdit.gateway_secret.trim()) {
      const json = JSON.stringify({ "token": getCookie("token"), "secret": newSecret.trim(), "name": enableForEdit.gateway_name });
      const url = `${process.env.REACT_APP_BASE_API}/api.php?action=update_gateway`;

      axios.post(url, JSON.stringify({ params: json }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then((response) => {
        if (response.data.success === "true") {
          var temp = [...savedGateways];
          //temp.push({ "gateway_name": newSecret.trim(), "gateway_secret": secret })
          // console.log("filter", temp.filter(g => g.gateway_name === enableForEdit.gateway_name)[0]);
          temp.filter(g => g.gateway_name === enableForEdit.gateway_name)[0].gateway_secret = newSecret.trim();
          // console.log("temp", temp);
          setSavedGateways([...temp]);

          setTimeout(() => {
            setEnableForEdit({});
            setNewSecret("");
            handleMessage({ type: 'success', message: response.data.message, show: true });
            window["closePopup"]();
          }, 500)
        }
        else {
          setTimeout(() => {
            setEnableForEdit({});
            setNewSecret("");
            handleMessage({ type: 'danger', message: response.data.message, show: true });
            window["closePopup"]();
          }, 500)
        }
      }).catch((error) => {
        console.error(`Error: ${error}`);
      });
    }
  }

  return (
    <>
      <Header />

      <div className="content">
        <div className="container">
          <div className="row">

            <SideBar />

            <div className="col-lg-9">
              <Alert type={alert.type} message={alert.message} show={alert.show} />
              <div className="widget-title">
                <h4>Payment Settings</h4>
              </div>
              <h6 className="user-title">Add Payment Gateway</h6>
              <div className="general-info mb">
                <div className="row">

                  <div className="col-sm-6 col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">Select Payment Gateway <span className="text-danger">*</span></label>
                      <select className="form-control w-100 rounded-1" value={selectedGateway.hasOwnProperty("value") ? selectedGateway.value : ""} onChange={(e) => handleGatewaySelection(e.target.value)}>
                        <option value="">Select Gateway</option>
                        {gateways.map((gateway, index) => (
                          <option value={gateway.value} key={index}>{gateway.name}</option>
                        ))}
                        {/* <option value="paypal">Paypal</option>
                        <option value="payoneer">Payoneer</option>
                        <option value="bnb">Crypto - Binance</option>
                        <option value="eth">Crypto - Ethereum</option> */}
                      </select>
                    </div>
                  </div>

                  {
                    (selectedGateway !== "") &&
                    (
                      <div className="col-sm-6 col-md-6">
                        {CreateInputForPaymentGateway()}
                      </div>
                    )
                  }

                </div>

                <div className='row'>
                  <div className="acc-submit my-5">
                    <button className="btn btn-secondary" onClick={() => clear()}>Cancel</button>
                    {
                      (selectedGateway !== "" && inputValue.trim() !== "" && inputValue.trim().length > 10)
                        ?
                        (
                          <button className="btn btn-primary" onClick={() => handleSaveSettings()}>Save Settings</button>
                        )
                        :
                        (
                          <button className="btn btn-primary disabled">Save Settings</button>
                        )
                    }
                  </div>
                  {/* {services.length > 0 && <AddedServices />} */}
                  {/* {services.length > 0 && <AddedServices services={services} TotalServices={totalServices} ItemLimit={pageItemLimit} />} */}
                </div>

              </div>

              {
                savedGateways.length > 0 &&
                (
                  <>
                    <div className="widget-title">
                      <h4>Saved Gateways</h4>
                    </div>

                    <div className="general-info mb-4">
                      <SavedGatewaysLayout />
                    </div>
                  </>
                )
              }
            </div>

          </div>
        </div>
      </div>

      <div className="mouse-cursor cursor-outer" style={{ visibility: 'visible' }} />
      <div className="mouse-cursor cursor-inner" style={{ visibility: 'visible' }} />

      <div className="modal fade" id="detailsModal" tabIndex={-1} aria-labelledby="detailsModalLabel" aria-hidden="true" style={{ display: 'none' }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header p-0">
              <h1 className="modal-title fs-5" id="exampleModalLiveLabel">Details</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setEnableForEdit({})} />
            </div>
            <div className="modal-body px-0">
              {/* <p className='mb-0 text-black fw-bold'>Details provided by client for this order</p> */}
              <div className='row'>
                <div className='col-md-12'>
                  <div className="form-group mb-1">
                    <label className="col-form-label pb-0">{enableForEdit.label} <span className="text-danger">*</span></label>
                    <textarea className="form-control" placeholder={enableForEdit.placeholder} value={newSecret !== "" ? newSecret : enableForEdit.gateway_secret} onChange={(e) => setNewSecret(e.target.value)} />
                    {/* <p className='text-black ps-2 mb-0'>v</p> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer p-0">
              <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => setEnableForEdit({})}>Close</button>
              {
                enableUpdateButton
                  ?
                  (
                    <button type="button" className="btn btn-primary" onClick={() => updatePaymentGateway()}>Update changes</button>
                  ) : (
                    <button type="button" className="btn btn-primary disabled">Update changes</button>
                  )
              }

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PaymentSettings