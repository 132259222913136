import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';

import Header from '../../components/Header';

import { getCookie } from '../../func';

const Details = () => {
    let location = useLocation();

    const navigate = useNavigate();
    let params = useParams();

    const [packageDeails, setPackageDetails] = useState({});

    useEffect(() => {
        if (params.slug !== undefined && params.slug !== null && params.slug.toString().trim() !== "")
            fetchPackageDetails(params.slug);
        else
            navigate(`/`, { replace: true });
    }, []);

    const PageHeader = () => (
        <>
            <div className="bg-img">
                <img src="/assets/img/bg/work-bg-03.png" alt="img" className="bgimg1" />
                <img src="/assets/img/bg/work-bg-03.png" alt="img" className="bgimg2" />
                <img src="/assets/img/bg/feature-bg-03.png" alt="img" className="bgimg3" />
            </div>
            <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h2 className="breadcrumb-title">{packageDeails.package_name}</h2>
                            <nav aria-label="breadcrumb" className="page-breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to={`/service/${packageDeails.service_title}`}>{packageDeails.service_title}</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">{packageDeails.package_name}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

    const fetchPackageDetails = (slug) => {
        const json = JSON.stringify({ "package_slug": slug });
        const url = `${process.env.REACT_APP_BASE_API}/api.php?action=get_package_details`;

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                setPackageDetails(response.data.package_details[0]);
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    }

    const CreateActionButton = () => {
        if ((getCookie("token") === undefined || getCookie("token") === null)) {
            return <Link to={`/login?url=${location.pathname}`} className="btn btn-primary">Login</Link>
        }
        else {
            return <Link to={`/order_now/${params.slug}`} className="btn btn-primary">Order Now</Link>
        }
    }

    return (
        <>
            <Header />

            <PageHeader />

            <div className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="serv-profile">
                                <ul>
                                    <li>{packageDeails.service_title}</li>
                                </ul>
                                <h2>{packageDeails.package_name}</h2>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="service-gal">
                                <div className="row gx-2">
                                    <div className="col-md-8">
                                        <div className="service-images big-gallery">
                                            <img src={`${process.env.REACT_APP_BASE_API}/${packageDeails["package_title_image"]}`} className="img-fluid" alt="package img" />
                                        </div>

                                        {/* <div className="row p-2 bg-white border-1">
                                            <div className="col-lg-8"> */}
                                        <div className='card card-provide mb-0'>
                                            <div className='card-body'>
                                                <div className="service-wrap">
                                                    <h5>Package Details</h5>
                                                    {/* <p>{packageDeails["package_details"]}</p> */}
                                                    {/* <p className='package-widget' style={{paddingLeft: "20px"}} dangerouslySetInnerHTML={{__html: packageDeails["package_details"]?.replaceAll("margin: 0px; padding: 0px 0px 8px 1.3em; list-style: none;", "margin: 0px;padding: 0px 0px 8px 0;list-style: circle;")}}></p> */}
                                                    <p className='package-widget' style={{paddingLeft: "20px"}} dangerouslySetInnerHTML={{__html: packageDeails["package_details"]?.replaceAll("margin: 0px; padding: 0px 0px 8px 1.3em; list-style: none;", "margin: 0px; padding: 0px 0px 8px 1.5em; list-style: none; display: inherit;")}}></p>
                                                </div>
                                                <div className="service-wrap provide-service mt-5">
                                                    {/* <h5>Service Provider</h5> */}
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="provide-box">
                                                                {/* <span><i className="feather-map-pin" /></span> */}
                                                                <div className="provide-info">
                                                                    <h6>Average Unique Domains</h6>
                                                                    <p>{packageDeails["average_unique_domains"]}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="provide-box">
                                                                {/* <span><i className="feather-mail" /></span> */}
                                                                <div className="provide-info">
                                                                    <h6>Minimum Quantity</h6>
                                                                    <p>{packageDeails["minimum_quantity"]}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="provide-box">
                                                                {/* <span><i className="feather-phone" /></span> */}
                                                                <div className="provide-info">
                                                                    <h6>Maximum Quantity</h6>
                                                                    <p>{packageDeails["maximum_quantity"]}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* </div>
                                        </div> */}

                                    </div>
                                    <div className="col-lg-4">
                                        <div className="card card-provide mb-0">
                                            <div className="card-body">
                                                <div className="provide-widget mb-2">
                                                    <div className="service-amount">
                                                        <h5>${packageDeails["package_price"]}
                                                            {/* <span>$170</span> */}
                                                        </h5>
                                                    </div>
                                                </div>
                                                {
                                                    packageDeails["featured_extras"] !== undefined &&
                                                    <div className="package-widget">
                                                        <h5>Featured Extras</h5>
                                                        <ul>
                                                            {
                                                                JSON.parse(packageDeails["featured_extras"]).map((extras, index) => (
                                                                    <li key={index}>{extras["feature"]}</li>
                                                                ))
                                                            }
                                                            {/* <li>Full car wash and clean</li>
                                                        <li>Auto Electrical</li>
                                                        <li>Pre Purchase Inspection</li>
                                                        <li>Pre Purchase Inspection</li> */}
                                                        </ul>
                                                    </div>
                                                }

                                                <CreateActionButton />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="mouse-cursor cursor-outer" style={{ visibility: 'visible' }} />
            <div className="mouse-cursor cursor-inner" style={{ visibility: 'visible' }} />
        </>
    )
}

export default Details