import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';

import { getCookie, base64_decode, eraseCookie } from '../../func';

const SideBar = () => {
    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');

    const [activePage, setActivePage] = useState('');

    useEffect(() => {
        if (getCookie("token") !== undefined && getCookie("token") !== null) {
            var temptoken = base64_decode(getCookie("token"));

            setUsername(temptoken.split("|")[1]);
            setEmail(temptoken.split("|")[2]);
            setRole(temptoken.split("|")[3]);

            var url = document.URL;
            if (url.includes("/dashboard"))
                setActivePage("dashboard");
            else if (url.includes("/services"))
                setActivePage("services");
            else if (url.includes("/add_service"))
                setActivePage("service");
            else if (url.includes("/add_package"))
                setActivePage("package");
            else if (url.includes("/campaigns"))
                setActivePage("campaigns");
            else if (url.includes("/add_campaign"))
                setActivePage("campaign");
            else if (url.includes("/add_pressrelease_service"))
                setActivePage("pressreleaseservice");
            else if (url.includes("/add_pressrelease_package"))
                setActivePage("pressreleasepackage");
            else if (url.includes("/orders"))
                setActivePage("orders");
            else if (url.includes("/payment_settings"))
                setActivePage("paymentsettings");
        }
    }, [])

    const logout = () => {
        eraseCookie("token");

        setTimeout(() => {
            navigate(`/login`, { replace: true });
        }, 500)
    }

    return (
        <div className="col-lg-3 theiaStickySidebar">
            <div className="settings-widget">
                <div className="settings-header">
                    <div className="settings-img">
                        {/* <img src="/assets/img/profiles/avatar-02.jpg" alt="user" /> */}
                        <img src="/assets/img/icons/myself.svg" alt="user" />
                    </div>
                    <h6>{username}</h6>
                    <p>{email}</p>
                    {/* <p>Member Since Sep 2021</p> */}
                </div>
                <div className="settings-menu">
                    {
                        role === "admin" ?
                            <ul>
                                <li>
                                    <Link to={"/dashboard"} className={`${activePage === "dashboard" && "active"}`}>
                                        <i className="feather-grid" /> <span>Dashboard</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/services"} className={`${activePage === "services" && "active"}`}>
                                        <i className="feather-smartphone" /> <span>Services</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/add_service"} className={`${activePage === "service" && "active"}`}>
                                        <i className="feather-smartphone" /> <span>Add Services</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/add_package"} className={`${activePage === "package" && "active"}`}>
                                        <i className="feather-package" /> <span>Add Packages</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/campaigns"} className={`${activePage === "campaigns" && "active"}`}>
                                        <i className="feather-shopping-bag" /> <span>Campaigns</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/add_campaign"} className={`${activePage === "campaign" && "active"}`}>
                                        <i className="feather-shopping-bag" /> <span>Add Campaign</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/add_pressrelease_service"} className={`${activePage === "pressreleaseservice" && "active"}`}>
                                        <i className="feather-package" /> <span>Add Press Release Service</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/add_pressrelease_package"} className={`${activePage === "pressreleasepackage" && "active"}`}>
                                        <i className="feather-archive" /> <span>Add Press Release Package</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/orders"} className={`${activePage === "orders" && "active"}`}>
                                        <i className="feather-heart" /> <span>Orders</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/payment_settings"} className={`${activePage === "paymentsettings" && "active"}`}>
                                        <i className="feather-settings" /> <span>Payment Settings</span>
                                    </Link>
                                </li>
                                {/* <li>
                            <a href="customer-wallet.html">
                                <i className="feather-credit-card" /> <span>Wallet</span>
                            </a>
                        </li>
                        <li>
                            <a href="customer-reviews.html">
                                <i className="feather-star" /> <span>Reviews</span>
                            </a>
                        </li>
                        <li>
                            <a href="customer-chat.html">
                                <i className="feather-message-circle" /> <span>Chat</span>
                            </a>
                        </li>
                        <li>
                            <a href="customer-profile.html">
                                <i className="feather-settings" /> <span>Settings</span>
                            </a>
                        </li> */}
                                <li>
                                    {/* <a href="index.html">
                                                <i className="feather-log-out" /> <span>Logout</span>
                                            </a> */}
                                    <button className='btn-logout' onClick={() => logout()}>
                                        <i className="feather-log-out" /> <span>Logout</span>
                                    </button>
                                </li>
                            </ul>
                            :
                            <ul>
                                <li>
                                    <Link to={"/dashboard"} className="active">
                                        <i className="feather-grid" /> <span>Dashboard</span>
                                    </Link>
                                </li>
                                <li>
                                    <button className='btn-logout' onClick={() => logout()}>
                                        <i className="feather-log-out" /> <span>Logout</span>
                                    </button>
                                </li>
                            </ul>
                    }
                </div>
            </div>
        </div>
    )
}

export default SideBar