import React from 'react'

const Statistics = () => {
    return (
        <section className="work-section"> {/**works-eight-section */}
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <div className="section-heading section-heading-eight aos aos-init aos-animate" data-aos="fade-up">
                            <img src="/assets/img/logo.png" alt="logo" loading='lazy'/>
                            <h2>Monklink</h2>
                            <p>The #1 SEO panel on the planet</p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center aos aos-init aos-animate" data-aos="fade-up">
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="works-eights-main">
                            <div className="works-eights-img">
                                {/* <img src="/assets/img/icons/gui-calendar-planner-eight.svg" alt="image" /> */}
                                <i className="feather-users" style={{fontSize: "3.5rem", fontWeight: 800, color: "#4e42eb"}}></i>
                                <div className="works-eights-arrow">
                                    <img src="/assets/img/icons/arrow-eight-1.svg" alt="customer" />
                                </div>
                            </div>
                            <h4>205,375</h4>
                            <h2 style={{color: "#4e42eb"}}>Customers</h2>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="works-eights-main">
                            <div className="works-eights-img">
                                {/* <img src="/assets/img/icons/pointer-eight.svg" alt="image" /> */}
                                <i className="feather-clock" style={{fontSize: "3.5rem", fontWeight: 800, color: "#4e42eb"}}></i>
                                <div className="works-eights-arrow works-eights-arrow-two">
                                    <img src="/assets/img/icons/arrow-eight-2.svg" alt="market" />
                                </div>
                            </div>
                            <h4>6+ Years</h4>
                            <h2 style={{color: "#4e42eb"}}>On Market</h2>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="works-eights-main">
                            <div className="works-eights-img">
                                {/* <img src="/assets/img/icons/dog-face-eight.svg" alt="image" /> */}
                                <i className="feather-globe" style={{fontSize: "3.5rem", fontWeight: 800, color: "#4e42eb"}}></i>
                                <div className="works-eights-arrow">
                                    <img src="/assets/img/icons/arrow-eight-1.svg" alt="countries" />
                                </div>
                            </div>
                            <h4>70+</h4>
                            <h2 style={{color: "#4e42eb"}}>Countries</h2>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="works-eights-main">
                            <div className="works-eights-img">
                                {/* <img src="/assets/img/icons/pay-per-eight.svg" alt="image" /> */}
                                <i className="feather-check-circle" style={{fontSize: "3.5rem", fontWeight: 800, color: "#4e42eb"}}></i>
                            </div>
                            <h4>10B+ Backlinks</h4>
                            <h2 style={{color: "#4e42eb"}}>Created Monthly</h2>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Statistics