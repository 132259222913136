import React, { useEffect, useRef } from 'react'

const Alert = ({ type, message, show }) => {
    const myRef = useRef(null)

    useEffect(() => {
        if (show)
            executeScroll();
    }, [show])

    const executeScroll = () => myRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    // const executeScroll = () => window.scrollTo(0, 0);

    return (
        <>
            {(show && type === "success") && (
                <div className="alert alert-success" role="alert" ref={myRef}>
                    {message}
                </div>
            )}
            {(show && type === "danger") && (
                <div className="alert alert-danger" role="alert" ref={myRef}>
                    {message}
                </div>
            )}
            {(show && type === "warning") && (
                <div className="alert alert-warning" role="alert" ref={myRef}>
                    {message}
                </div>
            )}
            {(show && type === "info") && (
                <div className="alert alert-info" role="alert" ref={myRef}>
                    {message}
                </div>
            )}
        </>
    )
}

export default Alert