import React from 'react'
import './style.css';

const ForEveryone = () => {
    return (
        <>
            {/* <section className="feature-section">
                <div className="container">
                    <div className="section-heading">
                        <div className="row align-items-center">
                            <div className="col-md-12 aos aos-init aos-animate" data-aos="fade-up">
                                <h2>Featured Categories</h2>
                                <p>What do you need to find?</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <div className="feature-box aos aos-init aos-animate" data-aos="fade-up">
                                <h5>Construction</h5>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="feature-box aos aos-init aos-animate" data-aos="fade-up">
                                <h5>Car Wash</h5>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="feature-box aos aos-init aos-animate" data-aos="fade-up">
                                <h5>Electrical</h5>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="feature-box aos aos-init aos-animate" data-aos="fade-up">
                                <h5>Cleaning</h5>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="feature-box aos aos-init aos-animate" data-aos="fade-up">
                                <h5>Interior</h5>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="feature-box aos aos-init aos-animate" data-aos="fade-up">
                                <h5>Carpentry</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className="customer-reviews-six-section">
                <div className="container">
                    <div className="section-heading section-heading-six">
                        <div className="row align-items-end">
                            <div className="col-md-12 aos aos-init aos-animate" data-aos="fade-up">
                                <h2>A Novel Take on Contemporary SEO Services</h2>
                                <p>Boost Your Online Presence and Get Discovered More Often</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 col-12 mb-4" data-aos="fade-up">
                            <div className="customer-review-main-six h-100" style={{ backgroundColor: "#4c40ed" }}>
                                <div className="customer-review-top customer-review-top-white h-100 pb-0">
                                    <h5 className='text-white'>Comprehensive SEO Audits</h5>
                                    <p className='text-white'>Evaluating current website performance and identifying areas for enhancement. Developing a strategy to improve visibility and rankings.</p>
                                </div>
                                {/* <div className="customer-review-quote">
                                    <img src="assets/img/icons/reviews-quote.svg" alt="image" />
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 mb-4" data-aos="fade-up">
                            <div className="customer-review-main-six h-100" style={{ backgroundColor: "#4c40ed" }}>
                                <div className="customer-review-top customer-review-top-white h-100 pb-0">
                                    <h5 className='text-white'>Keyword Research and Optimization</h5>
                                    <p className='text-white'>Conducting thorough keyword research to identify relevant and high-performing keywords. Ensuring content aligns with the target audience's search queries.</p>
                                </div>
                                {/* <div className="customer-review-quote">
                                    <img src="assets/img/icons/reviews-quote.svg" alt="image" />
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 mb-4" data-aos="fade-up">
                            <div className="customer-review-main-six h-100" style={{ backgroundColor: "#4c40ed" }}>
                                <div className="customer-review-top customer-review-top-white h-100 pb-0">
                                    <h5 className='text-white'>On-Page SEO</h5>
                                    <p className='text-white'>Including meta tag optimization, content enhancement, and site architecture improvements.</p>
                                </div>
                                {/* <div className="customer-review-quote">
                                    <img src="assets/img/icons/reviews-quote.svg" alt="image" />
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 mb-4" data-aos="fade-up">
                            <div className="customer-review-main-six h-100" style={{ backgroundColor: "#4c40ed" }}>
                                <div className="customer-review-top customer-review-top-white h-100 pb-0">
                                    <h5 className='text-white'>Off-Page SEO and Link Building</h5>
                                    <p className='text-white'>Building high-quality backlinks from reputable sources to boost your website’s credibility and rankings.</p>
                                </div>
                                {/* <div className="customer-review-quote">
                                    <img src="assets/img/icons/reviews-quote.svg" alt="image" />
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 mb-4" data-aos="fade-up">
                            <div className="customer-review-main-six h-100" style={{ backgroundColor: "#4c40ed" }}>
                                <div className="customer-review-top customer-review-top-white h-100 pb-0">
                                    <h5 className='text-white'>Content Creation and Marketing</h5>
                                    <p className='text-white'>Creating engaging, informative, SEO-optimized content that attracts and retains your audience while boosting your search engine rankings.</p>
                                </div>
                                {/* <div className="customer-review-quote">
                                    <img src="assets/img/icons/reviews-quote.svg" alt="image" />
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 mb-4" data-aos="fade-up">
                            <div className="customer-review-main-six h-100" style={{ backgroundColor: "#4c40ed" }}>
                                <div className="customer-review-top customer-review-top-white h-100 pb-0">
                                    <h5 className='text-white'>Local SEO</h5>
                                    <p className='text-white'>Targeting local search results to attract customers in your geographical area means ensuring that you stand out in local searches and directories.</p>
                                </div>
                                {/* <div className="customer-review-quote">
                                    <img src="assets/img/icons/reviews-quote.svg" alt="image" />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ForEveryone