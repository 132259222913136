import React from 'react'
import Header from '../../components/Header';
import ReviewsSection from '../../components/ReviewsSection';
import Footer from '../../components/Footer';

const PromoteBrand = () => {
    return (
        <>
            <Header />

            <section className="hero-section" style={{ minHeight: "350px" }}>
                <div className="container">
                    <div className="home-banner">
                        <div className="row w-100">
                            <div className="col-lg-6 col-md-10 mx-auto">
                                <div className="section-search aos aos-init aos-animate" data-aos="fade-up">
                                    {/* <h1>Are You Looking for <br /><b>Effective SEO Services?</b></h1> */}
                                    <h1>Promote Your Brand</h1>
                                    <p className="mb-3 text-black fw-bold">We support your success!</p>
                                    <p className="mb-5">We are the ideal choice for companies looking to expand their brand, regardless of size or financial situation.</p>

                                    <a className="btn btn-primary" href="#"><i className="feather-user me-2" />Get Started!</a>

                                </div>
                            </div>
                            <div className="col-lg-6 d-flex justify-content-center">
                                <img src="https://panel.seoestore.net/assets/img/pages/push-your-brand.png" className="img-fluid aos aos-init aos-animate" alt="img" data-aos="fade-up" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="work-section py-5"> {/**works-eight-section */}
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="section-heading section-heading-eight aos aos-init aos-animate" data-aos="fade-up">
                                <h2 style={{ color: "#5347e9" }}>Promote Your Brand—We'll Help You Expand!</h2>
                                {/* <p className='w-75 mx-auto'>Start getting real exposure for your brand. We'll take care of it for you. We can get an article written and published on the network of over 200+ news sites, all done within just 5 days.</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center aos aos-init aos-animate" data-aos="fade-up">
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="works-eights-main">
                                <div className="works-eights-img">
                                    <img src="https://panel.seoestore.net/assets/img/pages/features-icons/google-ranking.png" alt="steps" className='img-fluid w-75' />
                                </div>
                                <h3 style={{ color: "#4e42eb" }}>Place first on Google</h3>
                                <p className='text-start text-md-center'>SEO provides a means of elevating your brand name to the top if it is not currently displayed on the first page of Google search results.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="works-eights-main">
                                <div className="works-eights-img">
                                    <img src="https://panel.seoestore.net/assets/img/pages/features-icons/real-exposure.png" alt="steps" className='img-fluid w-75' />
                                </div>
                                <h3 style={{ color: "#4e42eb" }}>Actual visibility</h3>
                                <p className='text-start text-md-center'>Every month, 100 million people read our network of 200 well-known news websites. We help you be published to increase your visibility and search engine rankings.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="works-eights-main">
                                <div className="works-eights-img">
                                    <img src="https://panel.seoestore.net/assets/img/pages/features-icons/trust-badge.png" alt="steps" className='img-fluid w-75' />
                                </div>
                                <h3 style={{ color: "#4e42eb" }}>"As Seen On" badge of trust</h3>
                                <p className='text-center text-md-start'>By using social proof such as "As Seen On NBC, FOX, CBS" and more than 100 other websites, you may rapidly increase website conversions by up to 48%.</p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row py-4">
                        <div className="col-md-12 text-center">
                            <div className="section-heading section-heading-eight aos aos-init aos-animate mb-0" data-aos="fade-up">
                                <p className='mb-3'>Reach the market players and create a powerful market impact!</p>
                                <a className="btn btn-primary" href="#">Place Your Order Now!</a>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>

            <section className="work-section pt-5 pb-5" style={{ backgroundColor: "#ffffff" }}>
                <div className="container">

                    <div className="row mb-4">
                        <div className="col-md-12 text-center">
                            <div className="section-heading section-heading-eight aos aos-init aos-animate mb-0" data-aos="fade-up">
                                {/* <img src="/assets/img/logo.png" alt="logo" loading="lazy" /> */}
                                <h2>Companies of All Sizes: Use MonkLink to Expand Your Brand</h2>
                                <p className='w-75 mx-auto'>We'll generate favorable publicity for your business, which will boost its standing online and help establish your website as a reliable resource for information about your goods and services.</p>
                            </div>
                        </div>
                    </div>

                    {/* <div className="row pt-5 mb-0 mb-md-5"> */}
                    <div className="row align-items-center aos aos-init aos-animate" data-aos="fade-up">
                        <div className="col-lg-6 col-12">
                            <div className="pets-content-all">
                                <h3>Select a Featured Ranking Service to increase your internet visibility</h3>
                                <p>Make your order with only a few simple clicks:</p>
                                <ul className='my-4'>
                                    <li style={{ listStyleType: 'disclosure-closed', listStylePosition: 'inside', color: "#4c40ed", marginBottom: "4px" }}><span className='text-muted fw-bold'>Select the Press Release or SEO Campaigns PRO option</span></li>
                                    <li style={{ listStyleType: 'disclosure-closed', listStylePosition: 'inside', color: "#4c40ed", marginBottom: "4px" }}><span className='text-muted fw-bold'>Enter your keywords and link(s)</span></li>
                                    <li style={{ listStyleType: 'disclosure-closed', listStylePosition: 'inside', color: "#4c40ed", marginBottom: "4px" }}><span className='text-muted fw-bold'>Send in your order</span></li>
                                    <li style={{ listStyleType: 'disclosure-closed', listStylePosition: 'inside', color: "#4c40ed", marginBottom: "4px" }}><span className='text-muted fw-bold'>Examine your reports to ensure flawless outcomes</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="healthy-pets-img d-flex justify-content-end">
                                <img src="https://panel.seoestore.net/assets/img/pages/features/push-your-brand%20.png" alt="get seen" className="img-fluid w-75" />
                            </div>
                        </div>
                    </div>
                    {/* </div> */}

                </div>
            </section>

            <section className="client-sections review-four">
                <div className="container">
                    <div className="services-header aos aos-init aos-animate" data-aos="fade-up">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-content section-client mb-0">
                                    <h2>Highlighted Branding Services</h2>
                                    <p>By using our services, you can rank your brand name with SEO campaigns, gain some widely distributed news links, and give your brand a big boost.</p>
                                    {/* <a className="read-more align-self-center px-5 mt-0 mt-md-4" href="#">Start Your SEO Campaign, NOW!</a> */}
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-md-4 offset-md-2">
                                <div className="work-box aos aos-init aos-animate h-100 mb-0 d-flex flex-column justify-content-between p-2 rounded-2" data-aos="fade-up">
                                    {/* <span className="heading-style styled aqua align-self-center">Rank Higher on Google</span> */}
                                    <img src="https://panel.seoestore.net/assets/img/seo-services/seo-campaigns.jpg" className='rounded-top mb-4' alt="seo campaign"/>
                                    {/* <p className="my-3">SEO Campaign</p> */}
                                    <h3 className="title fs-4"><a href="/campaign/seo-campaign">SEO Campaign</a></h3>
                                    {/* <a className="read-more align-self-center" href="/">Read More <i className="feather-arrow-right-circle ms-2"></i></a> */}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="work-box aos aos-init aos-animate h-100 mb-0 d-flex flex-column justify-content-between p-2 rounded-2" data-aos="fade-up">
                                    {/* <span className="heading-style styled aqua align-self-center">Rank Higher on Google</span> */}
                                    <img src="https://panel.seoestore.net/assets/img/seo-services/press-release-1.jpg" className='rounded-top mb-4' alt="press release"/>
                                    {/* <p className="my-3">Press Release</p> */}
                                    <h3 className="title fs-4"><a href="/pressrelease">Press Release</a></h3>
                                    {/* <a className="read-more align-self-center" href="/">Read More <i className="feather-arrow-right-circle ms-2"></i></a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <ReviewsSection />

            <Footer />

            <div className="mouse-cursor cursor-outer" style={{ visibility: 'visible' }} />
            <div className="mouse-cursor cursor-inner" style={{ visibility: 'visible' }} />
        </>
    )
}

export default PromoteBrand