import React from 'react'
import './style.css';

import { Link } from 'react-router-dom';

const Hero = () => {

    return (
        <section className="hero-section">
            <div className="container">
                <div className="home-banner">
                    <div className="row w-100"> {/** row align-items-center w-100 */}
                        <div className="col-lg-7 col-md-10 mx-auto">
                            <div className="section-search aos aos-init aos-animate mt-md-5" data-aos="fade-up">
                                {/* <h1>Increase your <span className="text-spin styled aqua ms-2 ms-md-3"></span><br />with <b>Our Seo Service</b></h1> */}
                                <h1>Are You Looking for <br /><b>Effective SEO Services?</b></h1>
                                {/* <p className='pb-md-5'>Boost Your Rank - Get Organic Traffic</p>

                                <h3 className='mt-5'>Drive qualified buyers to your website by getting Quality Backlinks for SEO</h3> */}
                                {/* <h3 className='mt-5'>Boost Your Rank - Get Organic Traffic</h3>
                                <p className='mb-3'>Drive qualified buyers to your website by getting Quality Backlinks for SEO</p> */}
                                <h3 className='mt-5'>You Can Easily Rank Higher and Attract Organic Traffic</h3>
                                <p className='mb-3'>MonkLink Provides Smart SEO Solutions to Solve All Your Problems</p>

                                <Link className="btn btn-primary" to={"/register"}>
                                    <i className="feather-user me-2" />Get Free Access
                                </Link>
                                <p className='mt-3'>Create your free account (No credit card required!)</p>
                                {/* <div className="search-box">
                                    <form action="search.html">
                                        <div className="search-input line">
                                            <div className="search-group-icon">
                                                <i className="feather-map-pin" />
                                            </div>
                                            <div className="form-group mb-0">
                                                <label>Your Location</label>
                                                <input type="text" className="form-control" placeholder="America" />
                                            </div>
                                        </div>
                                        <div className="search-input">
                                            <div className="search-group-icon search-icon">
                                                <i className="feather-search" />
                                            </div>
                                            <div className="form-group mb-0">
                                                <label>What are you looking for?</label>
                                                <input type="text" className="form-control" placeholder="Car Repair Services" />
                                            </div>
                                        </div>
                                        <div className="search-btn">
                                            <button className="btn btn-primary" type="submit"><i className="feather-search me-2" />Search</button>
                                        </div>
                                    </form>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="banner-imgs">
                                <div className="banner-1 shape-1">
                                    {/* <img className="img-fluid" alt="banner" src="assets/img/banner1.png" /> */}
                                    <img className="img-fluid" alt="banner" src="assets/img/banner1.svg" loading='lazy' />
                                </div>
                                <div className="banner-2 shape-3">
                                    {/* <img className="img-fluid" alt="banner" src="assets/img/banner2.png" /> */}
                                    <img className="img-fluid" alt="banner" src="assets/img/banner2.svg" loading='lazy' />
                                </div>
                                <div className="banner-3 shape-3">
                                    {/* <img className="img-fluid" alt="banner" src="assets/img/banner3.png" /> */}
                                    <img className="img-fluid" alt="banner" src="assets/img/banner3.svg" loading='lazy' />
                                </div>
                                <div className="banner-4 shape-2">
                                    {/* <img className="img-responsive" alt="banner" src="assets/img/banner4.png" /> */}
                                    <img className="img-responsive" alt="banner" src="assets/img/banner4.svg" loading='lazy' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero