import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Header from '../../../components/Header';
import SideBar from '../../../components/SideBar';

import { getCookie, checkRole } from '../../../func';
import NormalOrders from '../../../components/NormalOrders';
import CampaignOrders from '../../../components/CampaignOrders';

const pageItemLimit = 50;

const Orders = () => {
    const navigate = useNavigate();
    const [orders, setOrders] = useState([]);
    const [campaignOrders, setCampaignOrders] = useState([]);
    const [totalOrders, setTotalOrders] = useState([]);
    const [totalCampaignOrders, setTotalCampaignOrders] = useState(undefined);

    const [selectedExtras, setSelectedExtras] = useState([]);
    const [selectedOrderDetails, setSelectedOrderDetails] = useState([]);

    const [selectedPackageServices, setSelectedPackageServices] = useState([]);

    useEffect(() => {
        if (getCookie("token") !== undefined && getCookie("token") !== null) {
            if (checkRole(getCookie("token")) !== "admin")
                navigate(`/dashboard`, { replace: true });
            else
                fetchOrders();
        }
        else {
            navigate(`/login`, { replace: true });
        }
    }, [])

    const fetchOrders = () => {
        const json = JSON.stringify({ "token": getCookie("token"), "limit": pageItemLimit, "offset": 0 });
        const url = `${process.env.REACT_APP_BASE_API}/api.php?action=get_dashboard_stats`;

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                // console.log("data:", response.data);
                setOrders(response.data.orders);
                setCampaignOrders(response.data.campaign_orders);
                setTotalOrders(response.data.total_orders);
                setTotalCampaignOrders(response.data.total_campaign_orders)
                // console.log("data:", response.data.orders);
                // response.data.orders.forEach((o)=>{
                //     console.log("o: ", JSON.parse(o.order_details));
                // })
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    }

    const showOrderDetails = (order_details) => {
        // // console.log("order_details: ", JSON.parse(order_details));
        // setSelectedExtras([...JSON.parse(order_details)["extras"]]);
        // // console.log("order_details: ", JSON.parse(order_details)["orderDetails"]);
        // setSelectedOrderDetails([...JSON.parse(order_details)["orderDetails"]])

        var tempDetails = JSON.parse(order_details);
        setSelectedExtras(tempDetails.hasOwnProperty("extras") ? [...tempDetails["extras"]] : []);
        setSelectedOrderDetails(tempDetails.hasOwnProperty("orderDetails") ? [...tempDetails["orderDetails"]] : []);
        setSelectedPackageServices(tempDetails.hasOwnProperty("selectedCampaign") ? [...tempDetails["selectedCampaign"]] : []);
    }

    const handleCloseModal = () => {
        setTimeout(() => {
            setSelectedOrderDetails([]);
            setSelectedExtras([]);
        }, 100)
    }

    return (
        <>
            <Header />

            <div className="content">
                <div className="container">
                    <div className="row">

                        <SideBar />

                        <div className="col-lg-9">
                            <NormalOrders orders={orders} showOrderDetails={showOrderDetails} TotalOrders={totalOrders} ItemLimit={pageItemLimit} />

                            <CampaignOrders orders={campaignOrders} showOrderDetails={showOrderDetails} TotalOrders={totalCampaignOrders} ItemLimit={pageItemLimit} />
                        </div>

                    </div>
                </div>
            </div>

            <div className="mouse-cursor cursor-outer" style={{ visibility: 'visible' }} />
            <div className="mouse-cursor cursor-inner" style={{ visibility: 'visible' }} />

            <div className="modal fade" id="detailsModal" tabIndex={-1} aria-labelledby="detailsModalLabel" aria-hidden="true" style={{ display: 'none' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header p-0">
                            <h1 className="modal-title fs-5" id="exampleModalLiveLabel">Order Details</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { handleCloseModal() }} />
                        </div>
                        <div className="modal-body px-0">
                            <p className='mb-0 text-black fw-bold'>Details provided by client for this order</p>
                            <div className='row'>
                                {
                                    selectedOrderDetails.map((o, index) => (
                                        <div className='col-md-6' key={index}>
                                            <div className="form-group mb-1">
                                                <label className="col-form-label pb-0">{o.label} <span className="text-danger">*</span></label>
                                                {/* <input type="text" className="form-control" placeholder="Enter Your Name" /> */}
                                                {o.userinput.split("^").map((v, i) => (
                                                    <p className='text-black ps-2 mb-0' key={i}>{v}</p>
                                                ))}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            {
                                selectedPackageServices.length > 0 && (
                                    <div className='row'>
                                        <div className="col-md-12">
                                            <div className="form-group mb-1">
                                                <label className="col-form-label pb-0 text-black fw-bold">Selected campaign details</label>
                                                <ul className="booking-date">
                                                    {selectedPackageServices.map((pkg, index) => (
                                                        <li key={index} className='d-flex flex-column'>
                                                            {pkg.tier !== "" && <span className='mb-0 align-self-start'>{pkg.tier}</span>}
                                                            <div className='d-flex'>
                                                                <p className='mb-0' style={{ minWidth: "50px" }}>{pkg.quantity}</p>
                                                                <div className='d-flex flex-column align-items-start'>
                                                                    <span className='text-black'>{pkg.service}</span>
                                                                    <span>{pkg.details}</span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                selectedExtras.length > 0 && (
                                    <div className='row'>
                                        <div className="col-md-12">
                                            <div className="form-group mb-1">
                                                <label className="col-form-label pb-0 text-black fw-bold">Selected Extra Service in this order</label>
                                                {
                                                    selectedExtras.map((extra, index) => {
                                                        //return <p className="text-black ps-2 mb-0">{extra["feature"]} - {extra["price"]}</p>
                                                        return <span className="d-flex w-100 justify-content-between pe-4">
                                                            <p className="text-black ps-2 mb-0">{extra["feature"]}</p> <p className="text-black ps-2 mb-0">Price: ${extra["price"]}</p>
                                                        </span>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className="modal-footer p-0">
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => { handleCloseModal() }}>Close</button>
                            {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Orders