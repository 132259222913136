import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

import Header from '../../components/Header'
import Alert from '../../components/Alert';

import { getCookie, setCookie, checkRole } from '../../func';

const PasswordRecovery = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');

    const [alert, setAlert] = useState({
        type: '',
        message: '',
        show: false
    });

    useEffect(() => {
        //console.log(checkRole(getCookie("token")));
        if (getCookie("token") !== undefined && getCookie("token") !== null) {
            //user is already loggedin
            if (checkRole(getCookie("token")) === "admin")
                navigate(`/dashboard`, { replace: true });
            else
                navigate(`/dashboard`, { replace: true });
        }
    }, [])

    const handleMessage = (params) => {
        setAlert({ type: params.type, message: params.message, show: true });

        setTimeout(() => {
            setAlert({ type: '', message: '', show: false });
        }, 2500);
    }

    const ForgotPassword = () => {
        const json = JSON.stringify({ "email": email });
        const url = `${process.env.REACT_APP_BASE_API}/api.php?action=forgotpassword`;

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        }).then((response) => {
            if (response.data.success === "true") {
                handleMessage({ type: 'success', message: response.data.message, show: true });

                setTimeout(() => {
                    navigate(`/`, { replace: true });
                }, 3000);
            }
            else {
                handleMessage({ type: 'danger', message: response.data.message, show: true });
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
            handleMessage({ type: 'danger', message: "Something went wrong. Please try later", show: true });
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (email.trim() !== "" && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            ForgotPassword();
        }
        else {
            handleMessage({ type: 'info', message: "Please provide valid email address", show: true });
        }
    }

    return (
        <>
            <Header />

            <div className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-6 mx-auto">
                            <div className="login-wrap">
                                <Alert type={alert.type} message={alert.message} show={alert.show} />
                                <div className="login-header mb-3">
                                    <h3 className='mb-1'>Password Recovery</h3>
                                    <p className='mb-0'>Enter your email and we will send you a link to reset your password.</p>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="log-form pb-0">
                                        <div className="form-group">
                                            <label className="col-form-label">Email</label>
                                            <input type="text" className="form-control" placeholder="johndoe@example.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                                        </div>
                                    </div>
                                    <button className="btn btn-primary w-100 login-btn" type="submit">Submit</button>
                                    <p className="no-acc mt-0">Remember Password ? <Link to={"/login"}>Login</Link></p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mouse-cursor cursor-outer" style={{ visibility: 'visible' }} />
            <div className="mouse-cursor cursor-inner" style={{ visibility: 'visible' }} />
        </>
    )
}

export default PasswordRecovery