import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';

import Header from '../../../components/Header'
import SideBar from '../../../components/SideBar'
// import AddedServices from '../../../components/AddedServices'

import Alert from '../../../components/Alert';

import { getCookie, checkRole } from '../../../func'
import AddedCampaigns from '../../../components/AddedCampaigns';

const pageItemLimit = 10;

const Campaigns = () => {
    const navigate = useNavigate();

    const [savedCampaigns, setSavedCampaigns] = useState([]);
    const [totalCampaigns, setTotalCampaigns] = useState([]);
    const [alert, setAlert] = useState({
        type: '',
        message: '',
        show: false
    });

    const handleMessage = (params) => {
        setAlert({ type: params.type, message: params.message, show: true });

        setTimeout(() => {
            setAlert({ type: '', message: '', show: false });
        }, 3000);
    }

    useEffect(() => {
        if (getCookie("token") !== undefined && getCookie("token") !== null) {
            if (checkRole(getCookie("token")) !== "admin")
                navigate(`/dashboard`, { replace: true });
            else {
                fetchCampaigns();
            }
        }
        else {
            navigate(`/login`, { replace: true });
        }
    }, []);

    const fetchCampaigns = () => {
        const json = JSON.stringify({ "token": getCookie("token"), "limit": pageItemLimit, "offset": 0 });
        const url = `${process.env.REACT_APP_BASE_API}/api.php?action=get_campaigns`;

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                setTotalCampaigns(response.data.total_campaigns);
                setSavedCampaigns(response.data.campaigns);
            }
            else {
                handleMessage({ type: 'danger', message: response.data.message, show: true });
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    }

    return (
        <>
            <Header />

            <div className="content">
                <div className="container">
                    <div className="row">

                        <SideBar />

                        <div className="col-lg-9">
                            <Alert type={alert.type} message={alert.message} show={alert.show} />
                            
                            <AddedCampaigns campaigns={savedCampaigns} TotalCampaigns={totalCampaigns} ItemLimit={pageItemLimit}/>
                        </div>

                    </div>
                </div>
            </div>

            <div className="mouse-cursor cursor-outer" style={{ visibility: 'visible' }} />
            <div className="mouse-cursor cursor-inner" style={{ visibility: 'visible' }} />
        </>
    )
}

export default Campaigns