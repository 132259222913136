import React from 'react';
import { Base64 } from "js-base64";

const secret = 'with123flying456colors';

const appendScript = (scriptToAppend, scriptToAppendID) => {
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = true;

    if (scriptToAppendID !== undefined)
        script.id = scriptToAppendID;

    document.body.appendChild(script);
}

const removeScript = (scriptToRemoveID) => {
    var elem = document.getElementById(scriptToRemoveID);

    if (elem !== null)
        elem.remove();
}

const appendStylesheet = (scriptToAppend, scriptToAppendID) => {
    const link = document.createElement("link");
    link.href = scriptToAppend;

    if (scriptToAppendID !== undefined)
        link.id = scriptToAppendID;

    document.body.appendChild(link);
}

const removeStylesheet = (scriptToRemoveID) => {
    var elem = document.getElementById(scriptToRemoveID);

    if (elem !== null)
        elem.remove();
}

const setCookie = (name, value, days) => {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

const getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

const eraseCookie = (name) => {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

const checkRole = (token) => {
    //return window.atob(window.atob(token)).split("|")[3];
    //console.log(base64_decode(token).split("|"));
    return base64_decode(token).split("|")[3];
}

const base64_encode = (value) => {
    if (value.trim() === "")
        return null;

    return Base64.encode(value);
}

const isBase64 = (str) => {
    if (str === '' || str.trim() === '') { return false; }
    try {
        return btoa(atob(str)) === str;
    } catch (err) {
        return false;
    }
}

const base64_decode = (value) => {
    if (value.trim() === "")
        return null;

    if (isBase64(value)) {
        // console.log(value, "is base64");
        if (isBase64(Base64.decode(value))) {
            // console.log(Base64.decode(value), "is also base64");
            return Base64.decode(Base64.decode(value));
        }
        else if (!isBase64(Base64.decode(value))) {
            return Base64.decode(value);
        }
    }
    //return Base64.decode(Base64.decode(value));
}

const validate_textfield = (text) => {
    if (text != null && text.toString().trim() !== "")
        return true;

    return false;
}

const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
}

const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" }
    return new Date(dateString).toLocaleDateString(undefined, options)
}

const addScript = (url, id, integrity, async = true, crossOrigin = "anonymous") => {
    const script = document.createElement("script");
    script.src = url;
    script.id = id;

    if (async)
        script.async = async;

    if (integrity)
        script.integrity = integrity;

    script.crossOrigin = crossOrigin;
    document.body.appendChild(script);
}

const removeTags = (str) => {
    if ((str === null) || (str === ''))
        return false;
    else
        str = str.toString();

    return str.replace(/(<([^>]+)>)/ig, '');
}

const getWords = (str, limit) => {
    return str.split(/\s+/).slice(0, limit).join(" ");
}

// const cipher = salt => {
//     const textToChars = text => text.split('').map(c => c.charCodeAt(0));
//     const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
//     const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);

//     return text => text.split('')
//         .map(textToChars)
//         .map(applySaltToChar)
//         .map(byteHex)
//         .join('');
// }

// const decipher = salt => {
//     const textToChars = text => text.split('').map(c => c.charCodeAt(0));
//     const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
//     return encoded => encoded.match(/.{1,2}/g)
//         .map(hex => parseInt(hex, 16))
//         .map(applySaltToChar)
//         .map(charCode => String.fromCharCode(charCode))
//         .join('');
// }

const encrypt = (salt, text) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);

    return text
        .split("")
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join("");
};

const decrypt = (salt, encoded) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded
        .match(/.{1,2}/g)
        .map((hex) => parseInt(hex, 16))
        .map(applySaltToChar)
        .map((charCode) => String.fromCharCode(charCode))
        .join("");
};

const returnTextToCipher = (text) => {
    // // To create a cipher
    // const myCipher = cipher(secret)

    // //Then cipher any text:
    // return myCipher(text);

    return encrypt(secret, text);
}

const returnCipherToText = (cipher) => {
    // //To decipher, you need to create a decipher and use it:
    // const myDecipher = decipher(secret);

    // myDecipher(cipher)

    return decrypt(secret, cipher);
}

export {
    appendScript,
    removeScript,
    setCookie,
    getCookie,
    eraseCookie,
    checkRole,
    base64_encode,
    base64_decode,
    validate_textfield,
    openInNewTab,
    formatDate,
    addScript,
    appendStylesheet,
    removeStylesheet,
    removeTags,
    getWords,
    returnTextToCipher,
    returnCipherToText
}