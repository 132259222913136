import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

import Header from '../../components/Header';
import Alert from '../../components/Alert';

import { getCookie, setCookie, checkRole } from '../../func';

const Reqister = () => {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [alert, setAlert] = useState({
        type: '',
        message: '',
        show: false
    });

    useEffect(() => {
        if (getCookie("token") !== undefined && getCookie("token") !== null) {
            //user is already loggedin
            if (checkRole(getCookie("token")) === "admin")
                navigate(`/dashboard`, { replace: true });
            else
                navigate(`/dashboard`, { replace: true });
        }
    }, [])

    const handleMessage = (params) => {
        setAlert({ type: params.type, message: params.message, show: true });

        setTimeout(() => {
            setAlert({ type: '', message: '', show: false });
        }, 3200);
    }

    const Register = () => {
        const json = JSON.stringify({ "username": name, "email": email, "password": password });
        const url = `${process.env.REACT_APP_BASE_API}/api.php?action=register`;

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                handleMessage({ type: 'success', message: response.data.message, show: true });

                setTimeout(() => {
                    navigate(`/login`, { replace: true });
                }, 3500);
            }
            else {
                handleMessage({ type: 'danger', message: response.data.message, show: true });
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
            handleMessage({ type: 'danger', message: "Something went wrong. Please try later", show: true });
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (name.trim() !== "" && /^[a-zA-Z0-9 ]+$/.test(name) && email.trim() !== "" && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) && password.trim() !== "") {
            Register();
        }
        else {
            if (name.trim() === "")
                handleMessage({ type: 'info', message: "Please provide name", show: true });//console.log("name is required");
            else if (!/^[a-zA-Z ]+$/.test(name))
                handleMessage({ type: 'info', message: "Please provide valid name without special character and numbers", show: true });//console.log("Invalid user name");
            else if (email.trim() === "")
                handleMessage({ type: 'info', message: "Please provide email address", show: true });//console.log("email is required");
            else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email))
                handleMessage({ type: 'info', message: "Please provide valid email address", show: true });//console.log("invalid email address");
            else if (password.trim() !== "")
                handleMessage({ type: 'info', message: "Please provide password", show: true });//console.log("password is required");
            else
                handleMessage({ type: 'info', message: "Please provide valid name, email and password", show: true });//console.log("Please provide valid name, email and password");
        }
    }

    return (
        <>
            <Header />

            <div className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-6 mx-auto">
                            <div className="login-wrap">
                                <Alert type={alert.type} message={alert.message} show={alert.show} />
                                <div className="login-header mb-3">
                                    <h3 className='mb-1'>Register</h3>
                                    <p className='mb-0'>Please enter your details</p>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label className="col-form-label">Name</label>
                                        <input type="text" className="form-control" placeholder="Enter your name" value={name} onChange={(e) => setName(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label">Email</label>
                                        <input type="email" className="form-control" placeholder="johndoe@example.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                    {/* <div className="row">
                                    <div className="col-md-12">
                                        <label className="col-form-label">Phone Number</label>
                                        <div className="form-group">
                                            <input type="text" className="form-control form-control-lg group_formcontrol" id="phone" name="phone" placeholder="(256) 789-6253" />
                                        </div>
                                    </div>
                                </div> */}
                                    <div className="form-group">
                                        <label className="col-form-label d-block">Password<span className="brief-bio float-end">Must be 8 Characters at Least</span></label>
                                        <div className="pass-group">
                                            <input type={showPassword ? "text" : "password"} className="form-control pass-input" placeholder="*************" value={password} onChange={(e) => setPassword(e.target.value)} />
                                            <span className={`toggle-password ${showPassword ? 'feather-eye-off' : 'feather-eye'}`} onClick={() => { setShowPassword(!showPassword) }} />
                                        </div>
                                    </div>
                                    <button className="btn btn-primary w-100 login-btn" type="submit">Register</button>

                                    {/* <div className="login-or">
                                    <span className="or-line" />
                                    <span className="span-or">Or, log in with your email</span>
                                </div>
                                <div className="social-login">
                                    <a href="#" className="btn btn-google w-100">
                                        <img src="assets/img/icons/google.svg" className="me-2" alt="img" />Log in with Google
                                    </a>
                                    <a href="#" className="btn btn-google w-100">
                                        <img src="assets/img/icons/fb.svg" className="me-2" alt="img" />Log in with Facebook
                                    </a>
                                </div> */}
                                    <p className="no-acc mt-1">Already have an account ? <Link to={"/login"}> Login</Link></p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mouse-cursor cursor-outer" style={{ visibility: 'visible' }} />
            <div className="mouse-cursor cursor-inner" style={{ visibility: 'visible' }} />
        </>
    )
}

export default Reqister