import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';

import Header from '../../components/Header'
import Alert from '../../components/Alert';

import { getCookie, setCookie, checkRole } from '../../func';

const pattern1 = /(?=.*[a-z])/
const pattern2 = /(?=.*[a-z])(?=.*[A-Z])/
const pattern3 = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/
const pattern4 = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@!_%&*])/

const ResetPassword = () => {
    const navigate = useNavigate();
    let params = useParams();

    const [password, setPassword] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');
    const [percent, setPercent] = useState(0);
    const [showPassword, setShowPassword] = useState(false);

    const [alert, setAlert] = useState({
        type: '',
        message: '',
        show: false
    });

    useEffect(() => {
        if (getCookie("token") !== undefined && getCookie("token") !== null) {
            //user is already loggedin
            if (checkRole(getCookie("token")) === "admin")
                navigate(`/dashboard`, { replace: true });
            else
                navigate(`/dashboard`, { replace: true });
        }

        if (params.token !== undefined && params.token !== null && params.token.toString().trim() !== "")
            Verify(params.token);
        else {
            navigate(`/`, { replace: true });
        }
    }, [])

    useEffect(() => {
        if (password.length > 5) {
            const result1 = pattern1.test(password);
            const result2 = pattern2.test(password);
            const result3 = pattern3.test(password);
            const result4 = pattern4.test(password);

            if (result1 === true) {
                setPercent(25);
            } if (result2 === true) {
                setPercent(50);
            } if (result3 === true) {
                setPercent(75);
            } if (result4 === true) {
                setPercent(100);
            }
        }
        else {
            percent !== 0 && setPercent(0);
        }
        // console.log("percentage: ", percent)
    }, [password, percent])

    const handleMessage = (params) => {
        setAlert({ type: params.type, message: params.message, show: true });

        setTimeout(() => {
            setAlert({ type: '', message: '', show: false });
        }, 3200);
    }

    const Verify = (token) => {
        const json = JSON.stringify({ "token": token });
        const url = `${process.env.REACT_APP_BASE_API}/api.php?action=validate_token`;

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success !== "true") {
                setTimeout(() => {
                    navigate(`/`, { replace: true });
                }, 3500);
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
            //handleMessage({ type: 'danger', message: "Something went wrong. Please try later", show: true });
        });
    }

    const ChangePassword = () => {
        const json = JSON.stringify({ "token": params.token, "newpassword": password });
        const url = `${process.env.REACT_APP_BASE_API}/api.php?action=resetpassword`;

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        }).then((response) => {
            if (response.data.success === "true") {
                handleMessage({ type: 'success', message: response.data.message, show: true });

                setTimeout(() => {
                    navigate(`/login`, { replace: true });
                }, 3000);
            }
            else {
                handleMessage({ type: 'danger', message: response.data.message, show: true });
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
            handleMessage({ type: 'danger', message: "Something went wrong. Please try later", show: true });
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if ((password.trim() !== "" && password.trim().length > 5) && (confirmpassword.trim() !== "" && confirmpassword.trim().length > 5) && password.trim() === confirmpassword.trim()) {
            ChangePassword();
        }
        else {
            handleMessage({ type: 'info', message: "Please provide valid password", show: true });
        }
    }

    return (
        <>
            <Header />

            <div className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-6 mx-auto">
                            <div className="login-wrap">
                                <Alert type={alert.type} message={alert.message} show={alert.show} />
                                <div className="login-header">
                                    <h3>Reset Password</h3>
                                    <p>Your new password must be different from previous used passwords.</p>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="log-form">
                                        <div className="form-group">
                                            <label className="col-form-label">New Password</label>
                                            <div className="pass-group" id="passwordInput">
                                                <input type="password" className="form-control pass-input" placeholder="*************" value={password} onChange={(e) => setPassword(e.target.value)} autoComplete="on" />
                                                <span className={`toggle-password ${showPassword ? 'feather-eye-off' : 'feather-eye'}`} onClick={() => { setShowPassword(!showPassword) }} />
                                            </div>
                                            <div className="password-strength" id="passwordStrength">
                                                {(password.length >= 5 && (percent > 0 && percent <= 25)) ? <span id="poor" style={{ "background": "#ff0000" }} /> : <span id="poor" />}
                                                {(password.length >= 5 && (percent > 25 && percent <= 50)) ? <span id="weak" style={{ "background": "#ffa500" }} /> : <span id="weak" />}
                                                {(password.length >= 5 && (percent > 50 && percent <= 75)) ? <span id="strong" style={{ "background": "#90ee90" }} /> : <span id="strong" />}
                                                {(password.length >= 5 && (percent > 75 && percent <= 100)) ? <span id="heavy" style={{ "background": "#159f46" }} /> : <span id="heavy" />}
                                            </div>
                                            <div id="passwordInfo">Use 8 or more characters with a mix of letters, numbers &amp; symbols.</div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-form-label">Confirm Password</label>
                                            <div className="pass-group">
                                                <input type={showPassword ? "text" : "password"} className="form-control pass-input" placeholder="*************" value={confirmpassword} onChange={(e) => setConfirmPassword(e.target.value)} autoComplete="on" />
                                                <span className={`toggle-password ${showPassword ? 'feather-eye-off' : 'feather-eye'}`} onClick={() => { setShowPassword(!showPassword) }} />
                                            </div>
                                        </div>
                                    </div>
                                    <button className="btn btn-primary w-100 login-btn" type="submit">Save Change</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mouse-cursor cursor-outer" style={{ visibility: 'visible' }} />
            <div className="mouse-cursor cursor-inner" style={{ visibility: 'visible' }} />
        </>
    )
}

export default ResetPassword