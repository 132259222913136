import React from 'react'
import { Link } from 'react-router-dom'

const Features = () => {
    return (
        <section className="feature-section pt-0 mx-4 mx-md-0" style={{backgroundColor: "transparent"}}> {/**work-section */}
            {/* <div className="container">
                <div className="row aos aos-init aos-animate" data-aos="fade-up">
                    <div className="col-md-12">
                        <div className="offer-paths">
                            <div className="offer-pathimg">
                                <img src="assets/img/offer.png" alt="img" />
                            </div>
                            <div className="offer-path-content">
                                <h3>We Are Offering 14 Days Free Trial</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore minim veniam, quis nostrud exercitation ullamco magna aliqua. </p>
                                <a href="free-trial.html" className="btn btn-primary btn-views">Try 14 Days Free Trial<i className="feather-arrow-right-circle" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="container pt-4" style={{borderRadius: "30px", backgroundColor: "#4c40ed"}}>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <div className="section-heading aos aos-init aos-animate" data-aos="fade-up">
                            <h2 className='text-white'>Boost Your Rank - Get Organic Traffic - Get Free Access NOW!</h2>
                            {/* <p className='text-white'>Aliquam lorem ante, dapibus in, viverra quis</p> */}
                        </div>
                    </div>
                </div>
                <div className="row mx-md-4 pb-md-4">
                    <div className="col-md-3">
                        <div className="work-box aos aos-init aos-animate px-1 py-4" data-aos="fade-up">
                            <div className="work-icon">
                                <span>
                                    <img className='w-75' src="assets/img/1.svg" alt="img" loading='lazy'/>
                                </span>
                            </div>
                            <h5>Reasonable prices</h5>
                            {/* <p>Lorem ipsum dolor amet, consectetur adipiscing tempor labore et dolore magna aliqua.</p>
                            <h4>01</h4> */}
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="work-box aos aos-init aos-animate px-1 py-4" data-aos="fade-up">
                            <div className="work-icon">
                                <span>
                                    <img className='w-75' src="assets/img/2.svg" alt="img" loading='lazy' />
                                </span>
                            </div>
                            <h5>Quick delivery</h5>
                            {/* <p>Lorem ipsum dolor amet, consectetur adipiscing tempor labore et dolore magna aliqua.</p>
                            <h4>02</h4> */}
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="work-box aos aos-init aos-animate px-1 py-4" data-aos="fade-up">
                            <div className="work-icon">
                                <span>
                                    <img className='w-75' src="assets/img/3.svg" alt="img" loading='lazy' />
                                </span>
                            </div>
                            <h5>Simple order handling</h5>
                            {/* <p>Lorem ipsum dolor amet, consectetur adipiscing tempor labore et dolore magna aliqua.</p>
                            <h4>03</h4> */}
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="work-box aos aos-init aos-animate px-1 py-4" data-aos="fade-up">
                            <div className="work-icon">
                                <span>
                                    <img className='w-75' src="assets/img/4.svg" alt="img" loading='lazy' />
                                </span>
                            </div>
                            <h5>Broad range of services</h5>
                            {/* <p>Lorem ipsum dolor amet, consectetur adipiscing tempor labore et dolore magna aliqua.</p>
                            <h4>03</h4> */}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 text-center">
                        <div className="section-heading aos aos-init aos-animate" data-aos="fade-up">
                            {/* <h2 className='text-white'>Boost Your Rank - Get Organic Traffic - Get Free Access NOW!</h2>
                            <p className='text-white'>Aliquam lorem ante, dapibus in, viverra quis</p> */}
                            <Link className="btn btn-white" to={"/register"}><i className="feather-user me-2"></i>Get Free Access</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Features