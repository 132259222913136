import React from 'react'
import Header from '../../components/Header';
import ReviewsSection from '../../components/ReviewsSection';
import Footer from '../../components/Footer';

const RankHigh = () => {
    return (
        <>
            <Header />

            <section className="hero-section" style={{ minHeight: "350px" }}>
                <div className="container">
                    <div className="home-banner">
                        <div className="row w-100">
                            <div className="col-lg-6 col-md-10 mx-auto">
                                <div className="section-search aos aos-init aos-animate" data-aos="fade-up">
                                    {/* <h1>Are You Looking for <br /><b>Effective SEO Services?</b></h1> */}
                                    <h1>Rank Higher on Google</h1>
                                    <p className="mb-3 text-black fw-bold">Improved Search Engine Rankings for Your Website.</p>
                                    <p className="mb-5">Boost the exposure of your website and improve its ranking on search engines like as Google, Yahoo, and Bing.</p>

                                    <a className="btn btn-primary" href="#"><i className="feather-user me-2" />Get Started!</a>

                                </div>
                            </div>
                            <div className="col-lg-6 d-flex justify-content-center">
                                <img src="assets/img/rank-higher-on-google.png" className="img-fluid aos aos-init aos-animate" alt="img" data-aos="fade-up" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="work-section py-5"> {/**works-eight-section */}
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="section-heading section-heading-eight aos aos-init aos-animate" data-aos="fade-up">
                                <h2 style={{ color: "#5347e9" }}>Obtain Qualified Leads and Rank Higher on Google!</h2>
                                {/* <p className='w-75 mx-auto'>Start getting real exposure for your brand. We'll take care of it for you. We can get an article written and published on the network of over 200+ news sites, all done within just 5 days.</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center aos aos-init aos-animate" data-aos="fade-up">
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="works-eights-main">
                                <div className="works-eights-img">
                                    <img src="https://panel.seoestore.net/assets/img/pages/features-icons/wide-base-of-services.png" alt="steps" className='img-fluid w-75' />
                                </div>
                                <h3 style={{ color: "#4e42eb" }}>A broad range of services</h3>
                                <p className='text-start text-md-center'>Our extensive service offering enables an integrated SEO approach that maximizes the internet visibility of your company.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="works-eights-main">
                                <div className="works-eights-img">
                                    <img src="https://panel.seoestore.net/assets/img/pages/features-icons/seo-package.png" alt="steps" className='img-fluid w-75' />
                                </div>
                                <h3 style={{ color: "#4e42eb" }}>Budget-friendly SEO packages</h3>
                                <p className='text-start text-md-center'>We provide our skilled staff with reasonably priced SEO packages. Regardless of your budget, we aim to give you the finest service possible.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="works-eights-main">
                                <div className="works-eights-img">
                                    <img src="https://panel.seoestore.net/assets/img/pages/features-icons/increase-your-website-ranking.png" alt="steps" className='img-fluid w-75' />
                                </div>
                                <h3 style={{ color: "#4e42eb" }}>Boost the ranking of your website</h3>
                                <p className='text-center text-md-start'>Boost the ranking of your website with our professionally-approved SEO services.</p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row py-4">
                        <div className="col-md-12 text-center">
                            <div className="section-heading section-heading-eight aos aos-init aos-animate mb-0" data-aos="fade-up">
                                <p className='mb-3'>Reach the market players and create a powerful market impact!</p>
                                <a className="btn btn-primary" href="#">Place Your Order Now!</a>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>

            <section className="work-section pt-5 pb-5" style={{ backgroundColor: "#ffffff" }}>
                <div className="container">

                    <div className="row mb-4">
                        <div className="col-md-12 text-center">
                            <div className="section-heading section-heading-eight aos aos-init aos-animate mb-0" data-aos="fade-up">
                                {/* <img src="/assets/img/logo.png" alt="logo" loading="lazy" /> */}
                                <h2>Boost your website's Google ranking with the greatest SEO campaigns</h2>
                                <p className='w-75 mx-auto'>We offer a large range of services at a single location. To quickly and efficiently rank your website on Google, use SEO tactics.</p>
                            </div>
                        </div>
                    </div>

                    {/* <div className="row pt-5 mb-0 mb-md-5"> */}
                    <div className="row align-items-center aos aos-init aos-animate" data-aos="fade-up">
                        <div className="col-lg-6 col-12">
                            <div className="pets-content-all">
                                <h3>Get your SEO strategy started in a few simple steps.</h3>
                                <p>To efficiently rank your website in Google, use SEO tactics:</p>
                                <ul className='my-4'>
                                    <li style={{ listStyleType: 'disclosure-closed', listStylePosition: 'inside', color: "#4c40ed", marginBottom: "4px" }}><span className='text-muted fw-bold'>It's as simple as 123</span></li>
                                    <li style={{ listStyleType: 'disclosure-closed', listStylePosition: 'inside', color: "#4c40ed", marginBottom: "4px" }}><span className='text-muted fw-bold'>Decide on a budget</span></li>
                                    <li style={{ listStyleType: 'disclosure-closed', listStylePosition: 'inside', color: "#4c40ed", marginBottom: "4px" }}><span className='text-muted fw-bold'>Enter your keywords and link(s)</span></li>
                                    <li style={{ listStyleType: 'disclosure-closed', listStylePosition: 'inside', color: "#4c40ed", marginBottom: "4px" }}><span className='text-muted fw-bold'>Send in your campaign</span></li>
                                    <li style={{ listStyleType: 'disclosure-closed', listStylePosition: 'inside', color: "#4c40ed", marginBottom: "4px" }}><span className='text-muted fw-bold'>Examine your reports to ensure flawless outcomes</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="healthy-pets-img d-flex justify-content-end">
                                <img src="https://panel.seoestore.net/assets/img/pages/features/rank-higher-on-google.png" alt="get seen" className="img-fluid w-75" />
                            </div>
                        </div>
                    </div>
                    {/* </div> */}

                </div>
            </section>

            <section className="client-sections review-four">
                <div className="container">
                    <div className="services-header aos aos-init aos-animate" data-aos="fade-up">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-content section-client mb-0">
                                    <h2>Cost-effective Custom SEO Campaigns</h2>
                                    <p>Start your SEO campaign with just one click. We've developed these packages to be as successful as possible while staying within your budget.</p>
                                    <a className="read-more align-self-center px-5 mt-0 mt-md-4" href="#">Launch Your SEO Campaign Right Away!</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <ReviewsSection />

            <Footer />

            <div className="mouse-cursor cursor-outer" style={{ visibility: 'visible' }} />
            <div className="mouse-cursor cursor-inner" style={{ visibility: 'visible' }} />
        </>
    )
}

export default RankHigh