import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { getCookie, base64_decode } from '../../func';

import Header from '../../components/Header';
import ServiceForm from '../../components/ServiceForm';
import CryptoPaymentsForm from '../../components/CryptoPaymentsForm';

var selectedPaymentMethod = '';

const OrderNow = () => {
    let location = useLocation();
    const navigate = useNavigate();
    let params = useParams();

    const [packageDeails, setPackageDetails] = useState({});
    const [activeTab, setActiveTab] = useState('details');

    const [totalPrice, setTotalPrice] = useState(0.0);
    const [selectedExtras, setSelectedExtras] = useState([]);

    const [paymentMethod, setPaymentMethod] = useState('');

    const [serviceFormData, setServiceFormData] = useState([]);
    const [enableProceedButton, setEnableProceedButton] = useState(false);
    //const [cryptoOrderResponse, setCryptoOrderResponse] = useState({ status: null, priceInCrypto: null, payment_response: null, chain: null, wallet: null });
    const [cryptoOrderResponse, setCryptoOrderResponse] = useState({ status: null, payment_response: null, chain: null, wallet: null });
    
    const [paypalClientID, setPaypalClientID] = useState("");
    const [cryptoRecieverAddress, setCryptoReciverAddress] = useState({ "ethereum": "", "binance": "" });

    useEffect(() => {
        if (params.slug !== undefined && params.slug !== null && params.slug.toString().trim() !== "") {
            if (getCookie("token") === undefined || getCookie("token") === null) {
                navigate(`/login?url=${location.pathname}`, { replace: true });
            }
            else {
                fetchPackageDetails(params.slug);
            }
        }
        else
            navigate(`/`, { replace: true });
    }, []);

    // useEffect(() => {
    //     console.log("selectedExtras: ", selectedExtras);
    // }, [selectedExtras]);

    useEffect(() => {
        // console.log("paymentMethod:", paymentMethod);
        selectedPaymentMethod = paymentMethod;
    }, [paymentMethod]);

    useEffect(() => {
        if (cryptoOrderResponse.status !== null) {
            saveOrder(cryptoOrderResponse.status, cryptoOrderResponse.payment_response)
        }
    }, [cryptoOrderResponse])

    useEffect(() => {
        if (activeTab === "payment") {
            fetchPaymentGateways();
        }
    }, [activeTab])

    // useEffect(() => {
    //     console.log("packageDeails: ", packageDeails);
    //     if (typeof packageDeails["package_id"] !== "undefined") {
    //         console.log("packageDeails: ", packageDeails);
    //         console.log(base64_decode(packageDeails["package_id"]));
    //         saveOrder("COMPLETED", { "id": "6SY644010Y605713Y", "intent": "CAPTURE", "status": "COMPLETED", "purchase_units": [{ "reference_id": "default", "amount": { "currency_code": "USD", "value": "70.00" }, "payee": { "email_address": "seoestoreclone-business@business.example.com", "merchant_id": "EN69UABR34FHL" }, "description": "Package 1", "shipping": { "name": { "full_name": "John Doe" }, "address": { "address_line_1": "1 Main St", "admin_area_2": "San Jose", "admin_area_1": "CA", "postal_code": "95131", "country_code": "US" } }, "payments": { "captures": [{ "id": "9R312488B5234053B", "status": "COMPLETED", "amount": { "currency_code": "USD", "value": "70.00" }, "final_capture": true, "seller_protection": { "status": "ELIGIBLE", "dispute_categories": ["ITEM_NOT_RECEIVED", "UNAUTHORIZED_TRANSACTION"] }, "create_time": "2024-04-27T13:46:14Z", "update_time": "2024-04-27T13:46:14Z" }] } }], "payer": { "name": { "given_name": "John", "surname": "Doe" }, "email_address": "seoestoreclone-personal@personal.example.com", "payer_id": "V4ZBT9EBEFUA6", "address": { "country_code": "US" } }, "create_time": "2024-04-27T13:46:05Z", "update_time": "2024-04-27T13:46:14Z", "links": [{ "href": "https://api.sandbox.paypal.com/v2/checkout/orders/6SY644010Y605713Y", "rel": "self", "method": "GET" }] });
    //     }
    // }, [packageDeails]);

    const fetchPackageDetails = (slug) => {
        const json = JSON.stringify({ "package_slug": slug, "showform": true });
        const url = `${process.env.REACT_APP_BASE_API}/api.php?action=get_package_details`;

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                setPackageDetails(response.data.package_details[0]);
                setTotalPrice(parseFloat(response.data.package_details[0]["package_price"]));
            }
            else{
                navigate(`/`, { replace: true });
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    }

    const fetchPaymentGateways = () => {
        const json = JSON.stringify({ "token": getCookie("token") });
        const url = `${process.env.REACT_APP_BASE_API}/api.php?action=get_all_gateway`;

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                // setCampaignDetails(response.data.campaign_details[0]);
                // console.log("gateways: ", response.data.gateways);
                var tempGateways = response.data.gateways;
                if (tempGateways.length > 0) {
                    //console.log(atob(tempGateways.filter(g => g.gateway_name === "Crypto - Binance")[0]["gateway_secret"]));
                    //console.log(atob(tempGateways.filter(g => g.gateway_name === "Crypto - Ethereum")[0]["gateway_secret"]));
                    setCryptoReciverAddress(
                        {
                            "ethereum": tempGateways.filter(g => g.gateway_name === "Crypto - Ethereum") > 0 ? atob(tempGateways.filter(g => g.gateway_name === "Crypto - Ethereum")[0]["gateway_secret"]) : "",
                            "binance": tempGateways.filter(g => g.gateway_name === "Crypto - Binance") > 0 ? atob(tempGateways.filter(g => g.gateway_name === "Crypto - Binance")[0]["gateway_secret"]) : ""
                        }
                    )
                    setPaypalClientID(tempGateways.filter(g => g.gateway_name === "Paypal") > 0 ? atob(tempGateways.filter(g => g.gateway_name === "Paypal")[0]["gateway_secret"]) : "")
                }
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    }

    const PageHeader = () => (
        <>
            <div className="bg-img">
                <img src="/assets/img/bg/work-bg-03.png" alt="img" className="bgimg1" />
                <img src="/assets/img/bg/work-bg-03.png" alt="img" className="bgimg2" />
                <img src="/assets/img/bg/feature-bg-03.png" alt="img" className="bgimg3" />
            </div>
            <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h2 className="breadcrumb-title">{packageDeails.service_title}</h2>
                            <nav aria-label="breadcrumb" className="page-breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">Details</li>
                                    <li className="breadcrumb-item active" aria-current="page">{packageDeails.service_title}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

    const handleFeaturedExtra = (control, extra) => {
        // console.log("control: ", control.value);
        // console.log("extra: ", extra);
        var temp = [...selectedExtras];
        var p = totalPrice;

        if (temp.length === 0) { // add object if not exists
            temp.push(extra);
            setSelectedExtras(temp);
            p = p + parseFloat(extra.price);
        }
        else if (temp.length > 0) {
            var exist = temp.some(e => e.feature === extra.feature);
            if (exist) { // remove if object exists
                temp = temp.filter(function (obj) {
                    return obj.feature !== extra.feature;
                });
                setSelectedExtras(temp);
                p = p - parseFloat(extra.price);
            }
            else {
                temp.push(extra);
                setSelectedExtras(temp);
                p = p + parseFloat(extra.price);
            }
        }
        setTotalPrice(p);
    }

    const preceedToPay = (step) => {
        if (step === 1) {
            setActiveTab("payment");
        }
        else if (step === 2) {
            setActiveTab("done");
        }
    }

    // const handlePaymentMethod = (methodname) => {
    //     setPaymentMethod(methodname);
    // }

    const handlePaymentMethod = (event) => {
        setPaymentMethod(event.target.value);
    }

    const initialOptions = {
        //clientId: process.env.REACT_APP_PAYPAL_CLIENTID,//"AaMlaT73vHMRqH5n0ua2o8HyWj8iS4VZlR8K0JDKMubSE4fxeSmdxrLLx0PADSC-ADTM-R0OLQios1-h", //"test"
        clientId: paypalClientID !== "" ? paypalClientID : process.env.REACT_APP_PAYPAL_CLIENTID,
        currency: process.env.REACT_APP_PAYPAL_CURRENCY,//"USD",
        intent: "capture",
        components: "buttons",
        //"disable-funding": "credit,card,venmo,paylater"
        "disable-funding": "credit,venmo,paylater"
    };

    const createOrder = (data, actions, err) => {
        // console.log("create order data: ", data);
        // console.log("create order actions: ", actions);
        // console.log("create order err: ", err);
        return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
                {
                    description: packageDeails["package_name"],
                    amount: {
                        currency_code: "USD",
                        value: totalPrice,
                    },
                },
            ],
        });
    }

    const onApprove = async (data, actions) => {
        // console.log("onApprove data: ", data);
        // console.log("onApprove actions: ", actions);
        const order = await actions.order.capture();
        // console.log("onApprove: ", order);

        //saveOrder(order["status"], JSON.stringify(order));
        saveOrder(order["purchase_units"][0]["payments"]["captures"][0]["status"], JSON.stringify(order));

        // console.log('order["status"]: ', order["status"])
        // console.log('order["purchase_units"][0]["payments"]["captures"][0]["id"]: ', order["purchase_units"][0]["payments"]["captures"][0]["id"])
        // console.log('order["purchase_units"][0]["payments"]["captures"][0]["status"]: ', order["purchase_units"][0]["payments"]["captures"][0]["status"]);

    }

    const onCancel = async (data) => {
        // console.log("onCancel data: ", data);
        console.log(data);
    }

    const onError = async (err) => {
        // console.log("onError err: ", err);
        console.log(err);
    }

    const saveOrder = (status, payment_response) => {
        debugger;
        var user_id = base64_decode(getCookie("token")).split("|")[0];
        var details = {
            orderDetails: [...serviceFormData],
            extras: [...selectedExtras]
        }

        var json = '';
        var url = '';
        if (paymentMethod !== "crypto") {
            json = JSON.stringify({ "package_id": base64_decode(packageDeails["package_id"]), "order_amount": totalPrice, "method": selectedPaymentMethod, "status": status, "payment_response": JSON.stringify(JSON.parse(payment_response)), "user_id": user_id, "order_details": JSON.stringify(details) });
            url = `${process.env.REACT_APP_BASE_API}/api.php?action=save_order`;
        }
        else if(paymentMethod === "crypto"){
            json = JSON.stringify({ "package_id": base64_decode(packageDeails["package_id"]), "order_amount": totalPrice, "method": selectedPaymentMethod, "status": status, "payment_response": JSON.stringify(cryptoOrderResponse.payment_response), "user_id": user_id, "order_details": JSON.stringify(details) });
            url = `${process.env.REACT_APP_BASE_API}/api.php?action=save_order`;
        }

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                setTimeout(() => {
                    setActiveTab("done");
                }, 1000);
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    }

    const SummeryBox = () => (
        <div className="summary-box">
            <div className="booking-info">
                <div className="service-book">
                    <div className="service-book-img">
                        <img src={`${process.env.REACT_APP_BASE_API}/${packageDeails["package_title_image"]}`} alt="img" style={{ border: "1px solid #4c40ed" }} />
                    </div>
                    <div className="serv-profile w-100">
                        <span className="badge px-0">{packageDeails.service_title}</span>
                        <h2 className='mb-0'>{packageDeails.package_name}</h2>
                    </div>
                </div>
            </div>

            <div className="booking-summary">
                {selectedExtras.length > 0 &&
                    (<>
                        <h6 className="px-3 pt-3 mb-0">Featured Extras</h6>
                        <ul className="booking-date">
                            {selectedExtras.map((ext, index) => (
                                <li key={index}>{ext.feature} <span>${ext.price}</span></li>
                            ))}
                        </ul>
                    </>)
                }
                <div className="booking-total">
                    <ul className="booking-total-list">
                        <li>
                            <span>Total</span>
                            <span className="total-cost">${totalPrice}</span>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    )

    const createOrderDetailsJson = () => {
        var user_id = base64_decode(getCookie("token")).split("|")[0];
        var details = {
            package_id: base64_decode(packageDeails["package_id"]),
            service_type: "package",
            order_amount: totalPrice,
            user_id: user_id,
            orderDetails: [...serviceFormData],
            extras: [...selectedExtras],
        }
        return JSON.stringify(details);
    }

    // const ServiceForm = () => {
    //     var formFields = [...JSON.parse(packageDeails["service_form"])];
    //     // console.log("typeof: ", typeof formFields)
    //     // console.log(formFields)
    //     formFields.map((field, index) => {
    //         switch (field.type) {
    //             case "text":
    //                 return <></>;
    //             case "text":
    //                 return <></>;
    //             case 3:
    //                 return <></>;
    //             default:
    //                 break;
    //         }
    //     })
    // }

    return (
        <>
            <Header />

            <PageHeader />

            <div className="content book-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">

                            <ul className="step-register row">
                                <li className={`${activeTab === "details" ? "active col-md-4" : "activate col-md-4"}`}>
                                    <div className="multi-step-icon">
                                        <span><img src="/assets/img/icons/calendar-icon.svg" alt="img" /></span>
                                    </div>
                                    <div className="multi-step-info">
                                        <h6>Order Details</h6>
                                        <p>Please provide details</p>
                                    </div>
                                </li>
                                <li className={`${activeTab === "payment" ? "active col-md-4" : ((activeTab !== "details" && activeTab !== "payment") ? "activate col-md-4" : "col-md-4")}`}>
                                    <div className="multi-step-icon">
                                        <span><img src="/assets/img/icons/wallet-icon.svg" alt="img" /></span>
                                    </div>
                                    <div className="multi-step-info">
                                        <h6>Payment</h6>
                                        <p>Select Payment Gateway</p>
                                    </div>
                                </li>
                                <li className={`${activeTab === "done" ? "active col-md-4" : "col-md-4"}`}>
                                    <div className="multi-step-icon">
                                        <span><img src="/assets/img/icons/book-done.svg" alt="img" /></span>
                                    </div>
                                    <div className="multi-step-info">
                                        <h6>Done </h6>
                                        <p>Completion of Order</p>
                                    </div>
                                </li>
                            </ul>

                            {activeTab === "details" &&
                                (<div className="row">
                                    <div className="col-lg-6">
                                        {/* {(packageDeails["featured_extras"] !== undefined && packageDeails["featured_extras"].length > 0) ? <h5 className="pay-title mt-4 mb-2">Order Detials</h5> : <h5 className="pay-title">Order Detials</h5>} */}
                                        <h5 className="pay-title mb-2">Order Detials</h5>
                                        {
                                            packageDeails["service_form"] !== undefined && <ServiceForm formFields={packageDeails["service_form"]} setEnableProceedButton={setEnableProceedButton} setServiceFormData={setServiceFormData} />
                                        }
                                        {
                                            packageDeails["featured_extras"] !== undefined &&
                                            <>
                                                {/* <h5 className="pay-title">Featured Extra</h5> */}
                                                {(packageDeails["service_form"].trim() !== "" && packageDeails["service_form"].trim() !== "[]") ? <h5 className="pay-title mt-4">Featured Extra</h5> : <h5 className="pay-title">Featured Extra</h5>}
                                                {
                                                    JSON.parse(packageDeails["featured_extras"]).map((extra, index) => (
                                                        <div className="payment-card payment-bg mb-2" key={index}>
                                                            <div className="payment-head">
                                                                <div className="payment-title w-100">
                                                                    <label className="custom_check me-2 w-100 mb-0">
                                                                        <input type="checkbox" name="payment" className="card-payment" value={selectedExtras.some(e => e.feature === extra.feature)} onChange={(e) => handleFeaturedExtra(e.target, extra)} />
                                                                        <span className="checkmark" style={{ marginTop: "3px" }} />

                                                                        <div className='w-100 d-flex flex-row justify-content-between align-items-center ms-2'>
                                                                            <h5 className='m-0'>{extra["feature"]}</h5>
                                                                            <h6>$ {extra["price"]}</h6>
                                                                        </div>
                                                                    </label>
                                                                    {/* <div className='w-100 d-flex flex-row justify-content-between align-items-center'>
                                                                        <h5 className='m-0'>{extra["feature"]}</h5>
                                                                        <h6>$ {extra["price"]}</h6>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        }
                                        {/* <div className="payment-card payment-bg">
                                            <div className="payment-head">
                                                <div className="payment-title">
                                                    <label className="custom_radio">
                                                        <input type="radio" name="payment" className="card-payment" defaultChecked />
                                                        <span className="checkmark" />
                                                    </label>
                                                    <h6>Wallet</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="payment-card">
                                            <div className="payment-head">
                                                <div className="payment-title">
                                                    <label className="custom_radio">
                                                        <input type="radio" name="payment" className="card-payment" />
                                                        <span className="checkmark" />
                                                    </label>
                                                    <h6>Cash On Delivery</h6>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="col-lg-6">
                                        <h5 className="pay-title">Order Summary</h5>
                                        <SummeryBox />
                                        <div className="booking-pay">
                                            {enableProceedButton ?
                                                <button className="btn btn-primary btn-pay w-100" style={{ marginRight: "0px" }} onClick={() => preceedToPay(1)}>Proceed to Pay ${totalPrice !== 0 ? totalPrice : packageDeails["package_price"]}</button>
                                                :
                                                <button className="btn btn-primary btn-pay w-100" style={{ marginRight: "0px" }} disabled>Proceed to Pay ${totalPrice !== 0 ? totalPrice : packageDeails["package_price"]}</button>
                                            }
                                            {/* <a href="javascript:void(0);" className="btn btn-secondary btn-skip">Skip</a> */}
                                        </div>
                                    </div>
                                </div>)
                            }

                            {activeTab === "payment" &&
                                (<div className="row">
                                    <div className="col-lg-6">
                                        <h5 className="pay-title">Payment Methods</h5>
                                        <div className="payment-card mb-1">
                                            <div className="payment-head">
                                                <div className="payment-title w-100">
                                                    <label className="custom_radio w-100 mb-0">
                                                        <input type="radio" name="payment" className="card-payment" value="paypal" checked={paymentMethod === 'paypal'} onChange={handlePaymentMethod} />
                                                        <span className="checkmark" />
                                                        <div className='w-100 d-flex flex-row justify-content-between align-items-center ms-2'>
                                                            <h6>Pay with Paypal</h6>
                                                            <img className="me-2" src="/assets/img/payment/paypal.png" style={{ width: "60px" }} alt="paypal img" />
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="payment-list p-4 my-2" style={{ display: (paymentMethod === "paypal" ? 'block' : 'none'), backgroundColor: "#f7f7ff", borderRadius: "5px" }}>
                                            <div className="row align-items-center">
                                                <div className="col-md-12">
                                                    <PayPalScriptProvider options={initialOptions}>
                                                        <PayPalButtons
                                                            style={{ layout: "vertical", tagline: false }}
                                                            createOrder={createOrder}
                                                            onApprove={onApprove}
                                                            onError={onError}
                                                            onCancel={onCancel}
                                                        />
                                                    </PayPalScriptProvider>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="payment-card mb-1">
                                            <div className="payment-head">
                                                <div className="payment-title w-100">
                                                    <label className="custom_radio w-100 mb-0">
                                                        <input type="radio" name="payment" className="card-payment" value="crypto" checked={paymentMethod === 'crypto'} onChange={handlePaymentMethod} />
                                                        <span className="checkmark" />
                                                        <div className='w-100 d-flex flex-row justify-content-between align-items-center ms-2'>
                                                            <h6>Pay with Crypto</h6>
                                                            <div>
                                                                <img className="me-2" src="/assets/img/eth.svg" style={{ width: "12px" }} alt="eth img" />
                                                                <img className="me-2" src="/assets/img/bnb.svg" style={{ width: "20px" }} alt="bnb img" />
                                                            </div>

                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="payment-list p-4 my-2" style={{ display: (paymentMethod === "crypto" ? 'block' : 'none'), backgroundColor: "#f7f7ff", borderRadius: "5px" }}>
                                            <div className="row align-items-center">
                                                <div className="col-md-12">
                                                    <CryptoPaymentsForm totalPrice={totalPrice} setCryptoOrderResponse={setCryptoOrderResponse} cryptoRecieverAddress={cryptoRecieverAddress} orderDetailsJSON={createOrderDetailsJson}/>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="payment-card mb-1">
                                            <div className="payment-head">
                                                <div className="payment-title w-100">
                                                    <label className="custom_radio w-100 mb-0">
                                                        <input type="radio" name="payment" className="card-payment" value="paypro" checked={paymentMethod === 'paypro'} onChange={handlePaymentMethod} />
                                                        <span className="checkmark" />
                                                        <div className='w-100 d-flex flex-row justify-content-between align-items-center ms-2'>
                                                            <h6>Pay with PayPro Global</h6>
                                                            <img className="me-2" src="/assets/img/payment/cards.png" style={{ width: "60px" }} alt="cards img" />
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="payment-card mb-1">
                                            <div className="payment-head">
                                                <div className="payment-title w-100">
                                                    <label className="custom_radio w-100 mb-0">
                                                        <input type="radio" name="payment" className="card-payment" value="tap" checked={paymentMethod === 'tap'} onChange={handlePaymentMethod} />
                                                        <span className="checkmark" />
                                                        <div className='w-100 d-flex flex-row justify-content-between align-items-center ms-2'>
                                                            <h6>Pay with TAP</h6>
                                                            <img className="me-2" src="/assets/img/payment/cards.png" style={{ width: "60px" }} alt="cards img" />
                                                        </div>
                                                    </label>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="payment-card mb-1">
                                            <div className="payment-head">
                                                <div className="payment-title w-100">
                                                    <label className="custom_radio w-100 mb-0">
                                                        <input type="radio" name="payment" className="card-payment" value="airtm" checked={paymentMethod === 'airtm'} onChange={handlePaymentMethod} />
                                                        <span className="checkmark" />
                                                        <div className='w-100 d-flex flex-row justify-content-between align-items-center ms-2'>
                                                            <h6>Pay with Airtm</h6>
                                                            <img className="me-2" src="/assets/img/payment/airtm.png" style={{ width: "60px" }} alt="airtm img" />
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="payment-card mb-1">
                                            <div className="payment-head">
                                                <div className="payment-title w-100">
                                                    <label className="custom_radio w-100 mb-0">
                                                        <input type="radio" name="payment" className="card-payment" value="bitcoin" checked={paymentMethod === 'bitcoin'} onChange={handlePaymentMethod} />
                                                        <span className="checkmark" />
                                                        <div className='w-100 d-flex flex-row justify-content-between align-items-center ms-2'>
                                                            <h6>Pay with Bitcoin</h6>
                                                            <img className="me-2" src="/assets/img/payment/bitcoin.png" style={{ width: "60px" }} alt="bitcoin img" />
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="payment-card mb-1">
                                            <div className="payment-head">
                                                <div className="payment-title w-100">
                                                    <label className="custom_radio w-100 mb-0">
                                                        <input type="radio" name="payment" className="card-payment" value="payoneer" checked={paymentMethod === 'payoneer'} onChange={handlePaymentMethod} />
                                                        <span className="checkmark" />
                                                        <div className='w-100 d-flex flex-row justify-content-between align-items-center ms-2'>
                                                            <h6>Pay with Payoneer</h6>
                                                            <img className="me-2" src="/assets/img/payment/payoneer.png" style={{ width: "60px" }} alt="payoneer img" />
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="payment-card mb-1">
                                            <div className="payment-head">
                                                <div className="payment-title w-100">
                                                    <label className="custom_radio w-100 mb-0">
                                                        <input type="radio" name="payment" className="card-payment" value="neteller" checked={paymentMethod === 'neteller'} onChange={handlePaymentMethod} />
                                                        <span className="checkmark" />
                                                        <div className='w-100 d-flex flex-row justify-content-between align-items-center ms-2'>
                                                            <h6>Pay with Neteller</h6>
                                                            <img className="me-2" src="/assets/img/payment/neteller.png" style={{ width: "60px" }} alt="neteller img" />
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* <div className="payment-card">
                                            <div className="payment-head">
                                                <div className="payment-title">
                                                    <label className="custom_radio credit-card-option">
                                                        <input type="radio" name="payment" className="card-payment" />
                                                        <span className="checkmark" />
                                                    </label>
                                                    <h6>Credit / Debit Card</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="payment-list" style={{ display: 'none' }}>
                                            <div className="row align-items-center">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="col-form-label">Name on Card</label>
                                                        <input className="form-control" type="text" placeholder="Enter Name on Card" />
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="form-group">
                                                        <label className="col-form-label">Card Number</label>
                                                        <input className="form-control" placeholder="**** **** **** ****" type="text" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 text-end">
                                                    <div className="form-group">
                                                        <label className="col-form-label">&nbsp;</label>
                                                        <img src="/assets/img/payment-card.png" className="img-fluid" alt="image" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="col-form-label">Expiration date</label>
                                                        <input className="form-control" placeholder="MM/YY" type="text" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="col-form-label">Security code</label>
                                                        <input className="form-control" placeholder="CVV" type="text" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="col-lg-6">
                                        <h5 className="pay-title">Order Summary</h5>
                                        <SummeryBox />
                                        {/* <div className="booking-pay">
                                            <button className="btn btn-primary btn-pay w-100" style={{ marginRight: "0px" }} onClick={() => preceedToPay(1)}>Proceed to Pay ${totalPrice !== 0 ? totalPrice : packageDeails["package_price"]}</button>
                                        </div> */}
                                    </div>
                                </div>)
                            }

                            {activeTab === "done" &&
                                (
                                    <>
                                        {/* <div className="row">
                                            <div className="col-lg-12 text-center">
                                                <img className='mb-4' src="/assets/img/icons/book-done.svg" alt="done" style={{ height: "100px", width: "100px", backgroundColor: "#4c40ed", borderRadius: "50px", padding: "20px" }} />
                                                <h2>Thank you for ordering "{packageDeails.service_title}"</h2>
                                                <p>Continue to order other exciting services</p>

                                                <Link to={'/'} className="btn btn-primary">
                                                    <i className="feather-arrow-left-circle me-2"></i>Back to Home
                                                </Link>
                                            </div>
                                        </div> */}
                                        <div className="row align-items-center">
                                            <div className="col-md-7">
                                                <div className="booking-done">
                                                    <h6>Successfully Completed Payment</h6>
                                                    <p>Your Order has been Successfully Competed</p>
                                                    <div className="book-submit">
                                                        <Link to={'/'} className="btn btn-primary">
                                                            <i className="feather-arrow-left-circle me-2"></i>Back to Home
                                                        </Link>

                                                        <Link to={'/dashboard'} className="btn btn-secondary">
                                                            Dashboard
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-5">
                                                <div className="booking-done">
                                                    <img src="/assets/img/booking-done.png" className="img-fluid" alt="image" />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="mouse-cursor cursor-outer" style={{ visibility: 'visible' }} />
            <div className="mouse-cursor cursor-inner" style={{ visibility: 'visible' }} />
        </>
    )
}

export default OrderNow